import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SetPassword } from '@pepper-ui/common';
import {
  setAccountPassword,
  setAccountRepeatedPassword,
  getAccountPasswordValue,
  getAccountRepeatPasswordValue,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { createPassword, reauthenticate } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const CreatePassword = () => {
  const passwordValueRedux = useSelector(getAccountPasswordValue);
  const repeatPasswordValueRedux = useSelector(getAccountRepeatPasswordValue);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.CreatePassword.s14',
    'Create your password - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const updatedPasswordValue = (event) => {
    dispatch(setAccountPassword(event.target.value));
  };

  const updatedRepeatPasswordValue = (event) => {
    dispatch(setAccountRepeatedPassword(event.target.value));
  };

  const errorMsg = copyProvider.getCopy(
    'SMEReg.CreatePassword.s1',
    'Create a password that meets the above mentioned criteria'
  );

  const errorMsgMatch = copyProvider.getCopy(
    'SMEReg.CreatePassword.s2',
    'Passwords do not match'
  );

  const handleBtnClick = () => {
    createPassword({ password: passwordValueRedux })
      .then((response) => {
        const { data } = response;
        const { temporaryEmail } = data;
        reauthenticate(temporaryEmail, passwordValueRedux);
        dispatch(setAccountPassword(passwordValueRedux));
        dispatch(setAccountRepeatedPassword(repeatPasswordValueRedux));
        history.push('/sme-reg/password-verified');
      })
      .catch(() => {
        // TODO: add error when the server is not responding
        // TODO: add error if changing the password failed
      });
  };
  const pageModel = (
    <SetPassword
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      heading={copyProvider.getCopy(
        'SMEReg.CreatePassword.s3',
        'Create your password'
      )}
      body={{
        line1: copyProvider.getCopy(
          'SMEReg.CreatePassword.s4',
          'This is the password you will use to sign in to your account.'
        ),
        line2: copyProvider.getCopy(
          'SMEReg.CreatePassword.s6',
          'Your password must:'
        ),
        luElement: {
          liElement1: copyProvider.getCopy(
            'SMEReg.CreatePassword.s9',
            'be at least 8 characters or longer'
          ),
          liElement2: copyProvider.getCopy(
            'SMEReg.CreatePassword.s10',
            'include at least 1 uppercase letter'
          ),
          liElement3: copyProvider.getCopy(
            'SMEReg.CreatePassword.s11',
            'include at least 1 lowercase letter'
          ),
          liElement4: copyProvider.getCopy(
            'SMEReg.CreatePassword.s12',
            'include at least 1 number'
          ),
          liElement5: copyProvider.getCopy(
            'SMEReg.CreatePassword.s13',
            'include a symbol'
          ),
        },
      }}
      inputs={{
        password: {
          label: copyProvider.getCopy(
            'SMEReg.CreatePassword.s5',
            'Create a password'
          ),
          value: passwordValueRedux,
          onChange: updatedPasswordValue,
          errorMessage: errorMsg,
          errorMessage2: errorMsgMatch,
        },
        repeatPassword: {
          label: copyProvider.getCopy(
            'SMEReg.CreatePassword.s7',
            'Repeat your password'
          ),
          value: repeatPasswordValueRedux,
          onChange: updatedRepeatPasswordValue,
        },
      }}
      button={{
        text: copyProvider.getCopy('SMEReg.CreatePassword.s8', 'Continue'),
        onClick: handleBtnClick,
      }}
    />
  );
  return pageModel;
};

export default CreatePassword;
