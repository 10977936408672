import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import {
  getRegStartDate,
  getSmeRegStartDate,
  getResubmitClaimStartDate,
} from '../features/ga/gaSlice';

const useGaEndEvent = (journeyCategory, eventName) => {
  const sendDataToGTM = useGTMDispatch();
  let stDate = '';
  if (journeyCategory.trim() === 'LandlordRegistration')
    stDate = useSelector(getRegStartDate);
  else if (journeyCategory.trim() === 'SmeRegistration')
    stDate = useSelector(getSmeRegStartDate);
  else if (journeyCategory.trim() === 'ResubmitClaim')
    stDate = useSelector(getResubmitClaimStartDate);

  React.useEffect(() => {
    const startDt = moment(new Date(stDate));
    const endDt = moment(new Date());
    const diff = endDt.diff(startDt);
    const diffDuration = moment.duration(diff);
    sendDataToGTM({
      event: eventName,
      category: journeyCategory,
      value: startDt.format('MMMM Do YYYY, h:mm:ss a'),
      label: `${diffDuration.minutes()} Minutes ${diffDuration.seconds()} Seconds`,
      startDt: startDt.format('MMMM Do YYYY, h:mm:ss a'),
      endDt: endDt.format('MMMM Do YYYY, h:mm:ss a'),
    });
  }, []);
  return () => {};
};

export default useGaEndEvent;
