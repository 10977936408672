import { useDispatch } from 'react-redux';
import { resetCustomer } from '../features/customer/customerJourneySlice';
import { resetInstallerJourney } from '../features/installer/installerJourneySlice';
import { resetApplicationJourney } from '../features/application/applicationJourneySlice';
import { resetDashboardSlice } from '../features/dashboard/dashboardSlice';
import { resetPmcJourney } from '../features/pmc/pmcJourneySlice';
import { resetRegistrationJourney } from '../features/registration/registrationJourneySlice';

export function useResetReduxState(...sliceNames) {
  const dispatch = useDispatch();
  sliceNames.forEach((sliceName) => {
    try {
      if (sliceName === 'customer') {
        dispatch(resetCustomer());
      } else if (sliceName === 'installerJourney') {
        dispatch(resetInstallerJourney());
      } else if (sliceName === 'applicationJourney') {
        dispatch(resetApplicationJourney());
      } else if (sliceName === 'dashboardSlice') {
        dispatch(resetDashboardSlice());
      } else if (sliceName === 'pmcJourney') {
        dispatch(resetPmcJourney());
      } else if (sliceName === 'registrationJourney') {
        dispatch(resetRegistrationJourney());
      }
      return () => {
        'Success';
      };
    } catch (error) {
      return () => {
        'Failed';
      };
    }
  });

  return '';
}

export function useSetReduxState() {
  const dispatch = useDispatch();

  function setResetReduxState(...sliceNames) {
    sliceNames.forEach((sliceName) => {
      try {
        if (sliceName === 'customer') {
          dispatch(resetCustomer());
        } else if (sliceName === 'installerJourney') {
          dispatch(resetInstallerJourney());
        } else if (sliceName === 'applicationJourney') {
          dispatch(resetApplicationJourney());
        } else if (sliceName === 'dashboardSlice') {
          dispatch(resetDashboardSlice());
        } else if (sliceName === 'pmcJourney') {
          dispatch(resetPmcJourney());
        } else if (sliceName === 'registrationJourney') {
          dispatch(resetRegistrationJourney());
        }
        return () => {
          'Success';
        };
      } catch (error) {
        return () => {
          'Failed';
        };
      }
    });
  }

  return [setResetReduxState];
}
