import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ContactInfo } from '@pepper-ui/common';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import {
  setAccountFirstName,
  setAccountLastName,
  setAccountRoleName,
  setAccountMobileNumber,
  setAccountTelephoneNumber,
  getAccountFirstName,
  getAccountLastName,
  getAccountRoleName,
  getAccountMobileNumber,
  getAccountTelephoneNumber,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';
import { updateContactDetails } from '../../firebase';

const ContactDetails = () => {
  const sendDataToGTM = useGTMDispatch();
  const firstNameRedux = useSelector(getAccountFirstName);
  const lastNameRedux = useSelector(getAccountLastName);
  const roleNameRedux = useSelector(getAccountRoleName);
  const mobileNumberRedux = useSelector(getAccountMobileNumber);
  const telephoneNumberRedux = useSelector(getAccountTelephoneNumber);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.ContactDetails.s18',
    'Enter your contact details - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const [errorAlreadyExists, setErrorAlreadyExists] = useState(false);

  const updateFirstName = (event) => {
    dispatch(setAccountFirstName(event.target.value));
  };
  const updateLastName = (event) => {
    dispatch(setAccountLastName(event.target.value));
  };
  const updatedRoleName = (event) => {
    dispatch(setAccountRoleName(event.target.value));
  };
  const updatedMobileNumber = (event) => {
    dispatch(setAccountMobileNumber(event.target.value));
  };
  const updatedTelephoneNumber = (event) => {
    dispatch(setAccountTelephoneNumber(event.target.value));
  };

  const errorFirstName = copyProvider.getCopy(
    'SMEReg.ContactDetails.s1',
    'Enter a valid first name'
  );

  const errorLastName = copyProvider.getCopy(
    'SMEReg.ContactDetails.s16',
    'Enter a valid last name'
  );

  const errorRoleMsg = copyProvider.getCopy(
    'SMEReg.ContactDetails.s2',
    'Enter a valid business role'
  );

  const errorMobileMsg = copyProvider.getCopy(
    'SMEReg.ContactDetails.s3',
    'Enter a valid mobile phone number'
  );

  const errorTelephoneMsg = copyProvider.getCopy(
    'SMEReg.ContactDetails.s4',
    'Enter a valid office landline number'
  );

  const accountAlreadyExistsMsg = copyProvider.getCopy(
    'SMEReg.ContactDetails.s17',
    'An account already exists with the mobile number'
  );

  const handleBtnClick = () => {
    sendDataToGTM({
      event: 'reg_contact_details',
      category: 'Registration',
    });
    updateContactDetails({
      firstName: firstNameRedux,
      lastName: lastNameRedux,
      roleName: roleNameRedux,
      mobileNumber: mobileNumberRedux,
      telephoneNumber: telephoneNumberRedux,
    })
      .then(() => {
        setErrorAlreadyExists(false);
        dispatch(setAccountFirstName(firstNameRedux));
        dispatch(setAccountLastName(lastNameRedux));
        dispatch(setAccountRoleName(roleNameRedux));
        dispatch(setAccountMobileNumber(mobileNumberRedux));
        dispatch(setAccountTelephoneNumber(telephoneNumberRedux));
        history.push('/sme-reg/access-code-mobile');
      })
      .catch((e) => {
        if (e.code === 'functions/already-exists') {
          setErrorAlreadyExists(true);
        }
      });
  };

  const pageModel = (
    <ContactInfo
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      heading={{
        contactDetailsTitle: copyProvider.getCopy(
          'SMEReg.ContactDetails.s5',
          'Enter your contact details'
        ),
      }}
      paragraphs={{
        confirmApplication: copyProvider.getCopy(
          'SMEReg.ContactDetails.s6',
          'We will use your contact details to confirm your application and to complete the account process.'
        ),
      }}
      inputs={{
        firstName: {
          label: copyProvider.getCopy('SMEReg.ContactDetails.s7', 'First name'),
          value: firstNameRedux,
          onChange: updateFirstName,
          errorMessage: errorFirstName,
        },
        lastName: {
          label: copyProvider.getCopy('SMEReg.ContactDetails.s8', 'Last name'),
          value: lastNameRedux,
          onChange: updateLastName,
          errorMessage: errorLastName,
        },
        companyRole: {
          label: copyProvider.getCopy(
            'SMEReg.ContactDetails.s9',
            'Your role in the company'
          ),
          hint: copyProvider.getCopy(
            'SMEReg.ContactDetails.s10',
            'For example, property owner or managing director.'
          ),
          value: roleNameRedux,
          onChange: updatedRoleName,
          errorMessage: errorRoleMsg,
        },
        mobileNo: {
          label: copyProvider.getCopy(
            'SMEReg.ContactDetails.s11',
            'Mobile phone number'
          ),
          hint: copyProvider.getCopy(
            'SMEReg.ContactDetails.s12',
            'We will send you a verification code to verify your phone number.'
          ),
          value: mobileNumberRedux,
          onChange: updatedMobileNumber,
          errorMessage: errorMobileMsg,
        },
        telNo: {
          label: copyProvider.getCopy(
            'SMEReg.ContactDetails.s13',
            'Office landline number (optional)'
          ),
          hint: copyProvider.getCopy(
            'SMEReg.ContactDetails.s15',
            'We will use your mobile or office landline number to contact you if we need more information.'
          ),
          value: telephoneNumberRedux,
          onChange: updatedTelephoneNumber,
          errorMessage: errorTelephoneMsg,
        },
      }}
      button={{
        onClick: handleBtnClick,
        text: copyProvider.getCopy('SMEReg.ContactDetails.s14', 'Continue'),
      }}
      hasServiceError={errorAlreadyExists}
      serviceErrorMessage={accountAlreadyExistsMsg}
    />
  );

  return pageModel;
};

export default ContactDetails;
