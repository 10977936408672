import React from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentUserAccount,
  getDashboardLink,
} from '../features/app/appSlice';

const hideMenu = () => {
  const currentUser = useSelector(getCurrentUserAccount);
  const dashboardLink = useSelector(getDashboardLink);
  React.useEffect(() => {
    const userNotLoggedIn =
      !currentUser || !currentUser.emailVerified || !dashboardLink;
    if (userNotLoggedIn) {
      document
        ?.querySelector('.govuk-header__menu-button')
        ?.classList.add('govuk-visually-hidden');
    } else {
      document
        ?.querySelector('.govuk-header__menu-button')
        ?.classList.remove('govuk-visually-hidden');
    }
    return () => {
      document
        ?.querySelector('.govuk-header__menu-button')
        ?.classList.remove('govuk-visually-hidden');
    };
  });
};

export default hideMenu;
