import ReactGA from 'react-ga4';

const useGaEvents = () => {
  return (
    category = 'deault category',
    action = 'default action',
    label = 'default label ' // optional for ReactGA.event but setting default
  ) => {
    ReactGA.event({ category, action, label });
  };
};

export default useGaEvents;
