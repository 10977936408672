import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'govuk-react-jsx';
import { useSelector } from 'react-redux';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../../features/app/appSlice';
import { getClaimsData } from '../../../features/customer/customerJourneySlice';
import ClaimSummary from '../../common/ClaimSummary';
import InstallationDetails from '../../common/InstallationDetails';
import FinancialDetails from '../../common/FinancialDetails';
import ChargepointDetails from '../../common/ChargepointDetails';
import useTitle from '../../../hooks/useTitle';

const ReviewClaim = () => {
  const { id } = useParams();
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const claimsValue = useSelector(getClaimsData);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const claimData = claimsValue.filter((c) => c.document_id === id)[0];

  useTitle(
    'Installer.Resubmit.ReviewClaim.s3',
    'View your residential claim - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleBackToResubmitYourClaim = () => {
    history.push(`/resubmit-journey/resubmit-claim/${claimData.document_id}`);
  };
  return (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'Installer.Resubmit.ReviewClaim.s1',
          'View your claim'
        )}
      </h1>
      <ClaimSummary claimData={claimData} />
      <InstallationDetails claimData={claimData} />
      <FinancialDetails claimData={claimData} />
      <ChargepointDetails claimData={claimData} />

      <Button onClick={handleBackToResubmitYourClaim}>
        {copyProvider.getCopy(
          'Installer.Resubmit.ReviewClaim.s2',
          'Back to resubmit your claim'
        )}
      </Button>
    </>
  );
};

export default ReviewClaim;
