/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import CopyProvider from '../../utils/copy-provider';
import cy from '../../copies/cy.json';
import en from '../../copies/en.json';

const cyCopy = {
  cy,
};

const enCopy = {
  en,
};

const initialState = {
  currentUserAccount: null,
  dashboardLink: null,
  currentLanguage: 'en',
  copyProviderCY: new CopyProvider('cy', cyCopy, 'nested'),
  copyProviderEN: new CopyProvider('en', enCopy, 'nested'),
  displayBackLink: true,
  isUserLocked: false,
  tokenCount: 0,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentUserAccount: (state, action) => {
      state.currentUserAccount = action.payload;
    },
    setDashboardLink: (state, action) => {
      state.dashboardLink = action.payload;
    },
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
    setDisplayGoBackLink: (state, action) => {
      state.displayBackLink = action.payload;
    },
    setUserLockedStatus: (state, action) => {
      state.isUserLocked = action.payload;
    },
    setTokenCount: (state, action) => {
      state.tokenCount = action.tokenCount;
    },
  },
});

export const {
  setCurrentLanguage,
  setDisplayGoBackLink,
  setUserLockedStatus,
  setTokenCount,
} = appSlice.actions;

export const getCurrentUserAccount = (state) => state.app.currentUserAccount;
export const getDashboardLink = (state) => state.app.dashboardLink;
export const getCurrentLanguage = (state) => state.app.currentLanguage;
export const getCopyProviderCY = (state) => state.app.copyProviderCY;
export const getCopyProviderEN = (state) => state.app.copyProviderEN;
export const getDisplayGoBackLink = (state) => state.app.displayBackLink;
export const getUserLockedStatus = (state) => state.app.isUserLocked;
export const getTokenCount = (state) => state.app.tokenCount;

export default appSlice.reducer;
