import React, { useEffect, useState } from 'react';
import { Button } from 'govuk-react-jsx';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  getDashboardLink,
} from '../features/app/appSlice';
import { updateSubmissionDeadlineDate } from '../firebase';

const ApplicationRenewed = () => {
  const { grantType, id } = useParams();
  const dashboardLink = useSelector(getDashboardLink);
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const [claimData, setClaimData] = useState({});

  const handleButton = () => {
    window.location.assign('/installer/claim-grant/installer-dashboard');
  };

  useEffect(() => {
    updateSubmissionDeadlineDate({ id, grantType })
      .then((response) => {
        const { data } = response;
        setClaimData(data);
      })
      .catch(() => {
        window.location.assign(dashboardLink);
      });
  }, []);

  const pageModel = (
    <div>
      <div className="govuk-panel govuk-panel--confirmation">
        <h1 className="govuk-panel__title">
          {copyProvider.getCopy(
            'Installer.ApplicationRenewed.s1',
            'Application renewed'
          )}
        </h1>
        <div className="govuk-panel__body">
          {copyProvider.getCopy(
            'Installer.ApplicationRenewed.s2',
            'Your reference number:'
          )}
          <br />
          <strong>{claimData.claimId}</strong>
        </div>
      </div>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.ApplicationRenewed.s3',
          'The application for'
        )}
        {` ${claimData.landlordAccountName} `}
        {copyProvider.getCopy(
          'Installer.ApplicationRenewed.s4',
          'has been extended to'
        )}
        <strong> {` ${claimData.claimDeadlineDate}.`}</strong>
      </p>

      <div>
        <h2 className="govuk-heading-m">
          {copyProvider.getCopy(
            'Installer.ApplicationRenewed.s5',
            'What happens next'
          )}
        </h2>
        <p className="govuk-body">
          {copyProvider.getCopy(
            'Installer.ApplicationRenewed.s6',
            'You will need to complete the installation and submit the claim by'
          )}
          {` ${claimData.claimDeadlineDate}. `}
          {copyProvider.getCopy(
            'Installer.ApplicationRenewed.s7',
            'If this is not enough time you can renew for a further 90 days.'
          )}
        </p>
      </div>

      <Button
        className="govuk-button govuk-!-margin-top-4"
        onClick={handleButton}
      >
        {copyProvider.getCopy(
          'Installer.ApplicationRenewed.s8',
          'Manage your applications'
        )}
      </Button>
    </div>
  );
  return pageModel;
};

export default ApplicationRenewed;
