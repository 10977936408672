import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  setDisplayGoBackLink,
} from '../../features/app/appSlice';

const AccountLocked = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const handleLinkClick = () => {
    window.location.assign('/evcg/password-reset-link');
  };

  useEffect(() => {
    dispatch(setDisplayGoBackLink(false));
    return () => dispatch(setDisplayGoBackLink(true));
  }, []);

  return (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'AccountLocked.s1',
          'Your account has been locked'
        )}
      </h1>
      <p className="govuk-body govuk-!-margin-top-7">
        {copyProvider.getCopy(
          'AccountLocked.s2',
          'We have locked your account because your sign in details were wrong 5 times.'
        )}
        <br />
        {copyProvider.getCopy(
          'AccountLocked.s3',
          'Your account has been locked for 15 minutes, even if you reset your password.'
        )}
      </p>
      <h3 className="govuk-heading-s govuk-!-margin-top-7">
        {copyProvider.getCopy('AccountLocked.s4', 'Need help signing in?')}
      </h3>
      <Link
        className="govuk-link govuk-body-m govuk-!-margin-top-5"
        to={{}}
        onClick={handleLinkClick}
      >
        {copyProvider.getCopy(
          'AccountLocked.s5',
          'I have forgotten my password'
        )}
      </Link>
    </>
  );
};

export default AccountLocked;
