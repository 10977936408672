import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { EmailVerified } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';

const VerifiedEmail = () => {
  const sendDataToGTM = useGTMDispatch();
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);

  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.VerifiedEmail.s3',
    'You have verified your email address - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleBtnClick = () => {
    sendDataToGTM({
      event: 'reg_email_verified',
      category: 'Registration',
    });
    history.push('/sme-reg/contact');
  };

  return (
    <EmailVerified
      heading={copyProvider.getCopy(
        'SMEReg.VerifiedEmail.s1',
        'You have verified your email address'
      )}
      button={{
        text: copyProvider.getCopy('SMEReg.VerifiedEmail.s2', 'Continue'),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default VerifiedEmail;
