import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'govuk-react-jsx';
import { Link } from 'react-router-dom';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import {
  getUserAccountInfo,
  setUserAccountInfo,
  getUserInfo,
  setGrantsRefData,
  getGrantsRefData,
} from '../../features/dashboard/dashboardSlice';
import { formatMobileNumber } from '../../utils/format-number';
import { getUserData, getGrantsData } from '../../firebase';
import useTitle from '../../hooks/useTitle';
import displayMenu from '../../hooks/displayMenu';

const YourAccount = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const currentUserData = useSelector(getUserAccountInfo);
  const currentUserInfo = useSelector(getUserInfo);
  const userType = currentUserInfo?.account_type;
  const grantRefData = useSelector(getGrantsRefData);
  const LANDLORD = 'LANDLORD';
  const INSTALLER = 'INSTALLER';
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const [accountSelected, setAccountSelected] = useState('personal');
  useTitle(
    'dashboardHub.YourAccount.s30',
    'Your account - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const cssClassSelected =
    'moj-side-navigation__item moj-side-navigation__item--active';
  const cssClassNotSelected = 'moj-side-navigation__item';

  const personalTabCss =
    accountSelected === 'personal' ? cssClassSelected : cssClassNotSelected;
  const businessTabCss =
    accountSelected === 'business' ? cssClassSelected : cssClassNotSelected;
  const grantUsageTabCss =
    accountSelected === 'grantUsage' ? cssClassSelected : cssClassNotSelected;

  displayMenu();
  useEffect(() => {
    if (currentUserData !== null) return;
    getUserData()
      .then((response) => {
        const { data } = response;
        dispatch(setUserAccountInfo(data));
      })
      .catch(() => {
        // TODO: Show error message
      });
    getGrantsData()
      .then((response) => {
        const { data } = response;
        dispatch(setGrantsRefData(data.availableGrants));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const businessAddress = currentUserData && (
    <>
      <section>
        {currentUserData.businessAddressLine1 &&
          `${currentUserData.businessAddressLine1},`}
      </section>
      <section>
        {currentUserData.businessAddressLine2 &&
          `${currentUserData.businessAddressLine2},`}
      </section>
      <section>
        {currentUserData.businessAddressCity &&
          `${currentUserData.businessAddressCity},`}
      </section>
      <section>
        {currentUserData.businessAddressCounty &&
          `${currentUserData.businessAddressCounty},`}
      </section>
      <section>
        {currentUserData.businessAddressPostcode &&
          currentUserData.businessAddressPostcode}
        {currentUserData.businessAddressCountry && ','}
      </section>
      <section>
        {currentUserData.businessAddressCountry &&
          currentUserData.businessAddressCountry}
      </section>
    </>
  );

  const handleChangePassword = () => {
    window.location.assign('/evcg/update-password');
  };

  const pageModel = (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy('dashboardHub.YourAccount.s1', 'Your account')}
      </h1>

      <div className="govuk-width-container">
        <div className="govuk-grid-column-one-quarter">
          <nav className="moj-side-navigation" aria-label="Side navigation">
            <ul className="moj-side-navigation__list">
              <li className={personalTabCss}>
                <Link
                  className="govuk-link"
                  to="/dashboardHub/personal-details"
                  aria-current="location"
                  onClick={(e) => {
                    e.preventDefault();
                    setAccountSelected('personal');
                  }}
                >
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s5',
                    'Personal details'
                  )}
                </Link>
              </li>
              <li className={businessTabCss}>
                <Link
                  className="govuk-link"
                  to="/dashboardHub/business-details"
                  aria-current="location"
                  onClick={(e) => {
                    e.preventDefault();
                    setAccountSelected('business');
                  }}
                >
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s15',
                    'Business details'
                  )}
                </Link>
              </li>
              {userType === 'Landlord' && (
                <li className={grantUsageTabCss}>
                  <Link
                    className="govuk-link"
                    to="/dashboardHub/grant-usage"
                    aria-current="location"
                    onClick={(e) => {
                      e.preventDefault();
                      setAccountSelected('grantUsage');
                    }}
                  >
                    {copyProvider.getCopy(
                      'dashboardHub.YourAccount.s31',
                      'Grant Usage'
                    )}
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>

        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            {accountSelected === 'personal' && (
              <>
                <h1 className="govuk-heading-l">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s5',
                    'Personal details'
                  )}
                </h1>
                <Table
                  className="govuk-summary-list govuk-!-margin-bottom-9"
                  firstCellIsHeader
                  rows={[
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s2',
                            'Name'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.firstName}{' '}
                              {currentUserData.lastName}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s3',
                            'User account'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.userLevel === 'Primary'
                                ? 'Admin'
                                : ''}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s4',
                            'Password'
                          ),
                          format: 'text',
                        },
                        {
                          children: '**********',
                          format: 'text',
                        },
                        {
                          children: (
                            <Link
                              className="govuk-link"
                              to={{
                                state: { isFromCheckAnswers: true },
                              }}
                              onClick={handleChangePassword}
                            >
                              {copyProvider.getCopy(
                                'dashboardHub.YourAccount.s6',
                                'Change'
                              )}
                            </Link>
                          ),
                          format: 'numeric',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s16',
                            'Role'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.roleWithinBusiness}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s7',
                            'Registered email'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>{currentUserData.accountEmail}</section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s8',
                            'Mobile number'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.mobileNumber &&
                                formatMobileNumber(
                                  currentUserData.mobileNumber
                                )}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                  ]}
                />
              </>
            )}
            {accountSelected === 'business' && (
              <>
                <h1 className="govuk-heading-l">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s15',
                    'Business details'
                  )}
                </h1>
                <Table
                  className="govuk-summary-list govuk-!-margin-bottom-9"
                  firstCellIsHeader
                  rows={[
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s9',
                            'Company name'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>{currentUserData.businessName}</section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s10',
                            'Type of account'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>{currentUserData?.accountType}</section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() === LANDLORD && {
                      cells: [
                        {
                          children: currentUserData.companyRegNumber
                            ? copyProvider.getCopy(
                                'dashboardHub.YourAccount.s11',
                                'Company registration number'
                              )
                            : copyProvider.getCopy(
                                'dashboardHub.YourAccount.s17',
                                'Company VAT number'
                              ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.companyRegNumber
                                ? currentUserData.companyRegNumber
                                : currentUserData.vatRegNumber}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() === LANDLORD && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s12',
                            'Registered company address'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>{businessAddress}</section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s18',
                            'Primary contact'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>{currentUserData.primaryContact}</section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s19',
                            'Email'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>{currentUserData.email}</section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s20',
                            'OZEV Number'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.installer_number}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s21',
                            'City'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.businessAddressCity}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s22',
                            'Postcode'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.businessAddressPostcode}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s23',
                            'Authorised for residential'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.authorised_for_residential
                                ? copyProvider.getCopy(
                                    'dashboardHub.YourAccount.s26',
                                    'Yes'
                                  )
                                : copyProvider.getCopy(
                                    'dashboardHub.YourAccount.s27',
                                    'No'
                                  )}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s24',
                            'Authorised for commercial'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.authorised_for_commercial
                                ? copyProvider.getCopy(
                                    'dashboardHub.YourAccount.s26',
                                    'Yes'
                                  )
                                : copyProvider.getCopy(
                                    'dashboardHub.YourAccount.s27',
                                    'No'
                                  )}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                    currentUserData?.accountType.toUpperCase() ===
                      INSTALLER && {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s25',
                            'Installer representative'
                          ),
                          format: 'text',
                        },
                        {
                          children: currentUserData && (
                            <section>
                              {currentUserData.installer_representative
                                ? copyProvider.getCopy(
                                    'dashboardHub.YourAccount.s26',
                                    'Yes'
                                  )
                                : copyProvider.getCopy(
                                    'dashboardHub.YourAccount.s27',
                                    'No'
                                  )}
                            </section>
                          ),
                          format: 'text',
                        },
                        {
                          children: '',
                          format: 'text',
                        },
                      ],
                    },
                  ]}
                />
              </>
            )}
            {accountSelected === 'grantUsage' && (
              <>
                <h1 className="govuk-heading-l">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s32',
                    'Grant Usage'
                  )}
                </h1>
                <p className="govuk-body">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s33',
                    'Grant allowances are renewed on 6 April each year.'
                  )}
                </p>
                <p className="govuk-heading-s">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s34',
                    'EV chargepoint grant for residential landlords'
                  )}
                </p>

                <Table
                  className="govuk-summary-list govuk-!-margin-bottom-9"
                  firstCellIsHeader
                  rows={[
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s35',
                            "Total number of sockets you've applied for"
                          ),
                          format: 'text',
                        },
                        {
                          children: copyProvider.getCopy(
                            currentUserInfo.resi_count_of_applications_submitted_for_sockets_in_year
                              ? currentUserInfo.resi_count_of_applications_submitted_for_sockets_in_year
                              : 0,
                            currentUserInfo.resi_count_of_applications_submitted_for_sockets_in_year
                              ? currentUserInfo.resi_count_of_applications_submitted_for_sockets_in_year
                              : 0
                          ),
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s36',
                            'Remaining socket allowance'
                          ),
                          format: 'text',
                        },
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.sxx',
                            grantRefData.find(
                              (grant) =>
                                grant.grantType === 'LANDLORD_RESIDENTIAL_GRANT'
                            ).quota -
                              (currentUserInfo.resi_count_of_applications_submitted_for_sockets_in_year
                                ? currentUserInfo.resi_count_of_applications_submitted_for_sockets_in_year
                                : 0)
                          ),
                          format: 'number',
                        },
                      ],
                    },
                  ]}
                />
                <p className="govuk-heading-s">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s37',
                    'EV chargepoint grant for commercial landlords'
                  )}
                </p>
                <Table
                  className="govuk-summary-list govuk-!-margin-bottom-9"
                  firstCellIsHeader
                  rows={[
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s38',
                            "Total number of sockets you've applied for"
                          ),
                          format: 'text',
                        },
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.sxxx',
                            currentUserInfo.comm_count_of_applications_submitted_for_sockets_in_year
                              ? currentUserInfo.comm_count_of_applications_submitted_for_sockets_in_year
                              : 0
                          ),
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s39',
                            'Remaining socket allowance'
                          ),
                          format: 'text',
                        },
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.sxx',
                            grantRefData.find(
                              (grant) =>
                                grant.grantType === 'LANDLORD_COMMERCIAL_GRANT'
                            ).quota -
                              (currentUserInfo.comm_count_of_applications_submitted_for_sockets_in_year
                                ? currentUserInfo.comm_count_of_applications_submitted_for_sockets_in_year
                                : 0)
                          ),
                          format: 'number',
                        },
                      ],
                    },
                  ]}
                />
                <p className="govuk-heading-s">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s40',
                    'EV infrastructure grant for residential car parks'
                  )}
                </p>
                <Table
                  className="govuk-summary-list govuk-!-margin-bottom-9"
                  firstCellIsHeader
                  rows={[
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s41',
                            'Applications submitted'
                          ),
                          format: 'text',
                        },
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.sxxx',
                            currentUserInfo.carpark_resi_claim_count
                              ? currentUserInfo.carpark_resi_claim_count
                              : 0
                          ),
                          format: 'text',
                        },
                      ],
                    },
                    {
                      cells: [
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.s42',
                            'Remaining application allowance'
                          ),
                          format: 'text',
                        },
                        {
                          children: copyProvider.getCopy(
                            'dashboardHub.YourAccount.sxx',
                            grantRefData.find(
                              (grant) =>
                                grant.grantType === 'CAR_PARK_RESIDENTIAL'
                            ).quota -
                              (currentUserInfo.carpark_resi_claim_count
                                ? currentUserInfo.carpark_resi_claim_count
                                : 0)
                          ),
                          format: 'number',
                        },
                      ],
                    },
                  ]}
                />
              </>
            )}
            {currentUserData?.accountType.toUpperCase() !== INSTALLER && (
              <div className="govuk-body">
                {copyProvider.getCopy(
                  'dashboardHub.YourAccount.s13',
                  'These details were provided when you registered and cannot be changed.'
                )}
                <p>
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s14',
                    'To make changes, you need to send an email to OZEV_IT_issues@dvla.gov.uk. You will have to provide your business name, company registration number and what you would like to change.'
                  )}
                </p>
              </div>
            )}
            {currentUserData?.accountType.toUpperCase() === INSTALLER && (
              <>
                <p className="govuk-body">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s29',
                    'These details were provided when you registered cannot be changed.'
                  )}
                </p>
                <p className="govuk-body">
                  {copyProvider.getCopy(
                    'dashboardHub.YourAccount.s28',
                    'To change any details you will need to contact DVLA by email, providing your business name and what you need to change.'
                  )}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
  return pageModel;
};

export default YourAccount;
