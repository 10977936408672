import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { CreateAccount as CommonCreateAccount } from '@pepper-ui/common';
// import { checkValidEmail } from '../../regex/regex';
import {
  setAccountEmail,
  getAccountEmail,
  setRepeatAccountEmail,
  getRepeatAccountEmail,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';
import { createAccount } from '../../firebase';

const CreateAccount = () => {
  const sendDataToGTM = useGTMDispatch();
  const emailValueRedux = useSelector(getAccountEmail);
  const repeatEmailValueRedux = useSelector(getRepeatAccountEmail);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const [serviceErrorNotAPIValid, setServiceErrorNotAPIValid] = useState(false);
  const [serviceErrorAlreadyExists, setServiceErrorAlreadyExists] =
    useState(false);

  useTitle(
    'SMEReg.CreateAccount.s16',
    'Enter your email - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const updateEmailValue = (event) => {
    dispatch(setAccountEmail(event.target.value));
  };

  const updateRepeatEmailValue = (event) => {
    dispatch(setRepeatAccountEmail(event.target.value));
  };

  const errValidMessage = copyProvider.getCopy(
    'SMEReg.CreateAccount.s1',
    'Email address needs to be valid'
  );
  const errMatchMessage = copyProvider.getCopy(
    'SMEReg.CreateAccount.s2',
    'Email addresses need to match'
  );
  const errExistEmail = copyProvider.getCopy(
    'SMEReg.CreateAccount.s3',
    'An account already exists with the email address'
  );

  const handleBtnClick = () => {
    // eslint-disable-next-line no-console
    sendDataToGTM({
      event: 'reg_create_account',
      category: 'Registration',
    });
    createAccount({ email: emailValueRedux })
      .then(() => {
        dispatch(setAccountEmail(emailValueRedux));
        history.push('/sme-reg/check-email');
      })
      .catch((error) => {
        // add error when the email is already-exists
        if (error.code === 'functions/already-exists') {
          // history.push('/sme-reg/existing-email');
          setServiceErrorAlreadyExists(true);
        } else {
          // add error when the server is not responding
          setServiceErrorNotAPIValid(true);
        }
      });
  };

  const pageModel = (
    <div>
      <CommonCreateAccount
        summary={{
          title: copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          ),
        }}
        heading={copyProvider.getCopy(
          'SMEReg.CreateAccount.s4',
          'Enter your email'
        )}
        body={{
          paragraphOne: copyProvider.getCopy(
            'SMEReg.CreateAccount.s5',
            'This is the email address that you will use to sign in to your account.'
          ),
          paragraphTwo: copyProvider.getCopy(
            'SMEReg.CreateAccount.s6',
            ' We will send a code to verify your email.'
          ),
        }}
        enterEmailInput={{
          emailInvalidError: errValidMessage,
          emailLabel: copyProvider.getCopy(
            'SMEReg.CreateAccount.s7',
            'Enter your email address'
          ),
          onChangeEmailInput: updateEmailValue,
        }}
        enterRepeatEmailInput={{
          emailNotMatchingError: errMatchMessage,
          repeatEmailLabel: copyProvider.getCopy(
            'SMEReg.CreateAccount.s8',
            'Repeat your email address'
          ),
          onChangeRepeatEmailInput: updateRepeatEmailValue,
        }}
        button={{
          btnText: copyProvider.getCopy('SMEReg.CreateAccount.s9', 'Continue'),
          handleBtnClick,
        }}
        serviceErrors={{
          errorMsgNotValid: {
            hasError: serviceErrorNotAPIValid,
            message: errValidMessage,
          },
          errorMsgAlreadyExists: {
            hasError: serviceErrorAlreadyExists,
            message: errExistEmail,
          },
        }}
        value={{
          updatedEmailValue: emailValueRedux,
          updatedRepeatEmailValue: repeatEmailValueRedux,
        }}
      />
    </div>
  );

  return pageModel;
};

export default CreateAccount;
