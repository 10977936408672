import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PasswordVerified } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';

const PasswordCreated = () => {
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);

  useTitle(
    'SMEReg.PasswordCreated.s3',
    'You have created a password - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const handleBtnClick = () => {
    history.push('/sme-reg/linked-enterprise');
  };

  return (
    <PasswordVerified
      heading={copyProvider.getCopy(
        'SMEReg.PasswordCreated.s1',
        'You have created a password'
      )}
      button={{
        text: copyProvider.getCopy('SMEReg.PasswordCreated.s2', 'Continue'),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default PasswordCreated;
