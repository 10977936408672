/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  regStartDate: '',
  smeRegStartDate: '',
  resubmitClaimStartDate: '',
  pmcRegStartDate: '',
};

export const gaSlice = createSlice({
  name: 'gaSlice',
  initialState,
  reducers: {
    setRegStartDate: (state, action) => {
      state.regStartDate = action.payload;
    },
    setSmeRegStartDate: (state, action) => {
      state.smeRegStartDate = action.payload;
    },
    setResubmitClaimStartDate: (state, action) => {
      state.resubmitClaimStartDate = action.payload;
    },
    setPmcRegStartDate: (state, action) => {
      state.pmcRegStartDate = action.payload;
    },
  },
});

export const {
  setRegStartDate,
  setSmeRegStartDate,
  setResubmitClaimStartDate,
  setPmcRegStartDate,
} = gaSlice.actions;

export const getRegStartDate = (state) => state.gaSlice.regStartDate;
export const getSmeRegStartDate = (state) => state.gaSlice.smeRegStartDate;
export const getResubmitClaimStartDate = (state) =>
  state.gaSlice.resubmitClaimStartDate;
export const getPmcRegStartDate = (state) => state.gaSlice.pmcRegStartDate;

export default gaSlice.reducer;
