import React from 'react';
import { SummaryList } from 'govuk-react-jsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import ClaimStatusClassName from './ClaimStatus';
import convertDateToDisplay from '../../utils/convertDateToDisplay';

const ClaimSummary = ({ claimData }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  return (
    <>
      <h1 className="govuk-heading-m govuk-!-margin-top-5">
        {copyProvider.getCopy(
          'Installer.Resubmit.ClaimSummary.s1',
          'Claim summary'
        )}
      </h1>
      <SummaryList
        className="govuk-!-margin-bottom-9"
        rows={[
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.ClaimSummary.s2',
                'Grant type'
              ),
            },
            value: {
              children: claimData.grant_type_long_description,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.ClaimSummary.s3',
                'Reference number'
              ),
            },
            value: {
              children: claimData.customerReferenceNumber,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.ClaimSummary.s4',
                "Customer's name"
              ),
            },
            value: {
              children: `${claimData.firstName}  ${claimData.lastName}`,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.ClaimSummary.s5',
                'Date you submitted this claim'
              ),
            },
            value: {
              children: convertDateToDisplay(claimData.claim_submitted_date),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.ClaimSummary.s6',
                'Claim status'
              ),
            },
            value: {
              children: ClaimStatusClassName(
                claimData.claimStatus.toUpperCase()
              ),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.ClaimSummary.s7',
                'Date this claim was rejected'
              ),
            },
            value: {
              children: convertDateToDisplay(
                claimData.claim_first_rejection_date
              ),
            },
          },
        ]}
      />
    </>
  );
};
ClaimSummary.propTypes = {
  claimData: PropTypes.object.isRequired,
};
export default ClaimSummary;
