/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Input, Button, Table, Tag, ErrorSummary } from 'govuk-react-jsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import {
  setDocumentId,
  getApplicationCount,
  getClientCount,
} from '../../features/dashboard/dashboardSlice';

import { getDashboardPmcData } from '../../firebase';
import displayMenu from '../../hooks/displayMenu';
import useTitle from '../../hooks/useTitle';

const DashboardPMC = () => {
  const dispatch = useDispatch();
  const applicationCountRedux = useSelector(getApplicationCount);
  const clientCountRedux = useSelector(getClientCount);
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'dashboardHub.DashboardPMC.s29',
    'Manage your applications - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const [tablePmcRows, setTablePmcRows] = useState([]);

  const [searchStatus, setSearchStatus] = useState({
    searched: false,
    searchTerm: '',
    resultCount: 0,
    allData: null,
    errorMessage: '',
  });
  const [errorList, setErrorList] = useState([]);

  const checkTagLabel = (claimStatus) => {
    switch (claimStatus) {
      case 'Sent to Installer':
        return <Tag className="govuk-tag--yellow">{claimStatus}</Tag>;
      case 'Expiring Soon':
        return <Tag className="govuk-tag--orange">{claimStatus}</Tag>;
      case 'Awaiting Customer Review':
        return <Tag className="govuk-tag--red">{claimStatus}</Tag>;
      case 'Awaiting customer review':
        return <Tag className="govuk-tag--red">{claimStatus}</Tag>;
      case 'Submitted by Installer':
        return <Tag className="govuk-tag--blue">{claimStatus}</Tag>;
      case 'Completed':
        return <Tag className="govuk-tag--green">{claimStatus}</Tag>;
      case 'Declined':
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
      case 'Expired':
        return <Tag className="govuk-tag--red">{claimStatus}</Tag>;
      default:
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
    }
  };
  const handleClick = (e) => {
    dispatch(setDocumentId(e.document_id));
    if (e.grant_type === 'CAR_PARK_RESIDENTIAL')
      window.location.assign(`/car-parks${e.link}/${e.document_id}`);
    else window.location.assign(`/evcg${e.link}`);
  };

  const handleLandlordSearch = () => {
    const { searchTerm, searched, allData } = searchStatus;
    if (searchTerm.length < 3) {
      setSearchStatus({
        ...searchStatus,
        errorMessage: copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s27',
          'Enter 3 or more characters'
        ),
      });
      setErrorList([
        {
          children: copyProvider.getCopy(
            'dashboardHub.DashboardPMC.s27',
            'Enter 3 or more characters'
          ),
          href: '#input-with-hint-text',
        },
      ]);
      return;
    }

    if (!/^[a-z0-9 -]*$/i.test(searchTerm)) {
      setSearchStatus({
        ...searchStatus,
        errorMessage: copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s28',
          'Enter a valid character'
        ),
      });
      setErrorList([
        {
          children: copyProvider.getCopy(
            'dashboardHub.DashboardPMC.s28',
            'Enter a valid character'
          ),
          href: '#input-with-hint-text',
        },
      ]);

      return;
    }

    const all = searched ? allData : tablePmcRows;
    const searchRows = all.filter((entry) => {
      const { cells } = entry;
      // cell 0 is reference, cell 2 is company and cell 3 is postcode
      return (
        cells[0]?.children
          ?.toLowerCase()
          .startsWith(searchTerm.toLocaleLowerCase()) ||
        cells[2]?.children
          ?.toLowerCase()
          .startsWith(searchTerm.toLocaleLowerCase()) ||
        cells[3]?.children
          ?.toLowerCase()
          .startsWith(searchTerm.toLocaleLowerCase())
      );
    });
    setSearchStatus({
      ...searchStatus,
      searched: true,
      allData: all,
      resultCount: searchRows.length,
      errorMessage: '',
    });

    setErrorList([]);

    if (searchRows.length > 0) {
      setTablePmcRows(searchRows);
    }
  };

  const handleLandlordSearchInputChange = (e) => {
    const { value } = e.target;

    setSearchStatus({
      ...searchStatus,
      searchTerm: value,
    });
  };

  const clearLandlordSearch = (e) => {
    e.preventDefault();
    setTablePmcRows(searchStatus.allData);
    setSearchStatus({
      searched: false,
      searchTerm: '',
      resultCount: 0,
      allData: null,
      errorMessage: '',
    });
    setErrorList([]);
  };

  const capitalizeFirstLetter = (word) => {
    return word !== undefined && word
      ? word.charAt(0).toUpperCase() + word.slice(1)
      : '';
  };

  displayMenu();

  useEffect(() => {
    getDashboardPmcData()
      .then((response) => {
        const { data } = response;
        const tablePmcRowsLocal = [];
        data.arr.map((eachData) => {
          return tablePmcRowsLocal.push({
            cells: [
              { children: eachData.claim_id },
              { children: capitalizeFirstLetter(eachData.grant_type_display) },

              { children: eachData.landlord_account_name },
              { children: eachData.installation_address_postcode },
              {
                children: checkTagLabel(eachData.claim_status),
              },
              {
                children: (
                  <Link to={{}} onClick={() => handleClick(eachData)}>
                    {eachData.action}
                  </Link>
                ),
              },
            ],
          });
        });
        setTablePmcRows(tablePmcRowsLocal);
      })
      .catch(() => {});
  }, []);

  // NB: The below section was part of T46 (Pmc without clients)
  const pmcWithoutClients = (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-l">
        {copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s1',
          'Manage your applications'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s2',
          'You have not started any grant applications or registered any clients.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s3',
          'Once you have registered a client you can submit an application.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s4',
          'You can view your account details in ‘Your account’ at the top of this page.'
        )}
      </p>
      <Button
        className="govuk-button govuk-!-margin-top-3"
        onClick={() =>
          window.location.assign('/evcg/pmcClientReg/type-of-landlord')
        }
      >
        {copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s5',
          'Register a new client'
        )}
      </Button>
    </>
  );
  // ---- commented out for later ---- //
  // NB: The below section was part of T47 (Pmc with clients)
  const pmcWithClients = (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-l">
        {copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s1',
          'Manage your applications'
        )}
      </h1>
      <div className="govuk-button-group">
        <Button
          onClick={() => window.location.assign('/evcg/pmcApp/choose-landlord')}
          className="govuk-!-margin-top-5"
        >
          {copyProvider.getCopy(
            'dashboardHub.DashboardPMC.s9',
            'Start a new application'
          )}
        </Button>
        <Button
          onClick={() =>
            window.location.assign('/evcg/pmcClientReg/type-of-landlord')
          }
          className="govuk-!-margin-top-5 govuk-button--secondary"
        >
          {copyProvider.getCopy(
            'dashboardHub.DashboardPMC.s8',
            'Register a new client'
          )}
        </Button>
      </div>
      <br />
    </>
  );

  const pmcTable = (
    <>
      <hr />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <h2 className="govuk-heading-m">
            <label htmlFor="input-with-hint-text">
              {copyProvider.getCopy(
                'dashboardHub.DashboardPMC.s10',
                'Find an application'
              )}
            </label>
          </h2>
          <Input
            hint={{
              children: copyProvider.getCopy(
                'dashboardHub.DashboardPMC.s19',
                'You can search using postcode, reference number or client name'
              ),
            }}
            id="input-with-hint-text"
            name="test-name-2"
            type="text"
            onChange={handleLandlordSearchInputChange}
            value={searchStatus.searchTerm}
            errorMessage={
              searchStatus.errorMessage.length && {
                children: searchStatus.errorMessage,
              }
            }
          />
        </div>
        <div className="govuk-grid-column-one-third app-padding-top-dashboard">
          <Button onClick={handleLandlordSearch}>
            {copyProvider.getCopy('dashboardHub.DashboardPMC.s12', 'Search')}
          </Button>
        </div>
      </div>
      {searchStatus.searched && searchStatus.resultCount === 0 && (
        <>
          <h3 className="govuk-heading-s">
            {copyProvider.getCopy(
              'dashboardHub.DashboardPMC.s21',
              'There are no matching results'
            )}
          </h3>
          <p className="govuk-body">
            {copyProvider.getCopy(
              'dashboardHub.DashboardPMC.s22',
              'Improve your search results by:'
            )}
          </p>
          <ul className="govuk-list govuk-list--bullet">
            <li>
              {copyProvider.getCopy(
                'dashboardHub.DashboardPMC.s23',
                'double-checking your spelling'
              )}
            </li>
            <li>
              {copyProvider.getCopy(
                'dashboardHub.DashboardPMC.s24',
                'using fewer keywords'
              )}
            </li>
          </ul>
          <Link
            className="gov-link"
            style={{ fontWeight: 'normal' }}
            onClick={clearLandlordSearch}
          >
            {copyProvider.getCopy(
              'dashboardHub.DashboardPMC.s20',
              'Clear search'
            )}
          </Link>
        </>
      )}
      {(!searchStatus.searched || searchStatus.resultCount > 0) && (
        <>
          {searchStatus.searched && searchStatus.resultCount > 0 && (
            <>
              <p className="govuk-heading-s" role="status">
                {searchStatus.resultCount}{' '}
                {searchStatus.resultCount > 1
                  ? copyProvider.getCopy(
                      'dashboardHub.DashboardPMC.s25',
                      'applications'
                    )
                  : copyProvider.getCopy(
                      'dashboardHub.DashboardPMC.s26',
                      'application'
                    )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  style={{ fontWeight: 'normal' }}
                  onClick={clearLandlordSearch}
                >
                  {copyProvider.getCopy(
                    'dashboardHub.DashboardPMC.s20',
                    'Clear search'
                  )}
                </Link>
              </p>
              <p />
              <hr />
            </>
          )}
          <Table
            head={[
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardPMC.s13',
                  'Reference number'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardPMC.s14',
                  'Grant type'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardPMC.s15',
                  'Client'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardPMC.s16',
                  'Postcode'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardPMC.s17',
                  'Claim status'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardPMC.s18',
                  'Action'
                ),
              },
            ]}
            rows={tablePmcRows.map((row) => row)}
          />
        </>
      )}
    </>
  );

  const YourDashboard = (
    <div className="govuk-width-container">
      {applicationCountRedux === 0 &&
        clientCountRedux === 0 &&
        pmcWithoutClients}
      {applicationCountRedux === 0 && clientCountRedux > 0 && pmcWithClients}

      {tablePmcRows?.length > 0 && pmcTable}
    </div>
  );

  return YourDashboard;
};

export default DashboardPMC;
