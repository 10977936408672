import React from 'react';
import { useSelector } from 'react-redux';
import { ManageYourApplications } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';

const InstallerDashboardFirstTime = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.DashboardFirstTime.s5',
    'Manage your applications - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleBtnClick = () => {
    window.location.assign('/car-parks/sme-app/staff-and-fleet');
  };
  return (
    <ManageYourApplications
      heading={copyProvider.getCopy(
        'SMEReg.DashboardFirstTime.s1',
        'Manage your applications'
      )}
      body={{
        line1: copyProvider.getCopy(
          'SMEReg.DashboardFirstTime.s2',
          'You have not started any grant applications yet.'
        ),
        line2: copyProvider.getCopy(
          'SMEReg.DashboardFirstTime.s3',
          'Once you submit an application, you will be able to see it and track its progress here.'
        ),
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.DashboardFirstTime.s4',
          'Start a new application'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default InstallerDashboardFirstTime;
