async function readFileAsDataURL(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (reader.result) {
        resolve({
          fileName: file.name,
          fileType: file.type,
          file: reader.result,
        });
      }
    });
    reader.readAsDataURL(file);
  });
}

export default readFileAsDataURL;
