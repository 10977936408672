import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';

const Link = React.forwardRef((props, ref) => {
  const { children, to, href, ...attributes } = props;

  if (to) {
    return (
      <ReactRouterLink ref={ref} to={to} {...attributes}>
        {children}
      </ReactRouterLink>
    );
  }
  return (
    <a ref={ref} href={href || '#'} {...attributes}>
      {children}
    </a>
  );
});

Link.displayName = 'LinkWithRef';

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
};

Link.defaultProps = {
  to: null,
  href: null,
};

export default Link;
