import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MobileVerified as PhoneVerified } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';

const VerifiedMobile = () => {
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.MobileVerified.s3',
    'You have verified your mobile number - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleBtnClick = () => {
    history.push('/sme-reg/create-password');
  };

  return (
    <PhoneVerified
      heading={copyProvider.getCopy(
        'SMEReg.MobileVerified.s1',
        'You have verified your mobile number'
      )}
      button={{
        onClick: handleBtnClick,
        text: copyProvider.getCopy('SMEReg.MobileVerified.s2', 'Continue'),
      }}
    />
  );
};

export default VerifiedMobile;
