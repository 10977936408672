/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // initialise variables
  pmcLandlordType: '',
  pmcRegistrationNumber: '',
  pmcData: null,
  pmcLandlordsData: [],
  selectedLandlord: '',
};

export const pmcJourneySlice = createSlice({
  name: 'pmcJourney',
  initialState,
  reducers: {
    resetPmcJourney: () => initialState,
    setPmcLandlordType: (state, action) => {
      state.pmcLandlordType = action.payload;
    },
    setPMCRegistrationNumber: (state, action) => {
      state.pmcRegistrationNumber = action.payload;
    },
    setPMCData: (state, action) => {
      state.pmcData = action.payload;
    },
    setPMCLandlordsData: (state, action) => {
      state.pmcLandlordsData = action.payload;
    },
    setSelectedLandlord: (state, action) => {
      state.selectedLandlord = action.payload;
    },
  },
});

export const {
  setPMCRegistrationNumber,
  setPMCData,
  setPMCLandlordsData,
  setSelectedLandlord,
  setPmcLandlordType,
  resetPmcJourney,
} = pmcJourneySlice.actions;
export const getPmcLandlordType = (state) => state.pmcJourney.pmcLandlordType;

export const getPMCRegistrationNumber = (state) =>
  state.pmcJourney.pmcRegistrationNumber;

export const getPMCData = (state) => state.pmcJourney.pmcData;
export const getPMCLandlordsData = (state) => state.pmcJourney.pmcLandlordsData;
export const getSelectedLandlord = (state) => state.pmcJourney.selectedLandlord;

export default pmcJourneySlice.reducer;
