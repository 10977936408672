import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IncorrectVerificationCode } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';
import { setEmailCodeResent } from '../../features/registration/registrationJourneySlice';
import { resendCode } from '../../firebase';

const VerificationCodeError = () => {
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const dispatch = useDispatch();

  useTitle(
    'SMERag.VerificationCodeError.s5',
    'Incorrect verification code - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleBtnClick = () => {
    resendCode({ type: 'email' })
      .then(() => {
        dispatch(setEmailCodeResent(true));
        history.push('/sme-reg/check-email');
      })
      .catch(() => {
        // TODO: Show network error message
      });
  };

  const pageModel = (
    <IncorrectVerificationCode
      heading={copyProvider.getCopy(
        'SMEReg.VerificationCodeError.s1',
        'Incorrect confirmation code'
      )}
      body={{
        line1: copyProvider.getCopy(
          'SMEReg.VerificationCodeError.s2',
          'The confirmation code you have entered is incorrect.'
        ),
        line2: copyProvider.getCopy(
          'SMEReg.VerificationCodeError.s3',
          'You will need to request a new code to continue. This code will be sent to the email address you have provided to us.'
        ),
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.VerificationCodeError.s4',
          'Request a new code'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
  return pageModel;
};

export default VerificationCodeError;
