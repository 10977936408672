import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'govuk-react-jsx';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';

const PasswordUpdatedConfirmation = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const handleBtnClick = () => {
    window.location.assign('/signin');
  };

  return (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'PasswordUpdatedConfirmation.s1',
          'Password updated'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'PasswordUpdatedConfirmation.s2',
          'You have updated your password. You can now continue to sign in to your account.'
        )}
      </p>
      <Button className="govuk-button" onClick={handleBtnClick}>
        {copyProvider.getCopy(
          'PasswordUpdatedConfirmation.s3',
          'Sign in to your account'
        )}
      </Button>
    </>
  );
};

export default PasswordUpdatedConfirmation;
