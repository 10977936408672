import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConfirmationResetPassword } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { getAccountEmail } from '../../features/registration/registrationJourneySlice';
import useTitle from '../../hooks/useTitle';

const PasswordResetConfirmation = () => {
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const accountEmailRedux = useSelector(getAccountEmail);
  useTitle(
    'SMEReg.PasswordResetConfirmation.s6',
    'Check your email - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const handleBtnClick = () => {
    history.push('/signin');
  };

  return (
    <ConfirmationResetPassword
      heading={copyProvider.getCopy(
        'SMEReg.PasswordResetConfirmation.s1',
        'Check your email'
      )}
      body={{
        line1: copyProvider.getCopy(
          'SMEReg.PasswordResetConfirmation.s2',
          'We’ve sent a message to '
        ),
        line2: copyProvider.getCopy(
          'SMEReg.PasswordResetConfirmation.s3',
          'with a link to reset your password.'
        ),
        line3: copyProvider.getCopy(
          'SMEReg.PasswordResetConfirmation.s4',
          'If you don’t see an email from us within a few minutes, please check your spam folder and try again.'
        ),
        accountEmail: accountEmailRedux,
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.PasswordResetConfirmation.s5',
          'Sign in to your account'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default PasswordResetConfirmation;
