import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SummaryList, Button, ErrorSummary } from 'govuk-react-jsx';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../../features/app/appSlice';
import { deleteEvidence, uploadResubmitEvidence } from '../../../firebase';
import {
  getResubmitClaims,
  setCarparkEvidence,
  setChargepointEvidence,
  setDiagramEvidence,
  setInvoiceEvidence,
  setResubmitClaim,
} from '../../../features/resubmit/resubmitJourneySlice';
import FileManager from '../../common/FileManager';
import { getClaimsData } from '../../../features/customer/customerJourneySlice';
import convertDateToDisplay from '../../../utils/convertDateToDisplay';
import { removeAllItem } from '../../../utils/array-lib';
import useTitle from '../../../hooks/useTitle';
import useGaStartEvent from '../../../hooks/useGaStartEvent';

const ResubmitClaim = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const claimsValue = useSelector(getClaimsData);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const resubmitClaimsData = useSelector(getResubmitClaims);
  const [hasUploadErrorForChargepoint, setHasUploadErrorForChargepoint] =
    useState({});
  const [hasUploadErrorForCarpark, setHasUploadErrorForCarpark] = useState({});
  const [hasUploadErrorForDiagrams, setHasUploadErrorForDiagrams] = useState(
    {}
  );
  const [errorUploadParent, setUploadErrorParent] = useState(null);
  const [errorInvoice, setErrorInvoice] = useState(null);
  const [errorCarparkPhotos, setErrorCarparkPhotos] = useState(null);
  const [errorDiagrams, setErrorDiagrams] = useState(null);
  const [errorFileList, setErrorFileList] = useState([]);
  const [errorInvoiceList, setErrorInvoiceList] = useState([]);
  const [errorCarparkPhotoList, setErrorCarparkPhotoList] = useState([]);
  const [errorDiagramList, setErrorDiagramList] = useState([]);
  const claimData = claimsValue.filter((c) => c.document_id === id)[0];

  const isCarParksGrant =
    claimData.grant_type === 'Residential car park' ||
    claimData.grant_type === 'Commercial car park' ||
    claimData.grant_type === 'Staff and fleet car park';

  useTitle(
    'Installer.Resubmit.ResubmitClaim.s28',
    'Resubmit your claim - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  useGaStartEvent('ResubmitClaim', 'resubmit_claim_start');

  const resubmitClaim = resubmitClaimsData.filter(
    (claim) => claim.documentId === claimData.document_id
  )[0];

  const PHOTO_FILE_SIZE = 5 * 1000 * 1000;
  const DOC_FILE_SIZE = 2 * 1000 * 1000;
  const DOCTYPE_CHARGEPOINT = 'chargepoints';
  const DOCTYPE_CAR_PARK = 'carpark_photos';
  const DOCTYPE_DIAGRAM = 'diagrams';
  const DOCTYPE_INVOICE = 'invoice';
  const FAR_UPLOAD_FILE_LIMIT = 2;
  const CHARGEPOINT_UPLOAD_FILE_LIMIT = 20;
  const CARPARK_UPLOAD_FILE_LIMIT = 10;
  const DIAGRAM_UPLOAD_FILE_LIMIT = 10;

  const collectionName = {
    'Renters and flat owners': 'customers',
    'Residential landlord': 'application_claim',
    'Commercial landlord': 'application_claim',
    'Residential car park': 'carpark_claim',
    'Commercial car park': 'carpark_claim',
    'Staff and fleet car park': 'carpark_claim',
  };

  const FLATS_AND_RENTERS = 'Renters and flat owners';

  const resubmissionDeadline = convertDateToDisplay(
    claimData.claim_submission_deadline_date
  );
  const initialSubmissionDate = convertDateToDisplay(
    claimData.claim_submitted_date
  );
  const reviewerComments = claimData?.cw_response_for_installer?.split('. ');

  const validFileTypes = [
    'image/png',
    'image/gif',
    'image/bmp',
    'image/jpeg',
    'image/jpg',
  ];

  const validDocTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'application/vnd.ms-excel',
    'application/msword',
    'application/pdf',
  ];

  const chargepointHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s12',
    'Your photos must be a JPG, JPEG, PNG, GIF or BMP file and each one can be up to 5MB in size.'
  );

  const displayHintsForChargepoints = () => {
    return <p key={1}>{chargepointHint}</p>;
  };

  const carparkHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s12',
    'Your photos must be a JPG, JPEG, PNG, GIF, PDF or BMP file and each one can be up to 5MB in size.'
  );

  const diagramHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s12',
    'Your photos must be a JPG, JPEG, PNG, GIF, PDF or BMP file and each one can be up to 5MB in size.'
  );

  const displayHintsForCarparks = () => {
    return <p key={1}>{carparkHint}</p>;
  };

  const displayHintsForDiagram = () => {
    return <p key={1}>{diagramHint}</p>;
  };

  const firstInvoiceHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s13',
    'You can only upload one file. If you sent your customer more than one invoice, add a final invoice that includes your total charges.'
  );

  const secondInvoiceHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s14',
    'The document needs to be in JPG, PNG, GIF, BMP, XLS, DOC or PDF formats.'
  );

  const headingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s1',
    'Resubmit your claim'
  );
  const firstParTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s2',
    'The claim you have submitted has been rejected.'
  );
  const linkTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s3',
    'View what you have submitted before.'
  );
  const pTxtFirstPart = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s4',
    'You have until '
  );
  const pTxtSecondPart = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s5',
    ' to submit evidence for approval. After this date we will decline your claim.'
  );
  const firstSubheadingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s10',
    'What was wrong and how to fix it'
  );

  const btnTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s11',
    'Continue'
  );

  const grantType = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s6',
        'Grant type'
      ),
    },
    value: {
      children: claimData.grant_type_long_description,
    },
  };

  const refNr = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s7',
        'Reference number'
      ),
    },
    value: {
      children: claimData.customerReferenceNumber,
    },
  };

  const customerName = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s8',
        'Customer name'
      ),
    },
    value: {
      children: `${claimData.firstName} ${claimData.lastName}`,
    },
  };

  const claimDateSubmitted = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s9',
        'Date you submitted this claim'
      ),
    },
    value: {
      children: initialSubmissionDate,
    },
  };

  const invalidInvoiceFileFormatError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s15',
      'The invoice must be in a JPG, PNG, GIF, BMP, XLS, DOC or PDF format'
    ),
  };

  const invalidChargepointFormatError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s16',
      'Photos must be JPG, JPEG, PNG, GIF or BMP files'
    ),
  };

  const invalidCarparkFormatError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s16',
      'You can only upload up to 10 car park and property photos'
    ),
  };

  const invalidDiagramFormatError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s29',
      'Your diagrams must be in JPG, PNG, GIF, BMP or PDF format'
    ),
  };

  const invalidChargepointSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s17',
      'All the photos must be smaller than 5MB'
    ),
  };

  const invalidCarparkSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s17',
      'All the photos must be smaller than 5MB'
    ),
  };

  const invalidDiagramSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s30',
      'Your diagram file must be less than 5MB'
    ),
  };

  const invalidInvoiceSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s18',
      'The invoice file must be below 2MB'
    ),
  };

  const invalidInvoiceFormatAnsSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s19',
      'The invoice must be below 2MB and be in a JPG, PNG, GIF, BMP, XLS, DOC or PDF format'
    ),
  };

  const invalidChargepointFormatAnsSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s20',
      'Photos must be JPG, JPEG, PNG, GIF or BMP files and smaller than 5MB'
    ),
  };

  const invalidCarparkFormatAnsSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s20',
      'Photos must be JPG, JPEG, PNG, GIF or BMP files and smaller than 5MB'
    ),
  };

  const invalidDiagramFormatAnsSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s31',
      'Your diagram must be less than 5MB and in a JPG, PNG, GIF or BMP format'
    ),
  };

  const invalidFARMoreFilesError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s21',
      'You can only upload up to 2 chargepoint photos'
    ),
  };

  const invalidOtherMoreFilesError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s22',
      'You can only upload up to 20 chargepoint photos'
    ),
  };

  const invalidCarparkFilesError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s35',
      'You can only upload up to 10 car park and property photos'
    ),
  };

  const invalidDiagramFilesError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s32',
      'You can only upload up to 10 diagram files'
    ),
  };

  const handleContinueClick = () => {
    let errorMsgMoreFilesUploadedForChargepoint = false;
    let errorMsgMoreFilesUploadedForCarpark = false;
    let errorMsgMoreFilesUploadedForDiagrams = false;

    if (
      (claimData.grant_type === FLATS_AND_RENTERS &&
        resubmitClaim?.chargepointEvidence.length > FAR_UPLOAD_FILE_LIMIT) ||
      (claimData.grant_type !== FLATS_AND_RENTERS &&
        resubmitClaim.chargepointEvidence &&
        resubmitClaim.chargepointEvidence.length >
          CHARGEPOINT_UPLOAD_FILE_LIMIT)
    ) {
      errorMsgMoreFilesUploadedForChargepoint = true;
    }

    if (
      claimData.grant_type !== FLATS_AND_RENTERS &&
      resubmitClaim.carparkEvidence &&
      resubmitClaim.carparkEvidence.length > CARPARK_UPLOAD_FILE_LIMIT
    ) {
      errorMsgMoreFilesUploadedForCarpark = true;
    }

    if (
      claimData.grant_type !== FLATS_AND_RENTERS &&
      resubmitClaim.diagramEvidence &&
      resubmitClaim.diagramEvidence.length > DIAGRAM_UPLOAD_FILE_LIMIT
    ) {
      errorMsgMoreFilesUploadedForDiagrams = true;
    }

    if (errorMsgMoreFilesUploadedForChargepoint) {
      setHasUploadErrorForChargepoint({
        errorMsgMoreFilesUploadedForChargepoint,
      });
    } else if (errorMsgMoreFilesUploadedForCarpark) {
      setHasUploadErrorForCarpark({
        errorMsgMoreFilesUploadedForCarpark,
      });
    } else if (errorMsgMoreFilesUploadedForDiagrams) {
      setHasUploadErrorForDiagrams({
        errorMsgMoreFilesUploadedForDiagrams,
      });
    } else {
      setHasUploadErrorForChargepoint(null);
      setHasUploadErrorForCarpark(null);
      setHasUploadErrorForDiagrams(null);
      history.push(`/resubmit-journey/check-answers-resubmit/${id}`);
    }
  };

  const handleLinkClick = (claimDataParam) => {
    switch (claimDataParam.grant_type) {
      case 'Renters and flat owners':
        history.push(
          `/resubmit-journey/review-claim-EVHS/${claimData.document_id}`
        );
        break;
      case 'Residential landlord':
        history.push(
          `/resubmit-journey/review-claim-residential-landlords/${claimData.document_id}`
        );
        break;
      case 'Commercial landlord':
        history.push(
          `/resubmit-journey/review-claim-commercial-landlords/${claimData.document_id}`
        );
        break;
      case 'Car Parks':
      case 'Staff and fleet car park':
      case 'Residential car park':
      case 'Commercial car park':
        history.push(
          `/resubmit-journey/review-claim-carparks/${claimData.document_id}`
        );
        break;
      default:
        break;
    }
  };

  const displayHintsInvoice = () => {
    return (
      <>
        <p key={1} className="govuk-hint">
          {firstInvoiceHint}
        </p>
        <p key={2} className="govuk-hint">
          {secondInvoiceHint}
        </p>
      </>
    );
  };

  const extractFileInfo = (fileData, filePath) =>
    fileData.map((file) => {
      return {
        fileName: file.fileName,
        filePath: `${filePath}/${file.fileName}`,
      };
    });

  const updateChargepointEvidence = async (results, service) => {
    const response = await uploadResubmitEvidence({
      uploadFiles: results,
      id,
      uploadType: DOCTYPE_CHARGEPOINT,
      updateCollectionName: collectionName[service],
    });

    const filesInfo = extractFileInfo(results, response.data.resubmitPath);
    const chargepointEvidence = [
      ...(resubmitClaim.chargepointEvidence || []),
      ...filesInfo,
    ];

    dispatch(
      setChargepointEvidence({
        documentId: claimData.document_id,
        chargepointEvidence,
      })
    );
  };

  const updateCarparkEvidence = async (results, service) => {
    const response = await uploadResubmitEvidence({
      uploadFiles: results,
      id,
      uploadType: DOCTYPE_CAR_PARK,
      updateCollectionName: collectionName[service],
    });

    const filesInfo = extractFileInfo(results, response.data.resubmitPath);
    const carparkEvidence = [
      ...(resubmitClaim?.carparkEvidence || []),
      ...filesInfo,
    ];

    dispatch(
      setCarparkEvidence({
        documentId: claimData.document_id,
        carparkEvidence,
      })
    );
  };

  const updateDiagramEvidence = async (results, service) => {
    const response = await uploadResubmitEvidence({
      uploadFiles: results,
      id,
      uploadType: DOCTYPE_DIAGRAM,
      updateCollectionName: collectionName[service],
    });

    const filesInfo = extractFileInfo(results, response.data.resubmitPath);
    const diagramEvidence = [
      ...(resubmitClaim?.diagramEvidence || []),
      ...filesInfo,
    ];

    dispatch(
      setDiagramEvidence({
        documentId: claimData.document_id,
        diagramEvidence,
      })
    );
  };

  const deleteInvoiceEvidence = async (filePath, service) => {
    const filteredFiles = resubmitClaim.invoiceEvidence.filter((file) => {
      return file.filePath !== filePath;
    });

    if (filteredFiles.length <= 0) {
      document.getElementById('file-upload').value = '';
    }
    await deleteEvidence({
      id,
      filePath,
      updateCollectionName: collectionName[service],
      uploadType: DOCTYPE_INVOICE,
    });
  };

  const updateInvoiceEvidence = async (results, service) => {
    if (
      resubmitClaim &&
      resubmitClaim.invoiceEvidence &&
      resubmitClaim.invoiceEvidence.length > 0
    ) {
      await deleteInvoiceEvidence(
        resubmitClaim.invoiceEvidence[0].filePath,
        claimData.grant_type
      );
    }

    const response = await uploadResubmitEvidence({
      uploadFiles: results,
      id,
      uploadType: DOCTYPE_INVOICE,
      updateCollectionName: collectionName[service],
    });
    const filesInfo = extractFileInfo(results, response.data.resubmitPath);

    dispatch(
      setInvoiceEvidence({
        documentId: claimData.document_id,
        invoiceEvidence: filesInfo,
      })
    );
  };

  const handleUpload = async (results, documentType, service) => {
    switch (documentType) {
      case DOCTYPE_CHARGEPOINT:
        updateChargepointEvidence(results, service);
        break;
      case DOCTYPE_CAR_PARK:
        updateCarparkEvidence(results, service);
        break;
      case DOCTYPE_DIAGRAM:
        updateDiagramEvidence(results, service);
        break;
      case DOCTYPE_INVOICE:
        updateInvoiceEvidence(results, service);
        break;
      default:
        break;
    }
  };

  const deleteChargepointEvidence = async (filePath, service) => {
    const filteredFiles = resubmitClaim.chargepointEvidence.filter((file) => {
      return file.filePath !== filePath;
    });

    if (filteredFiles.length <= 0) {
      document.getElementById('file-upload').value = '';
    }
    await deleteEvidence({
      id,
      filePath,
      updateCollectionName: collectionName[service],
      uploadType: DOCTYPE_CHARGEPOINT,
    });

    dispatch(
      setChargepointEvidence({
        documentId: claimData.document_id,
        chargepointEvidence: filteredFiles,
      })
    );

    if (filteredFiles.length <= CHARGEPOINT_UPLOAD_FILE_LIMIT) {
      setHasUploadErrorForChargepoint({
        errorMsgMoreFilesUploadedForChargepoint: false,
      });
    }
  };

  const deleteCarparkEvidence = async (filePath, service) => {
    const filteredFiles = resubmitClaim.carparkEvidence.filter((file) => {
      return file.filePath !== filePath;
    });

    if (filteredFiles.length <= 0) {
      document.getElementById('file-upload').value = '';
    }
    await deleteEvidence({
      id,
      filePath,
      updateCollectionName: collectionName[service],
      uploadType: DOCTYPE_CAR_PARK,
    });

    dispatch(
      setCarparkEvidence({
        documentId: claimData.document_id,
        carparkEvidence: filteredFiles,
      })
    );

    if (filteredFiles.length <= CHARGEPOINT_UPLOAD_FILE_LIMIT) {
      setHasUploadErrorForChargepoint({
        errorMsgMoreFilesUploadedForCarpark: false,
      });
    }
  };

  const deleteDiagramEvidence = async (filePath, service) => {
    const filteredFiles = resubmitClaim.diagramEvidence.filter((file) => {
      return file.filePath !== filePath;
    });

    if (filteredFiles.length <= 0) {
      document.getElementById('file-upload').value = '';
    }
    await deleteEvidence({
      id,
      filePath,
      updateCollectionName: collectionName[service],
      uploadType: DOCTYPE_DIAGRAM,
    });

    dispatch(
      setDiagramEvidence({
        documentId: claimData.document_id,
        diagramEvidence: filteredFiles,
      })
    );

    if (filteredFiles.length <= DIAGRAM_UPLOAD_FILE_LIMIT) {
      setHasUploadErrorForChargepoint({
        errorMsgMoreFilesUploadedForDiagrams: false,
      });
    }
  };

  const handleDelete = async (event, _index, filePath, docType) => {
    event.preventDefault();
    switch (docType) {
      case DOCTYPE_CHARGEPOINT:
        await deleteChargepointEvidence(filePath, claimData.grant_type);
        break;
      case DOCTYPE_CAR_PARK:
        await deleteCarparkEvidence(filePath, claimData.grant_type);
        break;
      case DOCTYPE_DIAGRAM:
        await deleteDiagramEvidence(filePath, claimData.grant_type);
        break;
      case DOCTYPE_INVOICE:
        await deleteInvoiceEvidence(filePath, claimData.grant_type);
        break;
      default:
        break;
    }
  };

  const checkIfAnyFilesUploaded = () => {
    if (resubmitClaim) {
      return (
        (((resubmitClaim.chargepointEvidence &&
          resubmitClaim.chargepointEvidence?.length <= 0) ||
          !resubmitClaim.chargepointEvidence) &&
          ((resubmitClaim.invoiceEvidence &&
            resubmitClaim.invoiceEvidence?.length <= 0) ||
            !resubmitClaim.invoiceEvidence) &&
          ((resubmitClaim.carparkEvidence &&
            resubmitClaim.carparkEvidence?.length <= 0) ||
            !resubmitClaim.carparkEvidence) &&
          ((resubmitClaim.diagramEvidence &&
            resubmitClaim.diagramEvidence?.length <= 0) ||
            !resubmitClaim.diagramEvidence)) ||
        (errorCarparkPhotoList.length > 0 &&
          errorFileList.length > 0 &&
          errorDiagramList.length > 0 &&
          errorInvoiceList.length > 0)
      );
    }
    return false;
  };

  useEffect(() => {
    const errorListFileArr = [];

    if (errorUploadParent !== null && errorUploadParent !== undefined) {
      const errMessages = [
        invalidChargepointFormatError.children,
        invalidChargepointSizeError.children,
        invalidFARMoreFilesError.children,
        invalidOtherMoreFilesError.children,
        invalidChargepointFormatAnsSizeError.children,
      ];
      removeAllItem(errorFileList, errMessages);
      if (errorUploadParent !== '') {
        setErrorFileList([
          ...errorFileList,
          {
            ...errorUploadParent,
            href: '#file-upload',
          },
        ]);
      }
    } else {
      setErrorFileList([...errorFileList, ...errorListFileArr]);
    }
  }, [errorUploadParent, hasUploadErrorForChargepoint]);

  useEffect(() => {
    const errorListInvoiceArr = [];

    if (errorInvoice !== null && errorInvoice !== undefined) {
      const errMessages = [
        invalidInvoiceFileFormatError.children,
        invalidInvoiceSizeError.children,
        invalidInvoiceFormatAnsSizeError.children,
      ];
      removeAllItem(errorInvoiceList, errMessages);
      if (errorInvoice !== '') {
        setErrorInvoiceList([
          ...errorInvoiceList,
          {
            ...errorInvoice,
            href: '#invoice-upload',
          },
        ]);
      }
    } else {
      setErrorInvoiceList([...errorInvoiceList, ...errorListInvoiceArr]);
    }
  }, [errorInvoice]);

  useEffect(() => {
    const errorListCarparkArr = [];

    if (errorCarparkPhotos !== null && errorCarparkPhotos !== undefined) {
      const errMessages = [
        invalidCarparkFormatError.children,
        invalidCarparkSizeError.children,
        invalidCarparkFormatAnsSizeError.children,
        invalidCarparkFilesError.children,
      ];
      removeAllItem(errorCarparkPhotoList, errMessages);
      if (errorCarparkPhotos !== '') {
        setErrorCarparkPhotoList([
          ...errorCarparkPhotoList,
          {
            ...errorCarparkPhotos,
            href: '#car-park-upload',
          },
        ]);
      }
    } else {
      setErrorCarparkPhotoList([
        ...errorCarparkPhotoList,
        ...errorListCarparkArr,
      ]);
    }
  }, [errorCarparkPhotos, hasUploadErrorForCarpark]);

  useEffect(() => {
    const errorListDiagramArr = [];

    if (errorDiagrams !== null && errorDiagrams !== undefined) {
      const errMessages = [
        invalidDiagramFormatError.children,
        invalidDiagramSizeError.children,
        invalidDiagramFormatAnsSizeError.children,
        invalidDiagramFilesError.children,
      ];
      removeAllItem(errorDiagramList, errMessages);
      if (errorDiagrams !== '') {
        setErrorDiagramList([
          ...errorDiagramList,
          {
            ...errorDiagrams,
            href: '#diagram-upload',
          },
        ]);
      }
    } else {
      setErrorDiagramList([...errorDiagramList, ...errorListDiagramArr]);
    }
  }, [errorDiagrams, hasUploadErrorForDiagrams]);

  useEffect(() => {
    if (
      !resubmitClaimsData.some((r) => r.documentId === claimData.document_id)
    ) {
      dispatch(
        setResubmitClaim([
          ...resubmitClaimsData,
          {
            documentId: claimData.document_id,
            chargepointEvidence: [],
            invoiceEvidence: [],
          },
        ])
      );
    }
  }, []);

  return (
    <>
      {(errorFileList?.length > 0 ||
        errorCarparkPhotoList?.length > 0 ||
        errorDiagramList?.length > 0 ||
        errorInvoiceList?.length > 0) && (
        <ErrorSummary
          errorList={[
            ...errorFileList,
            ...errorCarparkPhotoList,
            ...errorDiagramList,
            ...errorInvoiceList,
          ]}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">{headingTxt}</h1>
      <p className="govuk-body">
        {firstParTxt}
        <br />
        <Link to={{}} onClick={() => handleLinkClick(claimData)}>
          {linkTxt}
        </Link>
      </p>
      <p className="govuk-body">
        {`${pTxtFirstPart} ${resubmissionDeadline} ${pTxtSecondPart}`}
      </p>

      <SummaryList
        rows={[grantType, refNr, customerName, claimDateSubmitted]}
      />
      <h2 className="govuk-heading-m govuk-!-margin-top-8">
        {firstSubheadingTxt}
      </h2>
      <ul className="govuk-list govuk-list--bullet">
        {reviewerComments &&
          reviewerComments.map((comment) => {
            return <li key={comment}>{comment}</li>;
          })}
      </ul>

      <h2 className="govuk-heading-m govuk-!-margin-top-9">
        {copyProvider.getCopy(
          'Installer.Resubmit.ResubmitClaim.s23',
          'Upload your new evidence'
        )}
      </h2>
      <label htmlFor="photo-upload">
        <h3 className="govuk-heading-s">
          {copyProvider.getCopy(
            'Installer.Resubmit.ResubmitClaim.s26',
            'Upload chargepoint photos'
          )}
        </h3>
      </label>
      <div className="govuk-form-group govuk-!-margin-top-7">
        <FileManager
          errorMessage={{
            invalidFileFormatError: invalidChargepointFormatError,
            invalidSizeError: invalidChargepointSizeError,
            invalidMoreFilesError:
              claimData.grant_type === FLATS_AND_RENTERS
                ? invalidFARMoreFilesError
                : invalidOtherMoreFilesError,
            invalidSizeAndFormat: invalidChargepointFormatAnsSizeError,
          }}
          hint={[displayHintsForChargepoints()]}
          name="file-upload"
          id="photo-upload"
          validFileTypes={validFileTypes}
          fileSize={PHOTO_FILE_SIZE}
          accept=".jpg, .png, .gif, .bmp, .jpeg"
          hasMoreFilesUploadError={
            (hasUploadErrorForChargepoint &&
              hasUploadErrorForChargepoint.errorMsgMoreFilesUploadedForChargepoint) ||
            false
          }
          handleUpload={(result) =>
            handleUpload(result, DOCTYPE_CHARGEPOINT, claimData.grant_type)
          }
          handleDelete={(event, index, filePath) =>
            handleDelete(event, index, filePath, DOCTYPE_CHARGEPOINT)
          }
          evidence={resubmitClaim?.chargepointEvidence || []}
          setUploadErrorParent={setUploadErrorParent}
        />
      </div>
      {isCarParksGrant && (
        <>
          <label htmlFor="car-park-upload">
            <h3 className="govuk-heading-s">
              {copyProvider.getCopy(
                'Installer.Resubmit.ResubmitClaim.s33',
                'Upload car park and property photos'
              )}
            </h3>
          </label>
          <div className="govuk-form-group govuk-!-margin-top-7">
            <FileManager
              errorMessage={{
                invalidFileFormatError: invalidCarparkFormatError,
                invalidSizeError: invalidCarparkSizeError,
                invalidMoreFilesError: invalidCarparkFilesError,
                invalidSizeAndFormat: invalidCarparkFormatAnsSizeError,
              }}
              hint={[displayHintsForCarparks()]}
              name="car-park-upload"
              id="car-park-upload"
              validFileTypes={validFileTypes}
              fileSize={PHOTO_FILE_SIZE}
              accept=".jpg, .png, .gif, .bmp, .jpeg"
              hasMoreFilesUploadError={
                (hasUploadErrorForCarpark &&
                  hasUploadErrorForCarpark.errorMsgMoreFilesUploadedForCarpark) ||
                false
              }
              handleUpload={(result) =>
                handleUpload(result, DOCTYPE_CAR_PARK, claimData.grant_type)
              }
              handleDelete={(event, index, filePath) =>
                handleDelete(event, index, filePath, DOCTYPE_CAR_PARK)
              }
              evidence={resubmitClaim?.carparkEvidence || []}
              setUploadErrorParent={setErrorCarparkPhotos}
            />
          </div>
          <label htmlFor="diagram-upload">
            <h3 className="govuk-heading-s">
              {copyProvider.getCopy(
                'Installer.Resubmit.ResubmitClaim.s34',
                'Upload diagrams'
              )}
            </h3>
          </label>
          <div className="govuk-form-group govuk-!-margin-top-7">
            <FileManager
              errorMessage={{
                invalidFileFormatError: invalidDiagramFormatError,
                invalidSizeError: invalidDiagramSizeError,
                invalidMoreFilesError: invalidDiagramFilesError,
                invalidSizeAndFormat: invalidDiagramFormatAnsSizeError,
              }}
              hint={[displayHintsForDiagram()]}
              name="diagram-upload"
              id="diagram-upload"
              validFileTypes={validFileTypes}
              fileSize={PHOTO_FILE_SIZE}
              accept=".jpg, .png, .gif, .bmp, .jpeg"
              hasMoreFilesUploadError={
                (hasUploadErrorForDiagrams &&
                  hasUploadErrorForDiagrams.errorMsgMoreFilesUploadedForDiagrams) ||
                false
              }
              handleUpload={(result) =>
                handleUpload(result, DOCTYPE_DIAGRAM, claimData.grant_type)
              }
              handleDelete={(event, index, filePath) =>
                handleDelete(event, index, filePath, DOCTYPE_DIAGRAM)
              }
              evidence={resubmitClaim?.diagramEvidence || []}
              setUploadErrorParent={setErrorDiagrams}
            />
          </div>
        </>
      )}

      <label htmlFor="invoice-upload">
        <h3 className="govuk-heading-s">
          {copyProvider.getCopy(
            'Installer.Resubmit.ResubmitClaim.s27',
            'Upload invoice'
          )}
        </h3>
      </label>
      <div className="govuk-form-group govuk-!-margin-top-7">
        {displayHintsInvoice()}
        <FileManager
          errorMessage={{
            invalidFileFormatError: invalidInvoiceFileFormatError,
            invalidSizeError: invalidInvoiceSizeError,
            invalidSizeAndFormat: invalidInvoiceFormatAnsSizeError,
          }}
          label={{
            children: copyProvider.getCopy(
              'Installer.Resubmit.ResubmitClaim.s27',
              'Upload invoice'
            ),
          }}
          name="invoice-upload"
          id="invoice-upload"
          validFileTypes={validDocTypes}
          fileSize={DOC_FILE_SIZE}
          accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .xls, .doc"
          handleUpload={(result) =>
            handleUpload(result, DOCTYPE_INVOICE, claimData.grant_type)
          }
          handleDelete={(event, index, filePath) =>
            handleDelete(event, index, filePath, DOCTYPE_INVOICE)
          }
          evidence={resubmitClaim?.invoiceEvidence || []}
          isInvoice
          setUploadErrorParent={setErrorInvoice}
        />
      </div>
      <Button
        disabled={checkIfAnyFilesUploaded()}
        onClick={handleContinueClick}
      >
        {btnTxt}
      </Button>
    </>
  );
};

export default ResubmitClaim;
