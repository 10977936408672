import { configureStore } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import appReducer from '../features/app/appSlice';
import customerReducer from '../features/customer/customerJourneySlice';
import installerReducer from '../features/installer/installerJourneySlice';
import registrationReducer from '../features/registration/registrationJourneySlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import pmcJourneyReducer from '../features/pmc/pmcJourneySlice';
import applicationReducer from '../features/application/applicationJourneySlice';
import resubmitReducer from '../features/resubmit/resubmitJourneySlice';
import gaReducer from '../features/ga/gaSlice';

export const history = createBrowserHistory();

const reducers = combineReducers({
  app: appReducer,
  customer: customerReducer,
  installer: installerReducer,
  registrationJourney: registrationReducer,
  dashboardSlice: dashboardReducer,
  pmcJourneySlice: pmcJourneyReducer,
  applicationJourney: applicationReducer,
  resubmit: resubmitReducer,
  gaSlice: gaReducer,
  router: connectRouter(history),
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  whitelist: [
    'customer',
    'installer',
    'installerJourney',
    'registrationJourney',
    'applicationJourney',
    'dashboardSlice',
    'pmcJourneySlice',
    'resubmit',
    'connectRouter',
    'gaSlice',
  ],
  blacklist: ['app'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'app/getCopyProviderCY',
          'app/getCpyProviderEN',
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
        ignoredPaths: [
          'app.copyProviderCY',
          'app.copyProviderEN',
          'installer.dateOfGrantApplication',
        ],
      },
    }).concat(routerMiddleware(history)),
});
