import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CannotProceed } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';

const CannotProceedSME = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const history = useHistory();
  useTitle(
    'SMEReg.CannotProceed.s8',
    'You cannot apply without a company registration or VAT number - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const handleBtnClick = () => {
    history.push('/signin');
  };

  const handleLink1 = () => {
    history.push('/sme-reg/company-identification');
  };

  const handleLink2 = () => {
    history.push('/sme-reg/company-identification-vat');
  };

  return (
    <CannotProceed
      heading={copyProvider.getCopy(
        'SMEReg.CannotProceed.s1',
        'You cannot proceed'
      )}
      body={{
        paragraphOne: copyProvider.getCopy(
          'SMEReg.CannotProceed.s2',
          'To create an account and apply for this grant you must be able to prove you are a UK registered business.'
        ),
        paragraphTwo: copyProvider.getCopy(
          'SMEReg.CannotProceed.s3',
          'You can identify your business by providing either:'
        ),
      }}
      luElement={{
        liElement1: {
          link: {
            text: copyProvider.getCopy(
              'SMEReg.CannotProceed.s4',
              'Company registration number'
            ),
            to: '/sme-reg/company-identification',
            onClick: handleLink1,
          },
          text: copyProvider.getCopy('SMEReg.CannotProceed.s5', '; or'),
        },
        liElement2: {
          link: {
            text: copyProvider.getCopy(
              'SMEReg.CannotProceed.s6',
              'UK VAT registration number'
            ),
            to: '/sme-reg/company-identification-vat',
            onClick: handleLink2,
          },
        },
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.CannotProceed.s7',
          'Cancel my account registration'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default CannotProceedSME;
