import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { ConfirmEmailAddress } from '@pepper-ui/common';
import {
  getAccountEmail,
  getVerificationCode,
  setVerificationCode,
  getEmailCodeResent,
  setEmailCodeResent,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';
import { checkEmail, resendCode } from '../../firebase';

const CheckEmail = () => {
  const sendDataToGTM = useGTMDispatch();
  const [success, setSuccess] = useState(false);

  const verificationCodeRedux = useSelector(getVerificationCode);
  const accountEmailRedux = useSelector(getAccountEmail);

  // emailCodeResentRedux is true if user clicks on 'Request a new code' button on IncorrectVerificationCode screen
  const emailCodeResentRedux = useSelector(getEmailCodeResent);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.CheckEmail.s14',
    'Enter the code to confirm your email address - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const errorMessage = copyProvider.getCopy(
    'SMEReg.CheckEmail.s12',
    'Enter a valid email verification code'
  );

  const updatedVerificationCode = (event) => {
    dispatch(setVerificationCode(event.target.value));
  };

  const handleBtnClick = () => {
    sendDataToGTM({
      event: 'reg_check_email',
      category: 'Registration',
    });
    checkEmail({ emailOtp: verificationCodeRedux })
      .then(() => {
        history.push('/sme-reg/email-verified');
      })
      .catch(() => {
        // checkEmail does not return a value to check success, it just returns in catch()
        history.push('/sme-reg/email-code-error');
      });
    dispatch(setVerificationCode(''));
  };

  const resendEmail = () => {
    //  reset flag from IncorrectVerificationCode
    dispatch(setEmailCodeResent(false));
    setSuccess(false);

    resendCode({ type: 'email' })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        // TODO: Show network error message
        setSuccess(false);
      });
  };

  const title = copyProvider.getCopy(
    'SMEReg.CheckEmail.s9',
    'Email code has been sent again'
  );

  const hint = copyProvider.getCopy(
    'SMEReg.CheckEmail.s5',
    'For example, DNCLRK'
  );

  const label = copyProvider.getCopy(
    'SMEReg.CheckEmail.s6',
    'Verification code'
  );

  const pageModel = (
    <ConfirmEmailAddress
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      banner={{
        resendEmailSuccess: success,
        content: copyProvider.getCopy(
          'SMEReg.CheckEmail.s10',
          'We have sent you a new verification code via email, this may take a few minutes to arrive.'
        ),
        title,
        emailResentCode: emailCodeResentRedux,
      }}
      heading={copyProvider.getCopy(
        'SMEReg.CheckEmail.s2',
        'Enter the code to confirm your email address'
      )}
      body={{
        verificationCode: {
          desc: copyProvider.getCopy(
            'SMEReg.CheckEmail.s3',
            'We have sent a code to: '
          ),
          accountEmail: accountEmailRedux,
          instructions: copyProvider.getCopy(
            'SMEReg.CheckEmail.s11',
            'It may take a few minutes to arrive. If this email does not arrive, check your junk or spam folder.'
          ),
          hint: copyProvider.getCopy(
            'SMEReg.CheckEmail.s4',
            'If you use a browser to access your email, you may need to open a new window or tab to see the code.'
          ),
        },
      }}
      confirmationCodeInput={{
        label,
        hint,
        onChange: (event) => updatedVerificationCode(event),
        value: verificationCodeRedux,
        errorMessage,
      }}
      button={{
        text: copyProvider.getCopy('SMEReg.CheckEmail.s8', 'Continue'),
        onClick: handleBtnClick,
      }}
      link={{
        text: copyProvider.getCopy(
          'SMEReg.CheckEmail.s7',
          'I have not received an email'
        ),
        onClick: () => resendEmail(),
        to: '/sme-reg/check-email',
      }}
    />
  );

  return pageModel;
};

export default CheckEmail;
