/**
 * Formats a date, returns a string with full month name (January, February, etc) followed by the year.
 * The year should be 4 numeric characters (2022. 2023 etc)
 * @param {String} date
 * @returns {String}
 */
export const formatDate = (date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date(date);
  return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
};

export const saveAnalyticsCookie = (userSelection) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  // expire after 1 years
  date.setTime(new Date(year + 1, month, day));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `evcgAnalytics=${userSelection};${expires};path=/`;
};

export const saveLanguageCookie = (lang) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  // expire after 1 years
  date.setTime(new Date(year + 1, month, day));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `lang=${lang};${expires};path=/`;
};
