/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  claimId: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  installerApplicationRefNumber: '',
  accessCode: '',
  propertyOwnership: '',
  grantClaimed: '',
  flatOwnership: '',
  isResidentialProperty: '',
  hasOffStreetParking: '',
  propertyType: '',
  manufacturerName: '',
  selectedModel: '',
  selectedManufacturer: '',
  manufacturerModels: [],
  vehicleInformation: '',
  installationAddress: {
    addressLine1: '',
    addressLine2: '',
    townOrCity: '',
    county: '',
    postCode: '',
  },
  vehicleOwnership: '',
  vehicleRegistrationNumber: '',
  customerReferenceNumber: '',
  chargePointManufacturers: [],
  chargePointModels: [],
  chargePointSerialNr: '',
  chargePointSocketNumber: 1,
  selectedCpManufacturer: '',
  selectedCpModel: '',
  installationDateDay: '',
  installationDateMonth: '',
  installationDateYear: '',
  installerType: '',
  subcontractorInstallerNumber: '',
  uid: '',
  installerDeclarationAccepted: '',
  subcontractorOZEVInstallerNumber: '',
  subcontractorName: '',
  installerOZEVNumber: '',
  installerName: '',
  photographs: [],
  dateOfCompletion: '',
  claimStatus: '',
  installationCost: '',
  invoice: [],
  claims: [],
  installerId: '',
  isFromCheckAnswers: false,
  isResidential: false,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomer: () => initialState,
    setCustomerId: (state, action) => {
      state.id = action.payload;
    },
    setClaimId: (state, action) => {
      state.claimId = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    setInstallerApplicationRefNumber: (state, action) => {
      state.installerApplicationRefNumber = action.payload;
    },
    setAccessCode: (state, action) => {
      state.accessCode = action.payload;
    },
    setGrantClaimed: (state, action) => {
      state.grantClaimed = action.payload;
    },
    setOwnershipType: (state, action) => {
      state.propertyOwnership = action.payload;
    },
    setHasOffStreetParking: (state, action) => {
      state.hasOffStreetParking = action.payload;
    },
    setIsResidentialProperty: (state, action) => {
      state.isResidentialProperty = action.payload;
    },
    setFlatOwnership: (state, action) => {
      state.flatOwnership = action.payload;
    },
    setPropertyType: (state, action) => {
      state.propertyType = action.payload;
    },
    setVehicleRegistrationNumber: (state, action) => {
      state.vehicleRegistrationNumber = action.payload;
    },
    setManufacturerName: (state, action) => {
      state.manufacturerName = action.payload;
    },
    setManufacturerModels: (state, action) => {
      state.manufacturerModels = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setSelectedManufacturer: (state, action) => {
      state.selectedManufacturer = action.payload;
    },
    setVehicleInformation: (state, action) => {
      state.vehicleInformation = action.payload;
    },
    setCustomerReferenceNumber: (state, action) => {
      state.customerReferenceNumber = action.payload;
    },
    setInstallationAddressLine1: (state, action) => {
      state.installationAddress.addressLine1 = action.payload;
    },
    setInstallationAddressLine2: (state, action) => {
      state.installationAddress.addressLine2 = action.payload;
    },
    setInstallationTownOrCity: (state, action) => {
      state.installationAddress.townOrCity = action.payload;
    },
    setInstallationCounty: (state, action) => {
      state.installationAddress.county = action.payload;
    },
    setInstallationPostCode: (state, action) => {
      state.installationAddress.postCode = action.payload;
    },
    setVehicleOwnership: (state, action) => {
      state.vehicleOwnership = action.payload;
    },
    setInstallerType: (state, action) => {
      state.installerType = action.payload;
    },
    setChargePointManufacturers: (state, action) => {
      state.chargePointManufacturers = action.payload;
    },
    setChargePointModels: (state, action) => {
      state.chargePointModels = action.payload;
    },
    setChargePointSerialNr: (state, action) => {
      state.chargePointSerialNr = action.payload;
    },
    setSelectedCpManufacturer: (state, action) => {
      state.selectedCpManufacturer = action.payload;
    },
    setSelectedCpModel: (state, action) => {
      state.selectedCpModel = action.payload;
    },
    setInstallationDateDay: (state, action) => {
      state.installationDateDay = action.payload;
    },
    setInstallationDateMonth: (state, action) => {
      state.installationDateMonth = action.payload;
    },
    setInstallationDateYear: (state, action) => {
      state.installationDateYear = action.payload;
    },
    setSubcontractorOZEVInstallerNumber: (state, action) => {
      state.subcontractorOZEVInstallerNumber = action.payload;
    },
    setInstallerOZEVNumber: (state, action) => {
      state.installerOZEVNumber = action.payload;
    },
    setInstallerName: (state, action) => {
      state.installerName = action.payload;
    },
    setSubcontractorName: (state, action) => {
      state.subcontractorName = action.payload;
    },
    setInstallerDeclarationAccepted: (state, action) => {
      state.installerDeclarationAccepted = action.payload;
    },
    setDateOfCompletion: (state, action) => {
      state.dateOfCompletion = action.payload;
    },
    setClaimStatus: (state, action) => {
      state.claimStatus = action.payload;
    },
    setPhotographs: (state, action) => {
      state.photographs = action.payload;
    },
    setInstallationCost: (state, action) => {
      state.installationCost = action.payload;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setChargePointSocketNumber: (state, action) => {
      state.chargePointSocketNumber = action.payload;
    },
    setInstallerId: (state, action) => {
      state.installerId = action.payload;
    },
    setClaimsData: (state, action) => {
      state.claims = action.payload;
    },
    setIsFromCheckAnswers: (state, action) => {
      state.isFromCheckAnswers = action.payload;
    },
    setIsResidential: (state, action) => {
      state.isResidential = action.payload;
    },
  },
});

export const {
  setCustomerId,
  setClaimId,
  setFirstName,
  setLastName,
  setEmail,
  setMobileNumber,
  setInstallerApplicationRefNumber,
  setAccessCode,
  setOwnershipType,
  setGrantClaimed,
  setFlatOwnership,
  setIsResidentialProperty,
  setHasOffStreetParking,
  setPropertyType,
  setVehicleRegistrationNumber,
  setManufacturerName,
  setManufacturerModels,
  setSelectedModel,
  setSelectedManufacturer,
  setVehicleInformation,
  setCustomerReferenceNumber,
  setInstallationAddressLine1,
  setInstallationAddressLine2,
  setInstallationTownOrCity,
  setInstallationCounty,
  setInstallationPostCode,
  setVehicleOwnership,
  setChargePointManufacturers,
  setChargePointModels,
  setChargePointSerialNr,
  setSelectedCpManufacturer,
  setSelectedCpModel,
  setInstallationDateDay,
  setInstallationDateMonth,
  setInstallationDateYear,
  setInstallerType,
  setInstallerDeclarationAccepted,
  setSubcontractorOZEVInstallerNumber,
  setSubcontractorName,
  setDateOfCompletion,
  setClaimStatus,
  setPhotographs,
  setInstallationCost,
  setInvoice,
  setCostOfInstallation,
  setTotalGrantAmount,
  setChargePointSocketNumber,
  setInstallerOZEVNumber,
  setInstallerName,
  installerId,
  setClaimsData,
  setIsFromCheckAnswers,
  setInstallerId,
  setIsResidential,
  resetCustomer,
} = customerSlice.actions;

export const getCustomerId = (state) => state.customer.id;
export const getClaimId = (state) => state.customer.claimId;
export const getFirstName = (state) => state.customer.firstName;
export const getLastName = (state) => state.customer.lastName;
export const getEmail = (state) => state.customer.email;
export const getMobileNumber = (state) => state.customer.mobileNumber;
export const getInstallerApplicationRefNumber = (state) =>
  state.customer.installerApplicationRefNumber;
export const getAccessCode = (state) => state.customer.accessCode;

export const getGrantClaimed = (state) => state.customer.grantClaimed;
export const getFlatOwnership = (state) => state.customer.flatOwnership;
export const getIsResidentialProperty = (state) =>
  state.customer.isResidentialProperty;
export const getOwnershipType = (state) => state.customer.propertyOwnership;
export const getHasOffStreetParking = (state) =>
  state.customer.hasOffStreetParking;
export const getPropertyType = (state) => state.customer.propertyType;
export const getVehicleRegistrationNumber = (state) =>
  state.customer.vehicleRegistrationNumber;
export const getManufacturerName = (state) => state.customer.manufacturerName;
export const getManufacturerModels = (state) =>
  state.customer.manufacturerModels;
export const getSelectedManufacturer = (state) =>
  state.customer.selectedManufacturer;
export const getSelectedModel = (state) => state.customer.selectedModel;
export const getVehicleInformation = (state) =>
  state.customer.vehicleInformation;
export const getCustomerReferenceNumber = (state) =>
  state.customer.customerReferenceNumber;
export const getInstallationAddressLine1 = (state) =>
  state.customer.installationAddress.addressLine1;
export const getInstallationAddressLine2 = (state) =>
  state.customer.installationAddress.addressLine2;
export const getTownOrCity = (state) =>
  state.customer.installationAddress.townOrCity;
export const getCounty = (state) => state.customer.installationAddress.county;
export const getPostCode = (state) =>
  state.customer.installationAddress.postCode;
export const getVehicleOwnership = (state) => state.customer.vehicleOwnership;
export const getInstallerType = (state) => state.customer.installerType;
export const getChargePointManufacturers = (state) =>
  state.customer.chargePointManufacturers;
export const getChargePointModels = (state) => state.customer.chargePointModels;
export const getChargePointSerialNr = (state) =>
  state.customer.chargePointSerialNr;
export const getSelectedCpManufacturer = (state) =>
  state.customer.selectedCpManufacturer;
export const getSelectedCpModel = (state) => state.customer.selectedCpModel;
export const getUID = (state) => state.customer.uid;
export const getInstallationDateDay = (state) =>
  state.customer.installationDateDay;
export const getInstallationDateMonth = (state) =>
  state.customer.installationDateMonth;
export const getInstallationDateYear = (state) =>
  state.customer.installationDateYear;
export const getInstallerDeclarationAccepted = (state) =>
  state.installer.installerDeclarationAccepted;
export const getSubcontractorName = (state) => state.customer.subcontractorName;
export const getSubcontractorOZEVInstallerNumber = (state) =>
  state.customer.subcontractorOZEVInstallerNumber;
export const getDateOfCompletion = (state) => state.customer.dateOfCompletion;
export const getClaimStatus = (state) => state.customer.claimStatus;
export const getPhotographs = (state) => state.customer.photographs;
export const getInstallationCost = (state) => state.customer.installationCost;
export const getInvoice = (state) => state.customer.invoice;
export const getChargePointSocketNumber = (state) =>
  state.customer.chargePointSocketNumber;
export const getInstallerOZEVNumber = (state) =>
  state.customer.installerOZEVNumber;
export const getInstallerName = (state) => state.customer.installerName;
export const getInstallerId = (state) => state.customer.installerId;
export const getIsFromCheckAnswers = (state) =>
  state.customer.isFromCheckAnswers;
export const getClaimsData = (state) => state.customer.claims;
export const getIsResidential = (state) => state.customer.isResidential;

export default customerSlice.reducer;
