import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Input, ErrorSummary } from 'govuk-react-jsx';
import { useHistory, Link } from 'react-router-dom';
import { getClaimsByInstallerId, getInstallerDetails } from '../../../firebase';
import { checkSearchTerm } from '../../../regex/regex';

import {
  setIsResidential,
  getClaimsData,
  setClaimsData,
  getIsResidential,
} from '../../../features/customer/customerJourneySlice';
import displayMenu from '../../../hooks/displayMenu';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  setDisplayGoBackLink,
} from '../../../features/app/appSlice';

import ClaimStatusClassName from '../../common/ClaimStatus';
import convertDateToDisplay from '../../../utils/convertDateToDisplay';
import useTitle from '../../../hooks/useTitle';

const InstallerDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const INSTALLER_ACCOUNT_TYPE = 'installer';
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'Installer.Dashboard.InstallerDashboard.s29',
    'Manage your claims - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const claimsDataValue = useSelector(getClaimsData);
  const isResidential = useSelector(getIsResidential);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchHasResultNo, setSearchHasNoResult] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const recordsPerPage = 50;
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const mapDashboardStatuses = {
    'READY TO CLAIM': 'READY TO CLAIM',
    'SUBMITTED FOR REVIEW': 'SUBMITTED FOR REVIEW',
    'WAITING DYNAMICS': 'SUBMITTED FOR REVIEW',
    'APPROVED FOR PAYMENT': 'APPROVED FOR PAYMENT',
    DECLINED: 'DECLINED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
    'READY FOR INSTALLER CLAIM': 'READY TO CLAIM',
    'INSTALLER CLAIM INCOMPLETE': 'INCOMPLETE',
    'INSTALLER CLAIMED': 'SUBMITTED FOR REVIEW',
    PAYMENT: 'APPROVED FOR PAYMENT',
    APPROVED: 'APPROVED FOR PAYMENT',
    'Installer Claimed': 'SUBMITTED FOR REVIEW',
    REJECTED: 'REJECTED',
    RESUBMITTED: 'SUBMITTED FOR REVIEW',
    'SEND TO DYNAMICS': 'SUBMITTED FOR REVIEW',
    'EXPIRING SOON': 'EXPIRING SOON',
  };

  const searchTermErrorMsg = copyProvider.getCopy(
    'Installer.Dashboard.InstallerDashboard.s17',
    'Enter 3 or more characters'
  );

  const tableHead = [
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s5',
        'Reference number'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s6',
        'Grant type'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s7',
        'Customer'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s8',
        'Postcode'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s9',
        'Claim status'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s10',
        'Action'
      ),
    },
  ];

  const setLinkText = (claimStatus, claimSubmissionDeadlineDate) => {
    if (
      claimStatus === 'READY TO CLAIM' ||
      claimStatus === 'READY FOR INSTALLER CLAIM' ||
      claimStatus === 'INSTALLER CLAIM INCOMPLETE'
    ) {
      return (
        <span>
          {copyProvider.getCopy(
            'Installer.Dashboard.InstallerDashboard.s20',
            'Submit claim by '
          )}
          {claimSubmissionDeadlineDate}
        </span>
      );
    }
    if (
      claimStatus === 'SUBMITTED FOR REVIEW' ||
      claimStatus === 'WAITING DYNAMICS' ||
      claimStatus === 'APPROVED FOR PAYMENT' ||
      claimStatus === 'EXPIRED' ||
      claimStatus === 'CANCELLED' ||
      claimStatus === 'PAYMENT' ||
      claimStatus === 'SEND TO INSTALLER' ||
      claimStatus === 'INSTALLER CLAIMED' ||
      claimStatus === 'RESUBMITTED' ||
      claimStatus === 'SEND TO DYNAMICS'
    ) {
      return copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s21',
        'View claim '
      );
    }
    if (claimStatus === 'DECLINED') {
      return copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s22',
        'View declined claim'
      );
    }
    if (claimStatus === 'REJECTED') {
      return (
        <span>
          {copyProvider.getCopy(
            'Installer.Dashboard.InstallerDashboard.s23',
            'Resubmit claim by '
          )}
          {claimSubmissionDeadlineDate}
        </span>
      );
    }

    if (claimStatus === 'EXPIRING SOON') {
      return (
        <span>
          {copyProvider.getCopy(
            'Installer.Dashboard.InstallerDashboard.s30',
            'Renew by '
          )}
          {claimSubmissionDeadlineDate}
        </span>
      );
    }

    return '';
  };

  const setToLink = (claimStatus, grantType) => {
    let linkDirectedPage;
    if (grantType === 'Renters and flat owners') {
      linkDirectedPage = {
        'READY TO CLAIM':
          '/evhs/installer/claim-grant/review-customer-application',
        'SUBMITTED FOR REVIEW':
          '/evhs/installer/claim-grant/view-submitted-claim',
        'WAITING DYNAMICS': '/evhs/installer/claim-grant/view-submitted-claim',
        'APPROVED FOR PAYMENT':
          '/evhs/installer/claim-grant/view-approved-claim',
        PAYMENT: '/evhs/installer/claim-grant/view-approved-claim',
        DECLINED: '/evhs/installer/claim-grant/view-declined-claim',
        REJECTED: '/resubmit-journey/resubmit-claim',
        'EXPIRING SOON': '/application-renewed',
      };
    }
    if (
      grantType === 'Residential landlord' ||
      grantType === 'Commercial landlord'
    ) {
      linkDirectedPage = {
        'INSTALLER CLAIM INCOMPLETE':
          '/evcg/dashboardHub/view-grant-application',
        'READY FOR INSTALLER CLAIM':
          '/evcg/dashboardHub/view-grant-application',
        RESUBMITTED:
          '/evcg/dashboardHub/view-application-submitted-by-installer',
        'APPROVED FOR PAYMENT': '/evcg/dashboardHub/approved-for-payment',
        DECLINED: '/evcg/dashboardHub/declined',
        PAYMENT: '/evcg/dashboardHub/approved-for-payment',
        'SUBMITTED BY INSTALLER':
          '/evcg/dashboardHub/view-application-submitted-by-installer',
        'SEND TO INSTALLER':
          '/evcg/dashboardHub/view-application-sent-to-installer',
        'SUBMITTED FOR REVIEW': '/evcg/dashboardHub/submitted-for-review',
        'WAITING DYNAMICS': '/evcg/dashboardHub/submitted-for-review',
        REJECTED: '/resubmit-journey/resubmit-claim',
        'INSTALLER CLAIMED': '/evcg/dashboardHub/submitted-for-review',
        'EXPIRING SOON': '/application-renewed',
        EXPIRED: '/landlord-application/application-expired-residential',
      };
    }
    if (grantType === 'Residential car park') {
      linkDirectedPage = {
        'READY TO CLAIM': '/car-parks/installer-2/check-customer-application',
        'INSTALLER CLAIM INCOMPLETE':
          '/car-parks/installer-2/check-customer-application',
        'SUBMITTED FOR REVIEW': '/car-parks/installer-2/submitted-for-review',
        'WAITING DYNAMICS': '/car-parks/installer-2/submitted-for-review',
        DECLINED: '/car-parks/installer-2/declined',
        'APPROVED FOR PAYMENT': '/car-parks/installer-2/approved-for-payment',
        PAYMENT: '/car-parks/installer-2/approved-for-payment',
        'EXPIRING SOON': '/application-renewed',
        EXPIRED: '/landlord-application/application-expired-residential',
        REJECTED: '/resubmit-journey/resubmit-claim',
      };
    }

    if (grantType === 'Staff and fleet car park') {
      linkDirectedPage = {
        'READY TO CLAIM':
          '/car-parks/installer-2/sme/check-customer-application',
        'INSTALLER CLAIM INCOMPLETE':
          '/car-parks/installer-2/sme/check-customer-application',
        'SUBMITTED FOR REVIEW':
          '/car-parks/installer-2/sme/submitted-for-review',
        'WAITING DYNAMICS': '/car-parks/installer-2/sme/submitted-for-review',
        DECLINED: '/car-parks/installer-2/sme/declined',
        PAYMENT: '/car-parks/installer-2/sme/approved-for-payment',
        'APPROVED FOR PAYMENT':
          '/car-parks/installer-2/sme/approved-for-payment',
        'EXPIRING SOON': '/application-renewed',
        EXPIRED: '/landlord-application/application-expired-residential',
        REJECTED: '/resubmit-journey/resubmit-claim',
      };
    }
    return linkDirectedPage[claimStatus];
  };

  const handleLinkClick = (customer) => {
    return customer.claimStatus.toUpperCase() === 'EXPIRING SOON'
      ? window.location.assign(`
                    ${setToLink(
                      customer.claimStatus.toUpperCase(),
                      customer.grant_type
                    )}/${customer.grant_type}/${customer.document_id}`)
      : window.location.assign(`
                      ${setToLink(
                        customer.claimStatus.toUpperCase(),
                        customer.grant_type
                      )}/${customer.document_id}`);
  };

  const populateTable = () => {
    return (
      claimsDataValue &&
      claimsDataValue.length > 0 &&
      claimsDataValue.map((customer) => {
        return {
          cells: [
            {
              children: customer.customerReferenceNumber,
            },
            {
              children: customer.grant_type,
            },
            {
              children: `${customer.firstName} ${customer.lastName}`,
            },
            {
              children: customer.postCode,
            },
            {
              children: ClaimStatusClassName(
                mapDashboardStatuses[customer.claimStatus.toUpperCase()]
              ),
            },
            {
              children: (
                <Link to={{}} onClick={() => handleLinkClick(customer)}>
                  {setLinkText(
                    customer.claimStatus.toUpperCase(),
                    convertDateToDisplay(
                      customer.claim_submission_deadline_date
                    )
                  )}
                </Link>
              ),
            },
          ],
        };
      })
    );
  };

  displayMenu();

  const getInstaller = () => {
    getInstallerDetails()
      .then((response) => {
        if (response.data.success) {
          if (
            response.data.account_type.toLowerCase() === INSTALLER_ACCOUNT_TYPE
          ) {
            getClaimsByInstallerId({ searchTerm: '' })
              .then((res) => {
                if (res.data.success && res.data.totalClaims > 0) {
                  dispatch(setIsResidential(response.data.isResidential));
                }
              })
              .catch(() => {
                // TODO: error handling later
              });
          }
        }
      })
      .catch(() => {
        // Todo later error handling
      });
  };
  const fetchClaims = async () => {
    try {
      const response = await getClaimsByInstallerId({ searchTerm: '' });
      if (response?.data?.success) {
        setTotalRecords(response.data.claims.length);
        const startIndex = (currentPage - 1) * recordsPerPage;
        const claimsForCurrentPage = response.data.claims.slice(
          startIndex,
          startIndex + recordsPerPage
        );
        dispatch(setClaimsData(claimsForCurrentPage));
      }
    } catch (err) {
      history.push('/installer/claim-grant/installer-dashboard-first-time');
    }
  };
  useEffect(() => {
    if (
      isResidential !== undefined ||
      isResidential !== null ||
      isResidential !== ''
    )
      getInstaller();

    dispatch(setDisplayGoBackLink(false));
    fetchClaims();
    return () => dispatch(setDisplayGoBackLink(true));
  }, [currentPage]);

  const handleAddCustomerDetailsSubmit = () => {
    window.location.assign(
      '/evhs/installer/application-setup/customer-details'
    );
  };

  const handleSearch = (event) => {
    event.preventDefault();
    let hasError = false;
    if (!checkSearchTerm.test(searchTerm)) {
      const searchTermError = {
        children: searchTermErrorMsg,
      };
      setErrorMsg(searchTermError);
      setErrorList([{ ...searchTermError, href: '#search' }]);
      hasError = true;
      return;
    }
    setErrorList([]);
    setErrorMsg(null);
    if (!hasError) {
      getClaimsByInstallerId({
        searchTerm: searchTerm.toLowerCase(),
      })
        .then(({ data: { claims, success } }) => {
          if (success) {
            dispatch(setClaimsData(claims));
            if (!claims.length) {
              setSearchHasNoResult(true);
            } else {
              setSearchPerformed(true);
              setSearchHasNoResult(false);
            }
          }
        })
        .catch(() => {
          setSearchHasNoResult(true);
        });
    }
  };
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      {isResidential && (
        <>
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-third">
              <h1 className="govuk-heading-xl">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s1',
                  'Manage your claims'
                )}
              </h1>
            </div>
          </div>

          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-third">
              <p className="govuk-body">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s2',
                  'To begin a new claim for the'
                )}{' '}
                <b>
                  {copyProvider.getCopy(
                    'Installer.Dashboard.InstallerDashboard.s3',
                    'renters and flat owners grant'
                  )}
                </b>
                <br />
                <Link to={{}} onClick={handleAddCustomerDetailsSubmit}>
                  {copyProvider.getCopy(
                    'Installer.Dashboard.InstallerDashboard.s4',
                    "enter your customer's details."
                  )}
                </Link>
              </p>
            </div>
            <br />
            <hr className="govuk-section-break govuk-section-break--l govuk-section-break--visible" />
          </div>
        </>
      )}
      <div className="govuk-grid-row govuk-!-margin-top-4">
        <div className="moj-search">
          <form action="#" method="post">
            <Input
              className="govuk-input moj-search__input"
              id="search"
              hint={{
                children: copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s11',
                  'You can search using the customer name, postcode and reference number'
                ),
              }}
              label={{
                children: (
                  <h2 className="govuk-heading-m">
                    {copyProvider.getCopy(
                      'Installer.Dashboard.InstallerDashboard.s12',
                      'Find a claim'
                    )}
                  </h2>
                ),
              }}
              value={searchTerm}
              errorMessage={errorMsg}
              name="search"
              type="search"
              onChange={onSearchChange}
            />
            <Button
              className="moj-search__button"
              data-module="govuk-button"
              onClick={handleSearch}
            >
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s13',
                'Search'
              )}
            </Button>
          </form>
        </div>
      </div>
      {searchHasResultNo && (
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-one-half">
            <h3 className="govuk-heading-s">
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s14',
                'There are no matching results'
              )}
            </h3>
            <p className="govuk-body">
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s18',
                'Improve your search results by:'
              )}
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s15',
                  'double-checking your spelling'
                )}
              </li>
              <li>
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s16',
                  'using few keywords'
                )}
              </li>
            </ul>
            <a
              href="/installer/claim-grant/installer-dashboard"
              className="govuk-link "
            >
              <span role="presentation">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s19',
                  'Clear search'
                )}
              </span>
            </a>
          </div>
        </div>
      )}
      {searchPerformed && !searchHasResultNo && (
        <div className="govuk-grid-row">
          <Table head={tableHead} rows={populateTable()} />
          <a
            href="/installer/claim-grant/installer-dashboard"
            className="govuk-link "
          >
            <span role="presentation">
              {copyProvider.getCopy(
                '.ClaimGrant.InstallerDashboard.s19',
                'Clear search'
              )}
            </span>
          </a>
        </div>
      )}
      {!searchPerformed && !searchHasResultNo && (
        <div className="govuk-grid-row">
          <Table head={tableHead} rows={populateTable()} />
        </div>
      )}
      {!searchTerm && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
        />
      )}
    </>
  );
};
const Pagination = ({
  totalRecords,
  recordsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getVisiblePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i += 1) {
      if (
        i === 1 ||
        i === totalPages ||
        i === currentPage ||
        i === currentPage - 1 ||
        i === currentPage + 1
      ) {
        pages.push(i);
      } else if (pages[pages.length - 1] !== '...') {
        pages.push('...');
      }
    }
    return pages;
  };

  return (
    <nav
      className="govuk-pagination"
      aria-label="Pagination"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '20px',
      }}
    >
      {currentPage > 1 && (
        <div className="govuk-pagination__prev">
          <a
            className="govuk-link govuk-pagination__link"
            href="/installer/claim-grant/installer-dashboard"
            rel="prev"
            onClick={(event) => {
              event.preventDefault();
              handlePageChange(currentPage - 1);
            }}
          >
            <svg
              className="govuk-pagination__icon govuk-pagination__icon--prev"
              xmlns="http://www.w3.org/2000/svg"
              height="13"
              width="15"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 15 13"
            >
              <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z" />
            </svg>
            <span className="govuk-pagination__link-title">
              Previous<span className="govuk-visually-hidden"> page</span>
            </span>
          </a>
        </div>
      )}
      <ul
        style={{
          display: 'flex',
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          gap: '10px',
        }}
      >
        {getVisiblePageNumbers().map((page) => {
          const isEllipsis = page === '...';
          return (
            <li
              key={page}
              className={
                isEllipsis
                  ? 'govuk-pagination__item govuk-pagination__item--ellipses'
                  : 'govuk-pagination__item'
              }
            >
              <a
                className="govuk-link govuk-pagination__link"
                href="/installer/claim-grant/installer-dashboard"
                aria-label={`Page ${page}`}
                aria-current={page === currentPage ? 'page' : undefined}
                onClick={(e) => {
                  e.preventDefault();
                  if (!isEllipsis) {
                    handlePageChange(page);
                  }
                }}
                style={{
                  padding: '5px 10px',
                  textDecoration: isEllipsis ? 'none' : 'underline',
                  backgroundColor:
                    currentPage === page ? '#1d8feb' : 'transparent',
                  color: currentPage === page ? 'white' : '#4c2c92',
                  cursor: isEllipsis ? 'default' : 'pointer',
                  fontWeight: isEllipsis ? 'bold' : 'normal',
                }}
              >
                {page}
              </a>
            </li>
          );
        })}
      </ul>
      {currentPage < totalPages && (
        <div className="govuk-pagination__next">
          <a
            className="govuk-link govuk-pagination__link"
            href="/installer/claim-grant/installer-dashboard"
            rel="next"
            onClick={(event) => {
              event.preventDefault();
              handlePageChange(currentPage + 1);
            }}
          >
            <span className="govuk-pagination__link-title">
              Next
              <span className="govuk-visually-hidden"> page</span>
            </span>
            <svg
              className="govuk-pagination__icon govuk-pagination__icon--next"
              xmlns="http://www.w3.org/2000/svg"
              height="13"
              width="15"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 15 13"
            >
              <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z" />
            </svg>
          </a>
        </div>
      )}
    </nav>
  );
};
// PropTypes for the component
Pagination.propTypes = {
  totalRecords: PropTypes.isRequired,
  recordsPerPage: PropTypes.isRequired,
  currentPage: PropTypes.isRequired,
  setCurrentPage: PropTypes.isRequired,
};
export default InstallerDashboard;
