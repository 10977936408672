import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkedEnterprise } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import {
  setIsLinkedEnterprise,
  getIsLinkedEnterprise,
} from '../../features/registration/registrationJourneySlice';
import { updateAccount } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const LinkedEnterpriseDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLinkedEnterprise = useSelector(getIsLinkedEnterprise);
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  /* eslint-disable */
  const [hasError, setHasError] = useState(false);
  const [errorList, setErrorList] = useState([]);
  /* eslint-disable */

  useTitle(
    'SMEReg.LinkedEnterprise.s29',
    'Is your company part of a linked enterprise? - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const errorMsg = copyProvider.getCopy(
    'SMEReg.LinkedEnterprise.s1',
    'Is your company part of a linked enterprise?'
  );

  const handleSelection = (event) => {
    dispatch(setIsLinkedEnterprise(event.target.value));
  };

  const handleBtnClick = () => {
    updateAccount({
      isLinkedEnterprise: isLinkedEnterprise === 'yes',
    })
      .then(() => {
        setHasError(false);

        if (isLinkedEnterprise === 'yes') {
          history.push('/sme-reg/id-linked-enterprise');
        } else {
          history.push('/sme-reg/company-identification');
        }
      })
      .catch(() => {
        setHasError(true);
        // setErrorList([{ children: errorMsg, href: '#yes' }]);
      });
  };

  return (
    <LinkedEnterprise
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      body={{
        title: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s1',
          'Is your company part of a linked enterprise?'
        ),
        sectionOne: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s8',
          'Most enterprises and companies are independent and are not linked enterprises.'
        ),
        sectionTwo: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s9',
          'A linked enterprise is directly or indirectly owned or controlled by another enterprise.'
        ),
      }}
      btn={{
        text: copyProvider.getCopy('SMEReg.LinkedEnterprise.s6', 'Continue'),
        handleBtnClick,
      }}
      radio={{
        errorMsg,
        handleSelection: (event) => handleSelection(event),
        value: isLinkedEnterprise,
        itemOne: 'Yes',
        itemTwo: 'No',
      }}
      serviceError={false}
      details={{
        title: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s7',
          'What is a linked enterprise'
        ),
        paragraphOne: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s2',
          'Linked enterprises are enterprises which have any of the following relationships with each other:'
        ),
        unOrderedListOne: {
          bulletPointOne: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s10',
            'if your company is not a partner with or linked to another enterprise then you are not a linked enterprise'
          ),
          bulletPointTwo: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s11',
            'if your company is independent and it does not have any other company participating in it, then it is not a linked enterprise'
          ),
          bulletPointThree: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s12',
            'if another company or enterprise has a large stake in your company, it is likely that your company is a linked enterprise'
          ),
          bulletPointFour: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s13',
            'if another company or enterprise controls your company it is likely that your company is a linked enterprise'
          ),
        },
        subHeading: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s14',
          'Example of a linked enterprise:'
        ),
        paragraphTwo: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s15',
          'Volkswagen Group UK is a company that works like a linked enterprise (or controlling enterprise). Some of its brands include:'
        ),
        unOrderedListTwo: {
          bulletPointOne: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s16',
            'Volkswagen'
          ),
          bulletPointTwo: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s17',
            'Audi'
          ),
          bulletPointThree: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s18',
            'SEAT'
          ),
          bulletPointFour: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s19',
            'ŠKODA'
          ),
          bulletPointFive: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s20',
            'Volkswagen Commercial Vehicles'
          ),
        },
        paragraphThree: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s21',
          "Take Audi as an example. If Audi want to apply for a chargepoint grant they need to use the Volkswagen Group UK number. That is their controlling company's number, not their Audi Companies House number."
        ),
        paragraphFour: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s22',
          'If you are still unsure about being a linked enterprise there is more information in the '
        ),
        paragraphFive: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s24',
          '  and the '
        ),
        paragraphSix: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s25',
          'European Commission User guide'
        ),
        paragraphSeven: copyProvider.getCopy(
          'SMEReg.LinkedEnterprise.s26',
          ' to the SME Definition.'
        ),
        linkOne: {
          to: 'https://www.gov.uk/hmrc-internal-manuals/international-manual/intm412100',
          text: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s23',
            'HMRC internal manual'
          ),
        },
        linkTwo: {
          to: 'https://ec.europa.eu/growth/smes/sme-definition_en',
          text: copyProvider.getCopy(
            'SMEReg.LinkedEnterprise.s25',
            'European Commission User guide'
          ),
        },
      }}
    />
  );
};

export default LinkedEnterpriseDetails;
