import { format } from 'date-fns';
import convertFirebaseTimestampToDate from './convertFirebaseTimestampToDate';

const convertDateToDisplay = (timestamp) => {
  if (timestamp) {
    const date = convertFirebaseTimestampToDate(timestamp);

    const convertedDate = date.setMonth(date.getMonth());

    return format(new Date(convertedDate), 'dd MMMM yyyy');
  }
  return '';
};

export default convertDateToDisplay;
