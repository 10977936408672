/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Route, Redirect } from 'react-router-dom';
import { useAuthValue } from './AuthContext';

export default function AuthRoute({
  component: Component,
  isAnonymous,
  ...rest
}) {
  const { currentUser } = useAuthValue();

  if (currentUser === false) {
    return null;
  }

  if (!currentUser || (!isAnonymous && !currentUser.emailVerified)) {
    return <Redirect to="/signin" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />}></Route>;
}
