import React from 'react';

const displayMenu = () => {
  React.useEffect(() => {
    document
      ?.querySelector('.govuk-header__menu-button')
      ?.classList.remove('govuk-visually-hidden');
  });
};

export default displayMenu;
