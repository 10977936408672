import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { VerifyInformation } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import {
  getAnswers,
  setAnswers,
} from '../../features/registration/registrationJourneySlice';
import {
  formatMobileNumber,
  formatTelephoneNumber,
} from '../../utils/format-number';
import { checkAnswers } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const CheckAnswers = () => {
  const answers = useSelector(getAnswers);
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const history = useHistory();

  const dispatch = useDispatch();
  useTitle(
    'SMEReg.CheckAnswers.s21',
    'Check your answers - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  useEffect(() => {
    checkAnswers()
      .then((response) => {
        const { data } = response;
        dispatch(setAnswers(data));
      })
      .catch(() => {
        // TODO: Show error message
      });
  }, []);

  const handleBtnClick = () => {
    history.push('/sme-reg/declaration');
  };

  const pageModel = (
    <VerifyInformation
      heading={copyProvider.getCopy(
        'SMEReg.CheckAnswers.s1',
        'Check your answers'
      )}
      subHeading={{
        accountDetails: copyProvider.getCopy(
          'SMEReg.CheckAnswers.s2',
          'Account details'
        ),
        businessDetails: copyProvider.getCopy(
          'SMEReg.CheckAnswers.s9',
          'Business details'
        ),
      }}
      answers={{
        accountDetails: [
          {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s3',
                'Registered email'
              ),
            },
            value: {
              children: <p className="govuk-body">{answers.accountEmail}</p>,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s4',
                'Type of account'
              ),
            },
            value: {
              children: <p className="govuk-body ">{answers.accountType}</p>,
            },
          },
          {
            key: {
              children: copyProvider.getCopy('SMEReg.CheckAnswers.s19', 'Name'),
            },
            value: {
              children: (
                <p className="govuk-body ">
                  {answers.firstName} {answers.lastName}
                </p>
              ),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s6',
                'Role within the business'
              ),
            },
            value: {
              children: (
                <p className="govuk-body">{answers.roleWithinBusiness}</p>
              ),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s7',
                'Mobile number'
              ),
            },
            value: {
              children: (
                <p className="govuk-body ">
                  {formatMobileNumber(answers.mobileNumber)}
                </p>
              ),
            },
          },

          answers.phoneNumber && {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s8',
                'Office landline number'
              ),
            },
            value: {
              children: (
                <p className="govuk-body ">
                  {formatTelephoneNumber(answers.phoneNumber)}
                </p>
              ),
            },
          },
        ],
        businessDetails: [
          {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s13',
                'Linked enterprise'
              ),
            },
            value: {
              children: (
                <p className="govuk-body ">
                  {answers.isLinkedEnterprise ? 'Yes' : 'No'}
                </p>
              ),
            },
          },

          answers.companyRegNumber && {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s14',
                'Companies House number'
              ),
            },
            value: {
              children: (
                <p className="govuk-body">{answers.companyRegNumber}</p>
              ),
            },
          },

          answers.vatRegNumber && {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s17',
                'UK VAT registration number'
              ),
            },
            value: {
              children: (
                <p className="govuk-body">
                  {answers.vatRegNumber && answers.vatRegNumber}
                </p>
              ),
            },
          },

          answers.businessName && {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s15',
                'Business name'
              ),
            },
            value: {
              children: <p>{answers.businessName}</p>,
            },
          },

          answers.businessAddressLine1 && {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s16',
                'Business address'
              ),
            },
            value: {
              children: (
                <div key="0" className="govuk-body">
                  <p className="govuk-body">
                    <section>
                      {answers.businessAddressLine1 &&
                        `${answers.businessAddressLine1},`}
                    </section>
                    <section>
                      {answers.businessAddressLine2 &&
                        `${answers.businessAddressLine2}`}
                    </section>
                    <section>
                      {answers.businessAddressCity &&
                        `${answers.businessAddressCity},`}
                    </section>
                    <section>
                      {answers.businessAddressCounty &&
                        `${answers.businessAddressCounty.replace('.', '')},`}
                    </section>
                    <section>
                      {answers.businessAddressPostcode &&
                        answers.businessAddressPostcode}
                      {answers.businessAddressCountry && ','}
                    </section>
                    <section>
                      {answers.businessAddressCountry &&
                        answers.businessAddressCountry}
                    </section>
                  </p>
                </div>
              ),
            },
          },
          answers.noOfEmployees && {
            key: {
              children: copyProvider.getCopy(
                'SMEReg.CheckAnswers.s22',
                'Number of employees'
              ),
            },
            value: {
              children: <p>{answers.noOfEmployees}</p>,
            },
          },
        ],
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.CheckAnswers.s18',
          'Confirm and continue'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
  return pageModel;
};

export default CheckAnswers;
