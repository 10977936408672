/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountTypeSelected: '',
  accountEmail: '',
  buildingType: null,
  repeatAccountEmail: '',
  verificationCode: '',
  firstName: '',
  lastName: '',
  roleName: '',
  mobileNumber: '',
  telephoneNumber: '',
  passwordValue: '',
  repeatPasswordValue: '',
  landLordType: null,
  accessCodeMobile: '',
  isLinkedEnterprise: '',
  companyRegistrationNumber: '',
  linkedEnterpriseNumber: '',
  companyData: null,
  businessVatRegistration: '',
  businessVatData: null,
  propertyTypesSelected: [],
  emailCodeResent: false,
  answers: '',
  regStartDate: '',
  serviceError: false,
  noOfEmployees: '',
  securityCode: '',
};

export const registrationJourneySlice = createSlice({
  name: 'registrationJourney',
  initialState,
  reducers: {
    resetRegistrationJourney: () => initialState,
    setPropertyTypesSelected: (state, action) => {
      // If the user has selected the Residential checkbox (position 0 in the array), the data is checked,
      // then that as the value. Otherwise, set the value to nothing/not checked (null).
      const data = action.payload;
      if (data.value === 'Residential') {
        state.propertyTypesSelected[0] = data.checked ? data.value : null;
      } else if (data.value === 'Commercial') {
        state.propertyTypesSelected[1] = data.checked ? data.value : null;
      }
    },
    setAccountTypeSelected: (state, action) => {
      state.accountTypeSelected = action.payload;
    },

    setAccountEmail: (state, action) => {
      state.accountEmail = action.payload;
    },
    setRepeatAccountEmail: (state, action) => {
      state.repeatAccountEmail = action.payload;
    },
    setVerificationCode: (state, action) => {
      state.verificationCode = action.payload;
    },
    setAccountFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setAccountLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setAccountRoleName: (state, action) => {
      state.roleName = action.payload;
    },
    setAccountMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    setAccountTelephoneNumber: (state, action) => {
      state.telephoneNumber = action.payload;
    },
    setAccountPassword: (state, action) => {
      state.passwordValue = action.payload;
    },
    setAccountRepeatedPassword: (state, action) => {
      state.repeatPasswordValue = action.payload;
    },
    setAccountLandLordType: (state, action) => {
      state.landLordType = action.payload;
    },
    setAccessCodeMobile: (state, action) => {
      state.accessCodeMobile = action.payload;
    },
    setIsLinkedEnterprise: (state, action) => {
      state.isLinkedEnterprise = action.payload;
    },
    setCompanyRegistrationNumber: (state, action) => {
      state.companyRegistrationNumber = action.payload;
    },
    setLinkedEnterpriseNumber: (state, action) => {
      state.linkedEnterpriseNumber = action.payload;
    },
    setCompanyData: (state, action) => {
      state.businessVatData = null;
      state.companyData = action.payload;
    },
    setBusinessVatRegistration: (state, action) => {
      state.businessVatRegistration = action.payload;
    },
    setBusinessVatData: (state, action) => {
      state.companyData = null;
      state.businessVatData = action.payload;
    },
    setEmailCodeResent: (state, action) => {
      state.emailCodeResent = action.payload;
    },
    setAnswers: (state, action) => {
      state.answers = action.payload;
    },
    setRegStartDate: (state, action) => {
      state.regStartDate = action.payload;
    },
    setServiceError: (state, action) => {
      state.serviceError = action.payload;
    },
    setNoOfEmployees: (state, action) => {
      state.noOfEmployees = action.payload;
    },
    setSecurityCode: (state, action) => {
      state.securityCode = action.payload;
    },
  },
});

export const {
  setPropertyTypesSelected,
  setAccountTypeSelected,
  setAccountEmail,
  setRepeatAccountEmail,
  setVerificationCode,
  setAccountFirstName,
  setAccountLastName,
  setAccountRoleName,
  setAccountMobileNumber,
  setAccountTelephoneNumber,
  setAccountLandLordType,
  setAccessCodeMobile,
  setAccountPassword,
  setAccountRepeatedPassword,
  setAccountContactDetails,
  setIsLinkedEnterprise,
  setCompanyRegistrationNumber,
  setLinkedEnterpriseNumber,
  setCompanyData,
  setBusinessVatRegistration,
  setBusinessVatData,
  setEmailCodeResent,
  setAnswers,
  setRegStartDate,
  setServiceError,
  setNoOfEmployees,
  resetRegistrationJourney,
  setSecurityCode,
} = registrationJourneySlice.actions;

export const getPropertyTypesSelected = (state) =>
  state.registrationJourney.propertyTypesSelected;

export const getAccountTypeSelected = (state) =>
  state.registrationJourney.accountTypeSelected;

export const getAccountEmail = (state) =>
  state.registrationJourney.accountEmail;

export const getRepeatAccountEmail = (state) =>
  state.registrationJourney.repeatAccountEmail;

export const getVerificationCode = (state) =>
  state.registrationJourney.verificationCode;

export const getAccountFirstName = (state) =>
  state.registrationJourney.firstName;

export const getAccountLastName = (state) => state.registrationJourney.lastName;

export const getAccountRoleName = (state) => state.registrationJourney.roleName;

export const getAccountMobileNumber = (state) =>
  state.registrationJourney.mobileNumber;

export const getAccountTelephoneNumber = (state) =>
  state.registrationJourney.telephoneNumber;

export const getAccountPasswordValue = (state) =>
  state.registrationJourney.passwordValue;

export const getAccountRepeatPasswordValue = (state) =>
  state.registrationJourney.repeatPasswordValue;

export const getAccountLandLordType = (state) =>
  state.registrationJourney.landLordType;

export const getAccessCodeMobile = (state) =>
  state.registrationJourney.accessCodeMobile;

export const getIsLinkedEnterprise = (state) =>
  state.registrationJourney.isLinkedEnterprise;

export const getCompanyRegistrationNumber = (state) =>
  state.registrationJourney.companyRegistrationNumber;

export const getLinkedEnterpriseNumber = (state) =>
  state.registrationJourney.linkedEnterpriseNumber;

export const getCompanyData = (state) => state.registrationJourney.companyData;

export const getBusinessVatRegistration = (state) =>
  state.registrationJourney.businessVatRegistration;

export const getBusinessVatData = (state) =>
  state.registrationJourney.businessVatData;

export const getEmailCodeResent = (state) =>
  state.registrationJourney.emailCodeResent;

export const getAnswers = (state) => state.registrationJourney.answers;

export const getRegStartDate = (state) =>
  state.registrationJourney.regStartDate;

export const getServiceError = (state) =>
  state.registrationJourney.serviceError;

export const getNoOfEmployees = (state) =>
  state.registrationJourney.noOfEmployees;

export const getSecurityCode = (state) =>
  state.registrationJourney.securityCode;

export default registrationJourneySlice.reducer;
