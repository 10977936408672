import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SignIn from './components/installer/SignIn';
import InstallerDashboardFirstTime from './components/installer/dashboard/InstallerDashboardFirstTime';
import InstallerDashboard from './components/installer/dashboard/InstallerDashboard';
import Cookies from './components/Cookies';
import Dashboard from './components/dashboardHub/Dashboard';
import DashboardPMC from './components/dashboardHub/DashboardPMC';
import AuthRoute from './AuthRoute';
import YourAccount from './components/dashboardHub/YourAccount';
import ReviewClaim from './components/installer/resubmit/ReviewClaim';
import ResubmitConfirm from './components/installer/resubmit/ResubmitConfirm';
import ResubmitClaim from './components/installer/resubmit/ResubmitClaim';
import CheckAnswersResubmit from './components/installer/resubmit/CheckAnswersResubmit';
import AccountLocked from './components/installer/AccountLocked';
import AccountType from './components/AccountType';
import CreateAccount from './components/smeReg/CreateAccount';
import CheckEmail from './components/smeReg/CheckEmail';
import VerifiedEmail from './components/smeReg/VerifiedEmail';
import VerificationCodeError from './components/smeReg/VerificationCodeError';
import BusinessVatRegistration from './components/smeReg/CompanyVATNumber';
import ContactDetails from './components/smeReg/ContactDetails';
import AccessCodeMobile from './components/smeReg/AccessCodeMobile';
import VerifiedMobile from './components/smeReg/MobileVerified';
import CreatePassword from './components/smeReg/CreatePassword';
import ConfirmBusinessDetails from './components/smeReg/ConfirmBusinessDetails';
import LinkedEnterpriseDetails from './components/smeReg/LinkedEnterpriseDetails';
import PasswordCreated from './components/smeReg/PasswordCreated';
import LinkedEnterpriseNumber from './components/smeReg/LinkedEnterpriseNumber';
import CompanyRegNumber from './components/smeReg/CompanyRegNumber';
import CannotProceedSME from './components/smeReg/CannotProceedSME';
import Employees from './components/smeReg/Employees';
import CheckAnswers from './components/smeReg/CheckAnswers';
import Declaration from './components/smeReg/Declaration';
import AccountCreated from './components/smeReg/AccountCreated';
import CannotProceedEmployee from './components/smeReg/CannotProceedEmployee';
import PasswordRequestLink from './components/PasswordRequestLink';
import PasswordResetConfirmation from './components/smeReg/PasswordResetConfirmation';
import SMEInstallerDashboardFirstTime from './components/smeReg/DashboardFirstTime';
import ResetPassword from './components/ResetPassword';
import PasswordUpdatedConfirmation from './components/PasswordUpdatedConfirmation';
import DashboardSME from './components/dashboardHub/DashboardSME';
import ApplicationRenewed from './components/ApplicationRenewed';
import ViewExiredClaim from './components/dashboardHub/ViewExiredClaim';
import DeactivateAccount from './components/DeactivateAccount';
import AccessCode from './components/installer/AccessCode';

export default function RoutesTree() {
  return (
    <Switch>
      {/* ******************** */}
      {/* Generic user routes  */}
      {/* ******************** */}
      <Route exact path="/cookies">
        <Cookies />
      </Route>
      <Route exact path="/" render={() => <Redirect push to="/signin" />} />
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/account-locked" component={AccountLocked} />
      <Route exact path="/account-type" component={AccountType} />
      <Route exact path="/verify-security-code" component={AccessCode} />

      {/* *************************** */}
      {/* Installer claim grant routes */}
      {/* *************************** */}
      <Route
        exact
        path="/installer/claim-grant/installer-dashboard"
        component={InstallerDashboard}
      />
      <Route
        exact
        path="/installer/claim-grant/installer-dashboard-first-time"
        component={InstallerDashboardFirstTime}
      />
      {/* *************************** */}
      {/* Resubmit Claim routes */}
      {/* *************************** */}
      <Route
        exact
        path="/resubmit-journey/resubmit-claim/:id"
        component={ResubmitClaim}
      />
      <Route
        exact
        path="/resubmit-journey/review-claim-evhs/:id"
        component={ReviewClaim}
      />
      <Route
        exact
        path="/resubmit-journey/review-claim-residential-landlords/:id"
        component={ReviewClaim}
      />
      <Route
        exact
        path="/resubmit-journey/review-claim-commercial-landlords/:id"
        component={ReviewClaim}
      />
      <Route
        exact
        path="/resubmit-journey/review-claim-carparks/:id"
        component={ReviewClaim}
      />
      <Route
        exact
        path="/resubmit-journey/confirmation-resubmitted/:id"
        component={ResubmitConfirm}
      />
      <Route
        exact
        path="/resubmit-journey/check-answers-resubmit/:id"
        component={CheckAnswersResubmit}
      />

      {/* *************************** */}
      {/* Landlord Dashboard routes */}
      {/* *************************** */}
      <AuthRoute exact path="/dashboardHub/dashboard">
        <Dashboard />
      </AuthRoute>
      <AuthRoute exact path="/dashboardHub/dashboard-pmc">
        <DashboardPMC />
      </AuthRoute>
      <AuthRoute exact path="/sme-app/dashboard2">
        <DashboardSME />
      </AuthRoute>
      <AuthRoute exact path="/dashboardHub/your-account">
        <YourAccount />
      </AuthRoute>
      <AuthRoute
        exact
        path="/application-renewed/:grantType/:id"
        component={ApplicationRenewed}
      />
      {/* ***************************** */}
      {/* *   SME Registration routes * */}
      {/* ***************************** */}
      <Route exact path="/sme-reg/create-account" component={CreateAccount} />
      <Route exact path="/sme-reg/check-email" component={CheckEmail} />
      <Route exact path="/sme-reg/email-verified" component={VerifiedEmail} />
      <Route
        exact
        path="/sme-reg/email-code-error"
        component={VerificationCodeError}
      />
      <Route
        exact
        path="/sme-reg/company-identification-vat"
        component={BusinessVatRegistration}
      />
      <Route exact path="/sme-reg/contact" component={ContactDetails} />
      <Route
        exact
        path="/sme-reg/access-code-mobile"
        component={AccessCodeMobile}
      />
      <Route exact path="/sme-reg/phone-verified" component={VerifiedMobile} />
      <Route exact path="/sme-reg/create-password" component={CreatePassword} />
      <Route
        exact
        path="/sme-reg/confirm-business-details"
        component={ConfirmBusinessDetails}
      />
      <Route
        exact
        path="/sme-reg/linked-enterprise"
        component={LinkedEnterpriseDetails}
      />
      <Route
        exact
        path="/sme-reg/password-verified"
        component={PasswordCreated}
      />
      <Route
        exact
        path="/sme-reg/id-linked-enterprise"
        component={LinkedEnterpriseNumber}
      />
      <Route
        exact
        path="/sme-reg/company-identification"
        component={CompanyRegNumber}
      />
      <Route path="/sme-reg/cannot-proceed" component={CannotProceedSME} />
      <Route path="/sme-reg/number-of-employees" component={Employees} />
      <Route path="/sme-reg/check-answers" component={CheckAnswers} />
      <Route path="/sme-reg/declaration" component={Declaration} />
      <Route path="/sme-reg/account-created" component={AccountCreated} />
      <Route
        path="/sme-reg/cannot-proceed-employees"
        component={CannotProceedEmployee}
      />
      <Route
        path="/sme-app/dashboard-first-time"
        component={SMEInstallerDashboardFirstTime}
      />

      {/* ***************************** */}
      {/* *   Password Reset Route routes * */}
      {/* ***************************** */}
      <Route path="/forgotten-password" component={PasswordRequestLink} />
      <Route path="/confirm-link-sent" component={PasswordResetConfirmation} />
      <Route
        exact
        path="/reset-password/:encodedEmail/:encodedOtp"
        component={ResetPassword}
      />
      <Route
        exact
        path="/password-updated-confirmation"
        component={PasswordUpdatedConfirmation}
      />
      <Route
        exact
        path="/landlord-application/application-expired-residential/:id"
        component={ViewExiredClaim}
      />
      <Route
        exact
        path="/sme-app/application-expired-fleet/:id"
        component={ViewExiredClaim}
      />
      <Route exact path="/deactivate-account" component={DeactivateAccount} />
    </Switch>
  );
}
