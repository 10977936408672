import React from 'react';
import { useHistory } from 'react-router-dom';
import { Declare } from '@pepper-ui/common';
import { useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { completeRegistration } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const Declaration = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);

  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'SMEReg.Declaration.s10',
    'Declaration - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const history = useHistory();

  const handleBtnClick = () => {
    completeRegistration()
      .then(() => {
        history.push('/sme-reg/account-created');
      })
      .catch(() => {
        // TODO: Show error message
      });
  };

  return (
    <Declare
      heading={copyProvider.getCopy('SMEReg.Declaration.s1', 'Declaration')}
      body={{
        paragraph1: copyProvider.getCopy(
          'SMEReg.Declaration.s2',
          'You are creating an account to apply for grants to install EV chargepoints where parking is reserved for staff or fleet vehicles.'
        ),
        paragraph2: copyProvider.getCopy(
          'SMEReg.Declaration.s3',
          'By continuing, you confirm that you have provided the correct information to the best of your knowledge.'
        ),
        paragraph3: copyProvider.getCopy(
          'SMEReg.Declaration.s4',
          'If this information is incorrect or changes, you may not receive the grants.'
        ),
        paragraph4: copyProvider.getCopy(
          'SMEReg.Declaration.s5',
          'If chosen for an audit, you agree to work with OZEV or its agents.'
        ),
        paragraph5: copyProvider.getCopy(
          'SMEReg.Declaration.s6',
          'To find out how we use your information, '
        ),
      }}
      link={{
        text1: copyProvider.getCopy(
          'SMEReg.Declaration.s7',
          'read the OZEV personal information charter (opens in a new tab)'
        ),
      }}
      warning={copyProvider.getCopy(
        'SMEReg.Declaration.s8',
        'It is an offence to make a false declaration to secure a grant. You could get a fine or face prosecution.'
      )}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.Declaration.s9',
          'Accept and continue'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default Declaration;
