import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CompanyRegistrationNumber } from '@pepper-ui/common';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCompanyRegistrationNumber,
  setCompanyRegistrationNumber,
  setCompanyData,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { getCompanyData } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const CompanyRegNumber = () => {
  const companyRegistrationNumberRedux = useSelector(
    getCompanyRegistrationNumber
  );

  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const dispatch = useDispatch();
  const history = useHistory();
  const [serviceErrorNotAPIValid, setServiceErrorNotAPIValid] = useState(false);
  const [serviceErrorAlreadyExists, setServiceErrorAlreadyExists] =
    useState(false);
  const handleCompanyRegistration = (event) => {
    dispatch(setCompanyRegistrationNumber(event.target.value));
  };

  useTitle(
    'SMEReg.CompanyRegistrationNumber.s10',
    'Enter company registration number - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleLink = () => {
    history.push('/sme-reg/company-identification-vat');
  };

  const handleBtnClick = () => {
    getCompanyData({ companyRegNumber: companyRegistrationNumberRedux })
      .then((response) => {
        const { data } = response;
        dispatch(setCompanyData(data));

        if (
          data !== null &&
          data.companyRegNumber === companyRegistrationNumberRedux
        ) {
          history.push('/sme-reg/confirm-business-details');
        } else {
          setServiceErrorNotAPIValid(true);
        }
      })
      .catch((error) => {
        if (error.code === 'functions/already-exists') {
          setServiceErrorAlreadyExists(true);
        } else {
          setServiceErrorNotAPIValid(true);
        }
      });
  };
  const pageModel = (
    <CompanyRegistrationNumber
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      body={{
        title: copyProvider.getCopy(
          'SMEReg.CompanyRegistrationNumber.s3',
          'Enter your company registration number'
        ),
        paragraph:
          'We will use this number to confirm the business details of your company. This is the best number to use for a fast registration.',
      }}
      input={{
        errorMsg:
          'Enter a valid Company Registration number that consists of 8 characters and can contain letters and numbers',
        hint: 'Companies House provides this number when a company first registers.',
        label: 'Enter company registration number',
        value: companyRegistrationNumberRedux,
        onChange: handleCompanyRegistration,
      }}
      link={{
        text: "I don't have a company registration number",
        to: '/sme-reg/company-identification-vat',
        onClick: handleLink,
      }}
      btn={{
        text: 'Continue',
        onClick: handleBtnClick,
      }}
      serviceErrors={{
        errorMsgNotValid: {
          hasError: serviceErrorNotAPIValid,
          message: 'Entered Company Registration number not recognised',
        },
        errorMsgAlreadyExists: {
          hasError: serviceErrorAlreadyExists,
          message: 'This number is already in use with another account',
        },
      }}
    />
  );

  return pageModel;
};

export default CompanyRegNumber;
