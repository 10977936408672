/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  document_id: '',
  // Mock data value of 0 for testing BugFix/676 & (T46, T47)
  applicationCount: 0,
  clientCount: 0,
  carParkClientCount: 0,
  dashboardUserType: 'landlord', // change value to either 'pmc' or 'landlord' for different dashboard messages

  // Data for T544
  applicationWithdrawn: '',
  applicationStatus: '',

  // Mock data for PMC dashboard Table
  dashboardMockTableData: [
    {
      referenceNumber: '098521',
      grantType: 'Commercial landlord',
      customer: 'Jane Smith',
      postcode: 'WC1N 1XX',
      claimStatus: 'SENT TO INSTALLER',
      action: 'View claim',
      link: '/dashboardHub/view-application-sent-to-installer',
      dateSubmitted: '2021-12-12',
    },
    {
      referenceNumber: '098521',
      grantType: 'Commercial landlord',
      customer: 'Highfield Properties',
      postcode: 'WC1N 1XX',
      claimStatus: 'SUBMITTED BY INSTALLER',
      action: 'View claim',
      //  for when we have landlord table
      dateSubmitted: '2022-01-08',
      link: '/dashboardHub/view-application-submitted-by-installer',
    },
    {
      referenceNumber: '864442',
      grantType: 'Residential landlord',
      customer: 'Highfield Lodge',
      postcode: 'E1 1XX',
      claimStatus: 'COMPLETED',
      action: 'View claim',
      link: '/dashboardHub/view-application-completed',
      dateSubmitted: '2021-12-11',
    },
    {
      referenceNumber: '098521',
      grantType: 'Residential landlord',
      customer: 'Jane Smith',
      postcode: 'WC1N 1XX',
      claimStatus: 'DECLINED',
      action: 'View declined claim',
      link: '/dashboardHub/view-application-declined',
      dateSubmitted: '2021-12-16',
    },
  ],
  // Data for T543 and T542
  claimSummaryMockData: {
    grantType: 'Residential landlord',
    referenceNumber: '390466',
    companyName: 'Bluetick Properties',
    installerName: 'Electric City Installers',
    installerOzevNumber: '835722',
    dateOfClaimApproval: '13 December 2021',
    installationDate: '13 Jan 2022',
  },
  dashboardMockChargepointsData: [
    {
      id: '1',
      manufacturer: 'Phillips',
      model: 'PH023',
      serialNumber: 'ED307',
      photos: ['photo1.jpg', 'photo2.jpg'],
    },
    {
      id: '2',
      manufacturer: 'Sony',
      model: 'SN023',
      serialNumber: 'ED9935',
      photos: ['photo1.jpg', 'photo2.jpg'],
    },
  ],
  userDetails: null,
  submittedApplicationId: null,
  userAccountInfo: null,
  expiredClaim: null,
  userInfo: null,
  grantsRefData: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    resetDashboardSlice: () => initialState,
    setApplicationWithdrawn: (state, action) => {
      state.applicationWithdrawn = action.payload;
    },
    setApplicationStatus: (state, action) => {
      state.applicationStatus = action.payload;
    },
    setDocumentId: (state, action) => {
      state.document_id = action.payload;
      const serializedState = JSON.stringify(state.document_id);
      localStorage.setItem('document_id', serializedState);
    },
    setSubmittedApplicationId: (state, action) => {
      state.submittedApplicationId = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setClientCount: (state, action) => {
      state.clientCount = action.payload;
    },
    setCarParkClientCount: (state, action) => {
      state.carParkClientCount = action.payload;
    },
    setUserAccountInfo: (state, action) => {
      state.userAccountInfo = action.payload;
    },
    setExpiredClaim: (state, action) => {
      state.expiredClaim = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setGrantsRefData: (state, action) => {
      state.grantsRefData = action.payload;
    },
  },
});

export const {
  setApplicationWithdrawn,
  setApplicationStatus,
  setDocumentId,
  setSubmittedApplicationId,
  setUserDetails,
  setClientCount,
  setCarParkClientCount,
  setUserAccountInfo,
  setExpiredClaim,
  resetDashboardSlice,
  setUserInfo,
  setGrantsRefData,
} = dashboardSlice.actions;

export const getClientCount = (state) => state.dashboardSlice.clientCount;

export const getCarParkClientCount = (state) =>
  state.dashboardSlice.carParkClientCount;

export const getApplicationCount = (state) =>
  state.dashboardSlice.applicationCount;

export const getDashboardUserType = (state) =>
  state.dashboardSlice.dashboardUserType;

export const getDashboardMockTableData = (state) =>
  state.dashboardSlice.dashboardMockTableData;

export const getApplicationWithdrawn = (state) =>
  state.dashboardSlice.applicationWithdrawn;

export const getApplicationStatus = (state) =>
  state.dashboardSlice.applicationStatus;

export const getClaimSummaryMockData = (state) =>
  state.dashboardSlice.claimSummaryMockData;

export const getDashboardMockChargepointsData = (state) =>
  state.dashboardSlice.dashboardMockChargepointsData;

export const getUserInfo = (state) => state.dashboardSlice.userInfo;
export const getGrantsRefData = (state) => state.dashboardSlice.grantsRefData;

export const getDocumentId = () => {
  return localStorage.getItem('document_id')
    ? JSON.parse(localStorage.getItem('document_id'))
    : {};
};

export const getUserDetails = (state) => state.dashboardSlice.userDetails;

export const getSubmittedApplicationId = (state) =>
  state.dashboardSlice.submittedApplicationId;

export const getUserAccountInfo = (state) =>
  state.dashboardSlice.userAccountInfo;
export const getExpiredClaim = (state) => state.dashboardSlice.expiredClaim;
export default dashboardSlice.reducer;
