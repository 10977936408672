import React from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmBusinessDetails as ConfirmDetails } from '@pepper-ui/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessVatData,
  getCompanyData,
  getIsLinkedEnterprise,
  setAnswers,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { checkAnswers, updateAccount } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const ConfirmBusinessDetails = () => {
  const companyDataRedux = useSelector(getCompanyData);
  const vatDataRedux = useSelector(getBusinessVatData);
  const isLinkedEnterprise = useSelector(getIsLinkedEnterprise);
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const dispatch = useDispatch();
  useTitle(
    'SMEReg.ConfirmBusinessDetails.s8',
    'Confirm company details - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleHyperLink = () => {
    if (isLinkedEnterprise === 'yes') {
      history.push('/sme-reg/id-linked-enterprise');
    } else {
      history.push('/sme-reg/company-identification-vat');
    }
  };
  const redirectCheckAnswers = () => {
    checkAnswers()
      .then((response) => {
        const { data } = response;
        dispatch(setAnswers(data));
        history.push('/sme-reg/number-of-employees');
      })
      .catch(() => {
        // TODO: Show error message
      });
  };

  const handleBtnClick = () => {
    const data = vatDataRedux !== null ? vatDataRedux : companyDataRedux;
    updateAccount(data)
      .then(() => {
        redirectCheckAnswers();
      })
      .catch(() => {
        // TODO: Show an error message
      });
  };

  const getCompanyDataValues = () => {
    let data;
    if (isLinkedEnterprise === 'yes' && companyDataRedux !== null) {
      data = {
        companyRegNumber: companyDataRedux.companyRegNumber,
        businessName: companyDataRedux.businessName,
        businessAddressLine1: companyDataRedux.businessAddressLine1,
        businessAddressLine2: companyDataRedux.businessAddressLine2,
        businessAddressCity: companyDataRedux.businessAddressCity,
        businessAddressCounty: companyDataRedux.businessAddressCounty,
        businessAddressPostcode: companyDataRedux.businessAddressPostcode,
        businessAddressCountry: companyDataRedux.businessAddressCountry,
      };
    } else if (isLinkedEnterprise === 'yes' && companyDataRedux === null) {
      data = {
        companyRegNumber: vatDataRedux.vatRegNumber,
        businessName: vatDataRedux.businessName,
        businessAddressLine1: vatDataRedux.businessAddressLine1,
        businessAddressLine2: vatDataRedux.businessAddressLine2,
        businessAddressCity: vatDataRedux.businessAddressCity,
        businessAddressCounty: vatDataRedux.businessAddressCounty,
        businessAddressPostcode: vatDataRedux.businessAddressPostcode,
        businessAddressCountry: vatDataRedux.businessAddressCountry,
      };
    } else if (isLinkedEnterprise !== 'yes' && vatDataRedux !== null) {
      data = {
        companyRegNumber: vatDataRedux.vatRegNumber,
        businessName: vatDataRedux.businessName,
        businessAddressLine1: vatDataRedux.businessAddressLine1,
        businessAddressLine2: vatDataRedux.businessAddressLine2,
        businessAddressCity: vatDataRedux.businessAddressCity,
        businessAddressCounty: vatDataRedux.businessAddressCounty,
        businessAddressPostcode: vatDataRedux.businessAddressPostcode,
        businessAddressCountry: vatDataRedux.businessAddressCountry,
      };
    } else {
      data = {
        companyRegNumber: companyDataRedux.companyRegNumber,
        businessName: companyDataRedux.businessName,
        businessAddressLine1: companyDataRedux.businessAddressLine1,
        businessAddressLine2: companyDataRedux.businessAddressLine2,
        businessAddressCity: companyDataRedux.businessAddressCity,
        businessAddressCounty: companyDataRedux.businessAddressCounty,
        businessAddressPostcode: companyDataRedux.businessAddressPostcode,
        businessAddressCountry: companyDataRedux.businessAddressCountry,
      };
    }
    return data;
  };

  const pageModel = (
    <ConfirmDetails
      body={{
        title: copyProvider.getCopy(
          'SMEReg.ConfirmBusinessDetails.s1',
          'Confirm the company details'
        ),
        tableTitle1: copyProvider.getCopy(
          'SMEReg.ConfirmBusinessDetails.s2',
          'Company registration number'
        ),
        tableTitle2: copyProvider.getCopy(
          'SMEReg.ConfirmBusinessDetails.s3',
          'Company name'
        ),
        tableTitle3: copyProvider.getCopy(
          'SMEReg.ConfirmBusinessDetails.s4',
          'Registered company address'
        ),
        sectionParagraph: copyProvider.getCopy(
          'SMEReg.ConfirmBusinessDetails.s5',
          'Your company address may be different from the registered company address. If this is the case you may still proceed. We will not use this address to send any letters. Contact Companies House if you need to update your company address details.'
        ),
      }}
      link={{
        text: copyProvider.getCopy(
          'SMEReg.ConfirmBusinessDetails.s6',
          'This is not my business'
        ),
        to: '/sme-reg/company-registration-number',
        onClick: () => {
          handleHyperLink();
        },
      }}
      btn={{
        text: copyProvider.getCopy(
          'SMEReg.ConfirmBusinessDetails.s7',
          'Confirm and continue'
        ),
        handleBtnClick,
      }}
      companyDataRedux={getCompanyDataValues()}
    />
  );
  return pageModel;
};

export default ConfirmBusinessDetails;
