import React from 'react';
import { Tag } from 'govuk-react-jsx';

const ClaimStatusClassName = (claimStatus) => {
  const classes = {
    'READY TO CLAIM': 'govuk-tag--blue',
    'SUBMITTED FOR REVIEW': 'govuk-tag--yellow',
    'APPROVED FOR PAYMENT': 'govuk-tag--green',
    DECLINED: 'govuk-tag--grey',
    EXPIRED: 'govuk-tag--grey',
    CANCELLED: 'govuk-tag--grey',
    REJECTED: 'govuk-tag--red',
    INCOMPLETE: 'govuk-tag--grey',
    'EXPIRING SOON': 'govuk-tag govuk-tag--orange',
    'WAITING DYNAMICS': 'govuk-tag--yellow',
  };
  return <Tag className={classes[claimStatus]}>{claimStatus}</Tag>;
};

export default ClaimStatusClassName;
