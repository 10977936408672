import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  connectAuthEmulator,
  browserSessionPersistence,
  setPersistence,
  signInAnonymously,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';

const firebaseConfig = {};
let region = null;
if (process.env.NODE_ENV === 'development') {
  firebaseConfig.apiKey =
    process.env.REACT_APP_LOCAL_FIREBASE_API_KEY || 'default';
  firebaseConfig.authDomain =
    process.env.REACT_APP_LOCAL_FIREBASE_AUTH_DOMAIN || 'localhost';
  firebaseConfig.projectId =
    process.env.REACT_APP_LOCAL_FIREBASE_PROJECT_ID ||
    'dft-efm-electricvehicles-prod';
  region = process.env.REACT_APP_LOCAL_REGION || 'europe-west2';
} else {
  firebaseConfig.apiKey = process.env.REACT_APP_PRODUCTION_FIREBASE_API_KEY;
  firebaseConfig.authDomain =
    process.env.REACT_APP_PRODUCTION_FIREBASE_AUTH_DOMAIN;
  firebaseConfig.projectId =
    process.env.REACT_APP_PRODUCTION_FIREBASE_PROJECT_ID;
  firebaseConfig.storageBucket =
    process.env.REACT_APP_PRODUCTION_FIREBASE_STORAGE_BUCKET;
  firebaseConfig.messagingSenderId =
    process.env.REACT_APP_PRODUCTION_FIREBASE_MESSAGING_SENDER_ID;
  firebaseConfig.appId = process.env.REACT_APP_PRODUCTION_FIREBASE_APP_ID;
  region = process.env.REACT_APP_PRODUCTION_REGION;
}
export const app = initializeApp(firebaseConfig);

const functions = getFunctions(app, region);

export const auth = getAuth(app);

setPersistence(auth, browserSessionPersistence);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099');
}
export const getAccount = httpsCallable(functions, 'dashboard-getAccount');
export const getEvListByManufacturer = httpsCallable(
  functions,
  'evhsCustomer-getEvListByManufacturer'
);
export const createApplication = httpsCallable(
  functions,
  'evhsInstaller-createApplication'
);
export const checkRegistrationNumber = httpsCallable(
  functions,
  'evhsCustomer-checkRegistrationNumber'
);
export const submitCustomerInformation = httpsCallable(
  functions,
  'evhsCustomer-submitCustomerInformation'
);

export const generateOtp = httpsCallable(functions, 'evhsCustomer-generateOtp');

export const verifyOtp = httpsCallable(functions, 'evhsCustomer-verifyOtp');

export const uploadResubmitEvidence = httpsCallable(
  functions,
  'resubmit-uploadResubmitEvidence'
);

export const submitResubmitClaim = httpsCallable(
  functions,
  'resubmit-resubmitClaim'
);

export const updateSubmissionDeadlineDate = httpsCallable(
  functions,
  'evhsInstaller-updateSubmissionDeadlineDate'
);

export const signInUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const checkSubcontractorOZEVNumber = httpsCallable(
  functions,
  'evhsInstaller-checkSubcontractorOzevNumber'
);

export const getCustomerById = httpsCallable(
  functions,
  'evhsInstaller-getCustomerById'
);

export const checkVehicleDetails = httpsCallable(
  functions,
  'evhsCustomer-checkVehicleDetails'
);

export const getCustomerLinkInfo = httpsCallable(
  functions,
  'evhsCustomer-getCustomerLinkInfo'
);

export const submitClaim = httpsCallable(
  functions,
  'evhsInstaller-submitClaim'
);

export const getCpManufacturersList = httpsCallable(
  functions,
  'evhsInstaller-getCpManufacturers'
);

export const getCpModelsByManufacturer = httpsCallable(
  functions,
  'evhsInstaller-getCpModelsByManufacturer'
);

export const verifyCpData = httpsCallable(
  functions,
  'evhsInstaller-verifyCpData'
);

export const getCustomersByInstallerId = httpsCallable(
  functions,
  'evhsInstaller-getCustomersByInstallerId'
);

export const getInstallerDetails = httpsCallable(
  functions,
  'evhsInstaller-getInstallerDetails'
);
export const checkMobileNumber = httpsCallable(
  functions,
  'landlordReg-checkMobileNumber'
);
export const resendCode = httpsCallable(functions, 'landlordReg-resendCode');
export const checkEmail = httpsCallable(functions, 'landlordReg-checkEmail');

export const getUserData = httpsCallable(
  functions,
  'dashboard-userAccountDetails'
);

export const getClaimsByInstallerId = httpsCallable(
  functions,
  'evhsInstaller-getClaimsByInstallerId'
);

export const getDashboardData = httpsCallable(functions, 'dashboard-getDbData');

export const getDashboardSmeData = httpsCallable(
  functions,
  'carPark-getDashboardSmeData'
);

export const downloadFiles = httpsCallable(
  functions,
  'evhsInstaller-downloadFiles'
);

export const getDashboardPmcData = httpsCallable(
  functions,
  'dashboardPmc-getDbData'
);

export const deleteEvidence = httpsCallable(
  functions,
  'resubmit-deleteEvidence'
);

export const logLoginAttempts = httpsCallable(
  functions,
  'shared-logLoginAttempts'
);

export const chooseAccountType = httpsCallable(
  functions,
  'landlordReg-chooseAccountType'
);

export const createAccount = httpsCallable(
  functions,
  'landlordReg-createAccount'
);

export const signInRegistration = () => {
  return signInAnonymously(auth);
};

export const checkVATNumber = httpsCallable(
  functions,
  'landlordReg-checkVatNumber'
);

export const updateContactDetails = httpsCallable(
  functions,
  'landlordReg-updateContactDetails'
);

export const reauthenticate = (newEmail, newPassword) => {
  const credential = EmailAuthProvider.credential(newEmail, newPassword);

  return reauthenticateWithCredential(auth.currentUser, credential);
};

export const createPassword = httpsCallable(
  functions,
  'landlordReg-createPassword'
);

export const checkAnswers = httpsCallable(
  functions,
  'landlordReg-checkAnswers'
);

export const updateAccount = httpsCallable(
  functions,
  'landlordReg-updateAccount'
);
export const getCompanyData = httpsCallable(
  functions,
  'landlordReg-getCompanyData'
);

export const completeRegistration = httpsCallable(
  functions,
  'landlordReg-completeRegistration'
);

export const resetPasswordGeneral = httpsCallable(
  functions,
  'dashboard-resetPasswordGeneral'
);

export const changePassword = httpsCallable(functions, 'changePassword');

export const updateCarparkClaimsCountersForUser = httpsCallable(
  functions,
  'carPark-updateCarparkClaimsCountersForUser'
);

export const getApplicationClaim = httpsCallable(
  functions,
  'dashboard-getApplicationClaim'
);

export const disableAccount = httpsCallable(functions, 'disableAccount');
export const getGrantsData = httpsCallable(
  functions,
  'landlordApp-getGrantsData'
);

const firebaseApi = {
  getEvListByManufacturer,
  createApplication,
  checkRegistrationNumber,
  generateOtp,
  verifyOtp,
  submitCustomerInformation,
  signInUser,
  checkSubcontractorOZEVNumber,
  getCpManufacturersList,
  getCpModelsByManufacturer,
  verifyCpData,
  uploadResubmitEvidence,
  submitClaim,
  checkVehicleDetails,
  getCustomersByInstallerId,
  getInstallerDetails,
  checkMobileNumber,
  resendCode,
  getClaimsByInstallerId,
  getDashboardData,
  downloadFiles,
  getDashboardPmcData,
  deleteEvidence,
  submitResubmitClaim,
  logLoginAttempts,
  getAccount,
  chooseAccountType,
  signInRegistration,
  checkEmail,
  checkVATNumber,
  updateContactDetails,
  createPassword,
  reauthenticate,
  checkAnswers,
  updateAccount,
  getCompanyData,
  completeRegistration,
  resetPasswordGeneral,
  changePassword,
  getDashboardSmeData,
  updateCarparkClaimsCountersForUser,
  getApplicationClaim,
  disableAccount,
  getGrantsData,
};

export default firebaseApi;
