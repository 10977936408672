import React from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import {
  setRegStartDate,
  setSmeRegStartDate,
  setPmcRegStartDate,
} from '../features/ga/gaSlice';

const useGaAccountTypeStartEvent = (accountType) => {
  const sendDataToGTM = useGTMDispatch();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const startDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

    if (accountType === 'Landlord') {
      dispatch(setRegStartDate(startDate));
      sendDataToGTM({
        event: 'reg_account_type',
        category: 'LandlordRegistration',
      });
    } else if (accountType.trim() === 'Small to medium enterprise') {
      dispatch(setSmeRegStartDate(startDate));
      sendDataToGTM({
        event: 'sme_reg_account_type',
        category: 'SmeRegistration',
      });
    } else if (accountType.trim() === 'Property Management Company') {
      dispatch(setPmcRegStartDate(startDate));
      sendDataToGTM({
        event: 'pmc_reg_account_type',
        category: 'PmcRegistration',
      });
    }
  }, [accountType]);
  return () => {};
};

export default useGaAccountTypeStartEvent;
