import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EmployeesExceeded } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';

const CannotProceedEmployee = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const history = useHistory();
  useTitle(
    'SMEReg.CannotProceedEmployee.s4',
    'You cannot register as small to meduim enterprise - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const handleBtnClick = () => {
    history.push('/signin');
  };

  return (
    <EmployeesExceeded
      heading={copyProvider.getCopy(
        'SMEReg.CannotProceedEmployee.s1',
        'You cannot register as a small to medium enterprise'
      )}
      body={{
        paragraphOne: copyProvider.getCopy(
          'SMEReg.CannotProceedEmployee.s2',
          'Your company must have fewer than 250 employees to register as a small to medium enterprise.'
        ),
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.CannotProceedEmployee.s3',
          'Cancel my account registration'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default CannotProceedEmployee;
