import React from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';

const DeactivateAccount = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const pageModel = (
    <div>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'DeactivateAccount.s1',
          'Your account has been deactivated'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'DeactivateAccount.s2',
          'You cannot sign in to your OZEV electric vehicle chargepoint grants because your account has been deactivated.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'DeactivateAccount.s3',
          'If you would like to enquire about why your account was deactivated, send an email to OZEV_IT_issues@dvla.gov.uk.'
        )}
      </p>
    </div>
  );
  return pageModel;
};

export default DeactivateAccount;
