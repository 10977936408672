import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { MobileCodeVerify } from '@pepper-ui/common';
import {
  getAccountMobileNumber,
  setAccessCodeMobile,
  getAccessCodeMobile,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';
import { checkMobileNumber, resendCode } from '../../firebase';
import formatMobileNumber from '../../utils/formatMobileNumber';

const AccessCodeMobile = () => {
  const sendDataToGTM = useGTMDispatch();
  const [success, setSuccess] = useState(false);
  const accountMobileNumberRedux = useSelector(getAccountMobileNumber);
  // const accountMobileNumberRedux = '07459 626 777';
  const accessCodeMobileRedux = useSelector(getAccessCodeMobile);
  const [hasServiceError, setHasServiceError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'SMEReg.AccessCodeMobile.s11',
    'Enter your security code - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const errorMessage = copyProvider.getCopy(
    'SMEReg.AccessCodeMobile.s8',
    'Enter a valid access code.'
  );

  const updatedAccessCodeMobile = (event) => {
    dispatch(setAccessCodeMobile(event.target.value));
  };

  let accountMobileNumberDisplay = accountMobileNumberRedux;
  if (
    accountMobileNumberDisplay.length === 10 &&
    accountMobileNumberRedux.charAt(0) !== '0'
  ) {
    accountMobileNumberDisplay = `0${accountMobileNumberDisplay}`;
  }

  const handleBtnClick = () => {
    sendDataToGTM({
      event: 'reg_access_code_mobile',
      category: 'Registration',
    });
    checkMobileNumber({ mobileOtp: accessCodeMobileRedux })
      .then(() => {
        dispatch(setAccessCodeMobile(accessCodeMobileRedux));
        setHasServiceError(false);
        history.push('/sme-reg/phone-verified');
      })
      .catch(() => {
        // TODO: add error when the server is not responding
        // TODO: add error if the code is not valid
        setHasServiceError(true);
      });
  };

  const resendText = () => {
    resendCode({ type: 'phone' })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        // TODO: Show error message
        setSuccess(true);
      });
  };

  const title = copyProvider.getCopy(
    'SMEReg.AccessCodeMobile.s9',
    'Mobile code has been sent again'
  );

  const pageModel = (
    <MobileCodeVerify
      banner={{
        title,
        body: copyProvider.getCopy(
          'SMEReg.AccessCodeMobile.s10',
          'We have sent you a new verification code via text, this may take a few minutes to arrive.'
        ),
        success,
      }}
      heading={copyProvider.getCopy(
        'SMEReg.AccessCodeMobile.s1',
        'Enter your mobile verification code'
      )}
      paragraphs={{
        mobileNumber: formatMobileNumber(accountMobileNumberDisplay),
        text1: copyProvider.getCopy(
          'SMEReg.AccessCodeMobile.s2',
          'We have sent a text message to '
        ),
        text2: copyProvider.getCopy(
          'SMEReg.AccessCodeMobile.s3',
          'with a 6 digit verification code.'
        ),
        text3: copyProvider.getCopy(
          'SMEReg.AccessCodeMobile.s4',
          'It may take a few minutes to arrive.'
        ),
      }}
      accessCodeInput={{
        label: copyProvider.getCopy(
          'SMEReg.AccessCodeMobile.s5',
          'Verification code'
        ),
        value: accessCodeMobileRedux,
        errorMessage,
        onChange: updatedAccessCodeMobile,
      }}
      button={{
        text: copyProvider.getCopy('SMEReg.AccessCodeMobile.s7', 'Continue'),
        onClick: handleBtnClick,
      }}
      notReceivedLink={{
        text: copyProvider.getCopy(
          'SMEReg.AccessCodeMobile.s6',
          'I have not received the verification code'
        ),
        onClick: () => resendText(),
        to: '/sme-reg/access-code-mobile',
      }}
      hasServiceError={hasServiceError}
    />
  );

  return pageModel;
};

export default AccessCodeMobile;
