import React from 'react';
import { useHistory } from 'react-router-dom';
import { NoOfEmployees } from '@pepper-ui/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNoOfEmployees,
  getNoOfEmployees,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { updateAccount } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const Employees = () => {
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const noOfEmployees = useSelector(getNoOfEmployees);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const dispatch = useDispatch();
  useTitle(
    'SMEReg.Employees.s1',
    'How many employees does your company have? - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const items = [
    {
      children: copyProvider.getCopy('SMEReg.Employees.s2', '0 to 9 employees'),
      value: '0 to 9',
      id: 'noOfEmployees',
    },
    {
      children: copyProvider.getCopy(
        'SMEReg.Employees.s3',
        '10 to 49 employees'
      ),
      value: '10 to 49',
    },

    {
      children: copyProvider.getCopy(
        'SMEReg.Employees.s4',
        '50 to 249 employees'
      ),
      value: '50 to 249',
    },

    {
      children: copyProvider.getCopy(
        'SMEReg.Employees.s5',
        'More than 249 employees'
      ),
      value: 'More than 249',
    },
  ];

  const onNoOfEmployeesChanged = (event) => {
    dispatch(setNoOfEmployees(event.target.value));
  };

  const onContinueBtnClicked = () => {
    updateAccount({ noOfEmployees })
      .then(() => {
        history.push('/sme-reg/check-answers');
      })
      .catch(() => {
        // TODO: Show an error message
      });
  };

  const moreThan249Employees = () => {
    history.push('/sme-reg/cannot-proceed-employees');
  };

  return (
    <NoOfEmployees
      summary={{
        title: 'There is a problem',
      }}
      heading={copyProvider.getCopy(
        'SMEReg.Employees.s6',
        'How many employees does your company have?'
      )}
      radios={{
        errorMsg: copyProvider.getCopy(
          'SMEReg.Employees.s8',
          'Select the number of employees your company has'
        ),
        items,
        onChange: onNoOfEmployeesChanged,
        value: noOfEmployees,
        condition: items[3].value,
        conditionMatched: moreThan249Employees,
      }}
      button={{
        text: copyProvider.getCopy('SMEReg.Employees.s7', 'Continue'),
        onClick: onContinueBtnClicked,
      }}
    />
  );
};

export default Employees;
