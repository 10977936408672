/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resubmitPath: '',
  resubmitClaims: [
    {
      documentId: '',
      chargepointEvidence: [],
      invoiceEvidence: [],
      carparkEvidence: [],
      diagramEvidence: [],
      grantType: '',
    },
  ],
};

export const resubmitSlice = createSlice({
  name: 'resubmit',
  initialState,
  reducers: {
    setChargepointEvidence: (state, action) => {
      const claim = state.resubmitClaims.filter(
        (c) => c.documentId === action.payload.documentId
      )[0];

      claim.chargepointEvidence = action.payload.chargepointEvidence;
      const otherClaim = state.resubmitClaims.filter(
        (c) => c.documentId !== action.payload.documentId
      );
      state.resubmitClaims = [...otherClaim, claim];
    },
    setCarparkEvidence: (state, action) => {
      const claim = state.resubmitClaims.filter(
        (c) => c.documentId === action.payload.documentId
      )[0];

      claim.carparkEvidence = action.payload.carparkEvidence;
      const otherClaim = state.resubmitClaims.filter(
        (c) => c.documentId !== action.payload.documentId
      );
      state.resubmitClaims = [...otherClaim, claim];
    },
    setDiagramEvidence: (state, action) => {
      const claim = state.resubmitClaims.filter(
        (c) => c.documentId === action.payload.documentId
      )[0];

      claim.diagramEvidence = action.payload.diagramEvidence;
      const otherClaim = state.resubmitClaims.filter(
        (c) => c.documentId !== action.payload.documentId
      );
      state.resubmitClaims = [...otherClaim, claim];
    },
    setInvoiceEvidence: (state, action) => {
      const claim = state.resubmitClaims.filter(
        (c) => c.documentId === action.payload.documentId
      )[0];
      claim.invoiceEvidence = action.payload.invoiceEvidence;
      state.resubmitClaims = [...state.resubmitClaims, claim];
    },
    setResubmitClaim: (state, action) => {
      state.resubmitClaims = action.payload;
    },
  },
});

export const {
  setChargepointEvidence,
  setInvoiceEvidence,
  setResubmitClaim,
  setCarparkEvidence,
  setDiagramEvidence,
} = resubmitSlice.actions;

export const getResubmitClaims = (state) => state.resubmit.resubmitClaims;

export default resubmitSlice.reducer;
