// eslint-disable-next-line import/prefer-default-export
export const exist = (arr, children) => {
  // eslint-disable-next-line func-names
  return arr.some(function (el) {
    return el.children === children;
  });
};

export const removeItem = (arr, value) => {
  let i = 0;
  while (i < arr.length) {
    if (arr[i].children === value) {
      arr.splice(i, 1);
    } else {
      // eslint-disable-next-line no-plusplus
      ++i;
    }
  }
  return arr;
};

export const removeAllItem = (arr, arrValues) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const element of arrValues) {
    removeItem(arr, element);
  }
  return arr;
};
