import axios from 'axios';
import fileDownload from 'js-file-download';
import { downloadFiles } from '../firebase';

const downloadFilesFromUrl = async (data) => {
  const response = await downloadFiles({
    download: data,
  });

  response.data.filesURL.forEach((file) => {
    axios
      .get(file.imageURL, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, file.imageName);
      })
      .catch(() => {
        // TODO:
      });
  });
};

export default downloadFilesFromUrl;
