import React, { useState } from 'react';
import { CookieBanner } from 'govuk-react-jsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import useGaEvents from '../hooks/useGaEvents';
import { saveAnalyticsCookie, saveLanguageCookie } from '../utils/helpers';
import {
  setCurrentLanguage,
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';

function CookiesBanner() {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);

  const [showCookiesBanner, setShowCookiesBanner] = useState(
    !document.cookie.includes('evcgAnalytics')
  );

  // Copyprovider
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const analyticsSelection = (e, hasAccepted) => {
    e.preventDefault();

    const ga4Id = process.env.REACT_APP_GA_MEASUREMENT_ID;
    document.getElementById('cookies-banner').style.display = 'none';
    if (hasAccepted) {
      const gaEvents = useGaEvents();
      gaEvents(
        'Internal link click',
        `language changed to ${currentLanguage === 'en' ? 'CY' : 'EN'}`,
        'footer link'
      );
      document.getElementById('cookies-accept').removeAttribute('hidden');
      // *****************************************************************************
      // Component is rendered twice when using strict mode in DEVELOPMENT environment
      // so ReactGA.send() is trigger twice for every page when running in DEVELOPMENT

      // we could send custom paths rather than pathname i.e. we could map pathnames
      // to something like '/procedure-name/step-1', '/procedure-name/step-2' and use
      // ReactGA.send({ hitType: 'pageview', page: '/custom-path-name' });
      // *****************************************************************************

      window[`ga-disable-${ga4Id}`] = false;
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    } else {
      window[`ga-disable-${ga4Id}`] = true;
      document.getElementById('cookies-reject').removeAttribute('hidden');
    }
    saveAnalyticsCookie(hasAccepted);
    if (
      currentLanguage === 'en' ||
      currentLanguage === undefined ||
      currentLanguage === null
    ) {
      dispatch(setCurrentLanguage('en'));
      saveLanguageCookie('lang=en');
    } else {
      dispatch(setCurrentLanguage('cy'));
      saveLanguageCookie('lang=cy');
    }
  };

  const handleHide = () => {
    setShowCookiesBanner(false);
  };

  return (
    showCookiesBanner && (
      <CookieBanner
        aria-label="Cookie banner"
        messages={[
          {
            actions: [
              {
                children: copyProvider.getCopy(
                  'CookiesBanner.s1',
                  'Accept additional cookies'
                ),
                name: 'cookies',
                type: 'button',
                value: 'accept',
                onClick: (e) => analyticsSelection(e, true),
              },
              {
                children: copyProvider.getCopy(
                  'CookiesBanner.s2',
                  'Reject additional cookies'
                ),
                name: 'cookies',
                type: 'button',
                value: 'reject',
                onClick: (e) => analyticsSelection(e, false),
              },
              {
                children: copyProvider.getCopy(
                  'CookiesBanner.s3',
                  'View cookies'
                ),
                href: '/cookies',
              },
            ],
            id: 'cookies-banner',
            children: (
              <>
                <p>
                  {copyProvider.getCopy(
                    'CookiesBanner.s4',
                    'We use some essential cookies to make this service work.'
                  )}
                </p>
                <p>
                  {copyProvider.getCopy(
                    'CookiesBanner.s5',
                    'We’d also like to use additional cookies so we can understand how you use the service and make improvements.'
                  )}
                </p>
              </>
            ),
            headingChildren: copyProvider.getCopy(
              'CookiesBanner.s6',
              'Cookies on OZEV Electric Vehicle Chargepoint Grants'
            ),
          },
          {
            actions: [
              {
                children: copyProvider.getCopy(
                  'CookiesBanner.s7',
                  'Hide this message'
                ),
                type: 'button',
                className: 'govuk-!-margin-top-4',
                onClick: handleHide,
              },
            ],
            id: 'cookies-accept',
            hidden: true,
            children: (
              <>
                {copyProvider.getCopy(
                  'CookiesBanner.s8',
                  'You’ve accepted additional cookies. You can '
                )}
                <Link className="govuk-link" to="/cookies">
                  {copyProvider.getCopy(
                    'CookiesBanner.s9',
                    'change your cookie settings'
                  )}
                </Link>
                {copyProvider.getCopy('CookiesBanner.s10', ' at any time.')}
              </>
            ),
            role: 'alert',
          },
          {
            actions: [
              {
                children: copyProvider.getCopy(
                  'CookiesBanner.s7',
                  'Hide this message'
                ),
                type: 'button',
                className: 'govuk-!-margin-top-4',
                onClick: handleHide,
              },
            ],
            id: 'cookies-reject',
            hidden: true,
            children: (
              <>
                {copyProvider.getCopy(
                  'CookiesBanner.s11',
                  'You’ve rejected additional cookies. You can '
                )}
                <Link className="govuk-link" to="/cookies">
                  {copyProvider.getCopy(
                    'CookiesBanner.s9',
                    'change your cookie settings'
                  )}
                </Link>
                {copyProvider.getCopy('CookiesBanner.s10', ' at any time.')}
              </>
            ),
            role: 'alert',
          },
        ]}
      />
    )
  );
}

export default CookiesBanner;
