import isEmpty from 'lodash/isEmpty';
import result from 'lodash/result';
import toUpper from 'lodash/toUpper';
import toLower from 'lodash/toLower';
import template from 'lodash/template';

class CopyProvider {
  constructor(lang = 'en', resources = {}, resourceType = 'nested') {
    this.resources = resources[lang];
    this.lang = lang;
    this.resourceType = resourceType;
  }

  static getValue(
    locals = {},
    tKey = '',
    resourceType = 'nested',
    fallback = ''
  ) {
    if (!isEmpty(locals) && !isEmpty(tKey)) {
      if (resourceType === 'nested') {
        return result(locals, tKey, fallback);
      }
      return locals[tKey] || fallback;
    }
    // console.warn('Please provide copy object and key to getValue');
    return tKey;
  }

  getResources() {
    return this.resources;
  }

  getLocale() {
    return this.lang;
  }

  static transform(transform = '', resource = '') {
    if (isEmpty(resource)) {
      return resource;
    }
    switch (transform) {
      case 'uppercase':
        return toUpper(resource);
      case 'lowercase':
        return toLower(resource);
      default:
        // eslint-disable-next-line no-console
        console.warn(`Transformation for ${transform} is not available`);
        return resource;
    }
  }

  getCopy(tKey = '', fallback = '', attr = {}, transform = '') {
    let keyValue = CopyProvider.getValue(
      this.resources,
      tKey,
      this.resourceType,
      fallback
    );

    if (!isEmpty(attr)) {
      keyValue = template(keyValue)(attr);
    }
    if (!isEmpty(transform)) {
      keyValue = CopyProvider.transform(transform, keyValue);
    }
    return keyValue;
  }
}

export default CopyProvider;
