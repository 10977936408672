const formatValue = (value, fmt) => {
  const { format, prefix, leadingStringKey, leadingStringValue } = fmt;

  let formatted = '';
  let startIndex = 0;

  for (let i = 0; i < format.length; i += 1) {
    const length = format[i] === null ? value.length : format[i];
    formatted += `${
      i === leadingStringKey ? leadingStringValue : ''
    }${value.substr(startIndex, length)} `;
    startIndex += length;
  }

  formatted = `${prefix || ''}${formatted}`;

  return formatted.trim();
};

export const formatMobileNumber = (localNumber, countryPrefix = 44) => {
  const formatted = Number(localNumber.toString().replace(/\s/g, ''));

  return formatValue(`${countryPrefix}${formatted}`, {
    // EXAMPLE: "+44 #### ### ###",
    prefix: '+',
    format: [2, 4, 3, null],

    leadingStringValue: '(0)',
    leadingStringKey: 1,
  });
};

export const formatTelephoneNumber = (localNumber, countryPrefix = 44) => {
  const formatted = Number(localNumber.toString().replace(/\s/g, ''));

  return formatValue(`${countryPrefix}${formatted}`, {
    // EXAMPLE: "+44 ## #### ####",
    prefix: '+',
    format: [2, 2, 4, null],
    leadingStringValue: '(0)',
    leadingStringKey: 1,
  });
};
