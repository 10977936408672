import React from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';

const useTitle = (tag, title) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  React.useEffect(() => {
    const text = copyProvider.getCopy(tag, title);
    const prevTitle = document.title;
    document.title = text;

    return () => {
      document.title = prevTitle;
    };
  });
};

export default useTitle;
