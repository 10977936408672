import React, { useState } from 'react';
import { Button, Table, Radios } from 'govuk-react-jsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { saveAnalyticsCookie } from '../utils/helpers';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';

const Cookies = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const [evcgAnalyticsSelection, setEvcgAnalyticsSelection] = useState(
    document.cookie.includes('evcgAnalytics=true') ? 'yes' : 'no'
  );

  const handleCookieSelection = (e) => {
    setEvcgAnalyticsSelection(e.target.value);
  };

  const handleCookieSave = () => {
    const hasAccepted = evcgAnalyticsSelection === 'yes';
    const ga4Id = process.env.REACT_APP_GA_MEASUREMENT_ID;

    // GA4 cookies update
    // TODO: needs to be tested thoroughly with prod version of REACT_APP_GA_MEASUREMENT_ID
    if (hasAccepted) {
      window[`ga-disable-${ga4Id}`] = false;
      ReactGA.initialize(ga4Id);
    } else {
      window[`ga-disable-${ga4Id}`] = true;
    }

    // evcgAnalytics cookie update
    saveAnalyticsCookie(hasAccepted);
  };

  const page = (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy('Cookies.s1', 'Cookies')}
      </h1>
      {copyProvider.getCopy(
        'Cookies.s2',
        'Cookies are files saved on your phone, tablet or computer when you visit a website.'
      )}
      <p>
        {copyProvider.getCopy(
          'Cookies.s3',
          'We use cookies to collect information about how you use our service. We use this information to make the website work as well as possible and improve our services.'
        )}
      </p>
      <p>
        {copyProvider.getCopy(
          'Cookies.s4',
          'Our cookies aren’t used to identify you personally.'
        )}
      </p>
      <p>
        {copyProvider.getCopy('Cookies.s5', 'Find out more about ')}
        <Link
          to={{ pathname: 'https://www.aboutcookies.org/' }}
          target="_blank"
          className="govuk-link"
        >
          {copyProvider.getCopy('Cookies.s6', 'how to manage cookies.')}
        </Link>
      </p>

      <h2 className="govuk-heading-m govuk-!-margin-top-7">
        {copyProvider.getCopy('Cookies.s7', 'Analytics cookies (optional)')}
      </h2>
      <p>
        {copyProvider.getCopy(
          'Cookies.s8',
          'With your permission we use Google Analytics to collect data about how you use this service. This information helps us to improve our service.'
        )}
      </p>
      <p>
        {copyProvider.getCopy(
          'Cookies.s9',
          'Google Analytics is not allowed to use or share our analytics data with anyone.'
        )}
      </p>

      <Table
        className="govuk-summary-list govuk-!-margin-bottom-9"
        head={[
          {
            children: copyProvider.getCopy('Cookies.s10', 'Name'),
          },
          {
            children: copyProvider.getCopy('Cookies.s11', 'Purpose'),
          },
          {
            children: copyProvider.getCopy('Cookies.s12', 'Expires'),
          },
        ]}
        rows={[
          {
            cells: [
              {
                children: '_ga',
              },
              {
                children: copyProvider.getCopy(
                  'Cookies.s28',
                  'Used to distinguish users.'
                ),
              },
              {
                children: copyProvider.getCopy('Cookies.s29', '2 years'),
              },
            ],
          },
          {
            cells: [
              {
                // TODO: update with DfT - Google GA4 container-id
                children: '_ga_<container-id>',
              },
              {
                children: copyProvider.getCopy(
                  'Cookies.s31',
                  'Used to persist session state.'
                ),
              },
              {
                children: copyProvider.getCopy('Cookies.s29', '2 years'),
              },
            ],
          },
        ]}
      />

      <Radios
        className="govuk-radios--inline"
        fieldset={{
          legend: {
            className: 'govuk-fieldset__legend govuk-fieldset__legend--s',
            children: copyProvider.getCopy(
              'Cookies.s13',
              'Do you want to accept analytics cookies?'
            ),
          },
        }}
        idPrefix="cookies-accept"
        onChange={handleCookieSelection}
        items={[
          {
            children: copyProvider.getCopy('Cookies.s24', 'Yes'),
            value: 'yes',
          },
          {
            children: copyProvider.getCopy('Cookies.s25', 'No'),
            value: 'no',
          },
        ]}
        name="example"
        value={evcgAnalyticsSelection}
      />

      <Button onClick={handleCookieSave}>
        {copyProvider.getCopy('Cookies.s14', 'Save cookie settings')}
      </Button>

      <h2 className="govuk-heading-m govuk-!-margin-top-7">
        {copyProvider.getCopy('Cookies.s15', 'Esssential cookies')}
      </h2>
      <p>
        {copyProvider.getCopy(
          'Cookies.s16',
          'Esssential cookies keep your information secure while you use this service. We do not need permission to use them.'
        )}
      </p>

      <Table
        className="govuk-summary-list govuk-!-margin-bottom-9"
        head={[
          {
            children: copyProvider.getCopy('Cookies.s10', 'Name'),
          },
          {
            children: copyProvider.getCopy('Cookies.s11', 'Purpose'),
          },
          {
            children: copyProvider.getCopy('Cookies.s12', 'Expires'),
          },
        ]}
        rows={[
          {
            cells: [
              {
                children: 'evcgAnalytics',
              },
              {
                children: copyProvider.getCopy(
                  'Cookies.s26',
                  'This cookie is used to record if a user has accepted the use of analytics cookies. Setting this cookie means that we will not have to keep asking your permission to use analytics cookies every time you visit this site. No personal information is stored.'
                ),
              },
              {
                children: copyProvider.getCopy('Cookies.s27', '1 year'),
              },
            ],
          },
        ]}
      />

      <h2 className="govuk-heading-m govuk-!-margin-top-7">
        {copyProvider.getCopy('Cookies.s32', 'Language Selection')}
      </h2>
      <p>
        {copyProvider.getCopy(
          'Cookies.s33',
          'A cookie is stored to ensure the website displays text in your chosen language.'
        )}
      </p>

      <Table
        className="govuk-summary-list govuk-!-margin-bottom-9"
        head={[
          {
            children: copyProvider.getCopy('Cookies.s10', 'Name'),
          },
          {
            children: copyProvider.getCopy('Cookies.s11', 'Purpose'),
          },
          {
            children: copyProvider.getCopy('Cookies.s12', 'Expires'),
          },
        ]}
        rows={[
          {
            cells: [
              {
                children: 'lang',
              },
              {
                children: copyProvider.getCopy(
                  'Cookies.s34',
                  'This stores whether you have chosen to see content in English or Welsh.'
                ),
              },
              {
                children: copyProvider.getCopy('Cookies.s27', '1 year'),
              },
            ],
          },
        ]}
      />

      <h2 className="govuk-heading-m govuk-!-margin-top-7">
        {copyProvider.getCopy('Cookies.s17', 'Third-party cookies')}
      </h2>
      <p>
        {copyProvider.getCopy(
          'Cookies.s18',
          'Esssential cookies keep your information secure while you use this service. We do not need permission to use them.'
        )}
      </p>

      <h3 className="govuk-heading-s">
        {copyProvider.getCopy('Cookies.s19', 'SmartSurvey')}
      </h3>
      <p>
        {copyProvider.getCopy('Cookies.s20', 'We use ')}
        <Link
          to={{ pathname: 'https://www.smartsurvey.co.uk/' }}
          target="_blank"
          className="govuk-link"
        >
          {copyProvider.getCopy('Cookies.s19', 'SmartSurvey')}
        </Link>
        {copyProvider.getCopy(
          'Cookies.s21',
          ' to get feedback about service satisfaction. If you take part, SmartSurvey '
        )}
        <Link
          to={{ pathname: 'https://www.smartsurvey.co.uk/how-we-use-cookies' }}
          target="_blank"
          className="govuk-link"
        >
          {copyProvider.getCopy('Cookies.s22', 'will save extra cookies ')}
        </Link>
        {copyProvider.getCopy(
          'Cookies.s23',
          'to track your progress on their website.'
        )}
      </p>
    </>
  );
  return <div className="govuk-width-container govuk-body">{page}</div>;
};

export default Cookies;
