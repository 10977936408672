import React from 'react';
import { SummaryList } from 'govuk-react-jsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import convertDateToDisplay from '../../utils/convertDateToDisplay';
import downloadFilesFromUrl from '../../utils/downloadFiles';

const InstallationDetails = ({ claimData }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const isCarParksGrant =
    claimData.grant_type === 'Residential car park' ||
    claimData.grant_type === 'Commercial car park' ||
    claimData.grant_type === 'Staff and fleet car park';

  const whoCompletedInstallation = () => {
    if (
      claimData.installation_done_by === 'employee' ||
      claimData.subcontractor_id === ''
    ) {
      return (
        <>
          <section>{claimData.installer_company_name}</section>
          <section>{claimData.installer_id}</section>
        </>
      );
    }
    // If installer is Subcontractor
    return (
      <>
        <section>
          {copyProvider.getCopy(
            'Installer.Resubmit.InstallationDetails.s5',
            'Subcontractor'
          )}
        </section>
        <section>{claimData.subcontractor_company_name}</section>
        <section>{claimData.subcontractor_id}</section>
      </>
    );
  };

  const address = (
    <>
      <section>
        {claimData.addressLine1 && `${claimData.addressLine1},`}
      </section>
      <section>
        {claimData.addressLine2 && `${claimData.addressLine2},`}
      </section>
      <section>{claimData.city && `${claimData.city},`}</section>
      <section>{claimData.county && `${claimData.county},`}</section>
      <section>{claimData.postCode && claimData.postCode}</section>
    </>
  );

  const numberOfChargepoints = () => {
    if (claimData.grant_type !== 'Renters and flat owners') {
      return copyProvider.getCopy(
        'Installer.Resubmit.InstallationDetails.s6',
        'Number of chargepoints'
      );
    }
    return '';
  };

  const numberOfChargepointSockets = () => {
    if (claimData.grant_type !== 'Renters and flat owners') {
      return copyProvider.getCopy(
        'Installer.Resubmit.InstallationDetails.s7',
        'Number of chargepoint sockets'
      );
    }
    return '';
  };

  const numberOfParkingSpaces = () => {
    if (isCarParksGrant) {
      return copyProvider.getCopy(
        'Installer.Resubmit.InstallationDetails.s8',
        'Infrastructure supported EV parking spaces'
      );
    }
    return '';
  };

  const carparkDiagram = () => {
    if (isCarParksGrant) {
      const diagramEvidenceDownload =
        claimData.schematic_evidence.length > 0
          ? claimData.schematic_evidence.map((e) => {
              if (claimData?.grant_type === 'Renters and flat owners') {
                return {
                  imageName: e.image_id,
                  imagePath: e.image,
                };
              }
              return {
                imageName: e.schematic_id,
                imagePath: e.schematic_link,
              };
            })
          : [];

      const diagrams = claimData.schematic_evidence.map((diagram) => {
        return <section>{diagram.schematic_id}</section>;
      });
      return (
        <>
          {diagrams}
          <Link
            to={{}}
            onClick={() => downloadFilesFromUrl(diagramEvidenceDownload)}
          >
            {copyProvider.getCopy(
              'Installer.Resubmit.ChargepointDetails.s8',
              'Download diagrams'
            )}
          </Link>
        </>
      );
    }
    return '';
  };

  return (
    <>
      <h1 className="govuk-heading-m govuk-!-margin-top-5">
        {copyProvider.getCopy(
          'Installer.Resubmit.InstallationDetails.s1',
          'Installation details'
        )}
      </h1>

      <SummaryList
        className="govuk-!-margin-bottom-9"
        rows={[
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.InstallationDetails.s2',
                'Address'
              ),
            },
            value: {
              children: address,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.InstallationDetails.s3',
                'Who completed the installation'
              ),
            },
            value: {
              children: whoCompletedInstallation(),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.InstallationDetails.s4',
                'Date installations were completed'
              ),
            },
            value: {
              children: convertDateToDisplay(
                claimData.installation_completed_date
              ),
            },
          },
          numberOfChargepoints &&
            claimData.number_of_chargepoints_applied && {
              key: {
                children: numberOfChargepoints(),
              },
              value: {
                children: claimData.number_of_chargepoints_applied,
              },
            },
          numberOfChargepointSockets &&
            claimData.number_of_chargepoint_sockets_applied && {
              key: {
                children: numberOfChargepointSockets(),
              },
              value: {
                children: claimData.number_of_chargepoint_sockets_applied,
              },
            },
          numberOfParkingSpaces &&
            claimData.number_of_parking_spaces_applied && {
              key: {
                children: numberOfParkingSpaces(),
              },
              value: {
                children: claimData.number_of_parking_spaces_applied,
              },
            },
          carparkDiagram && {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.InstallationDetails.s9',
                'Car park diagram'
              ),
            },
            value: {
              children: carparkDiagram(),
            },
          },
        ]}
      />
    </>
  );
};

InstallationDetails.propTypes = {
  claimData: PropTypes.object.isRequired,
};
export default InstallationDetails;
