import React from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { setResubmitClaimStartDate } from '../features/ga/gaSlice';

const useGaStartEvent = (journeyCategory, eventName) => {
  const sendDataToGTM = useGTMDispatch();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const startDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    if (journeyCategory.trim() === 'ResubmitClaim')
      dispatch(setResubmitClaimStartDate(startDate));

    sendDataToGTM({
      event: eventName,
      category: journeyCategory,
    });
  }, []);
  return () => {};
};

export default useGaStartEvent;
