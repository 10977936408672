import React, { useEffect } from 'react';
import { Button, Panel } from 'govuk-react-jsx';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  setDisplayGoBackLink,
} from '../../../features/app/appSlice';
import { getClaimsData } from '../../../features/customer/customerJourneySlice';
import useTitle from '../../../hooks/useTitle';
import useGaEndEvent from '../../../hooks/useGaEndEvent';

const ResubmitConfirm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => dispatch(setDisplayGoBackLink(false)), []);
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const history = useHistory();
  const claimsValue = useSelector(getClaimsData);
  const claimData = claimsValue.filter((c) => c.document_id === id)[0];

  useTitle(
    'Installer.Resubmit.ConfirmResubmit.s11',
    'Claim resubmitted - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  useGaEndEvent('ResubmitClaim', 'resubmit_claim_end');

  const headingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s1',
    'We will now review your resubmitted claim'
  );
  const subHeadingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s2',
    'Your reference number: '
  );
  const secondHeadingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s3',
    'What happens next'
  );
  const firstPtxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s4',
    'The Office for Zero Emission Vehicles (OZEV) will now assess your claim.'
  );
  const secondPtxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s5',
    'We will aim to tell you, within 20 working days, whether your claim is successful, or to ask you for more information.'
  );
  const thirdPtxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s6',
    'You can check the progress of your claim on your manage your claims page.'
  );
  const btnTxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s9',
    'Manage your claims'
  );
  const linkTxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s7',
    'What did you think of this service?'
  );
  const fourthPtxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s8',
    '(takes 30 seconds)'
  );
  const thirdHeadingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ConfirmResubmit.s10',
    'Help to make this service better'
  );

  const handleBtnCLick = () => {
    history.push('/installer/claim-grant/installer-dashboard');
  };
  return (
    <>
      <Panel titleChildren={headingTxt}>
        {subHeadingTxt}
        <br /> <strong>{claimData.customerReferenceNumber}</strong>
      </Panel>
      <h2 className="govuk-heading-m">{secondHeadingTxt}</h2>
      <p className="govuk-body">{firstPtxt}</p>
      <p className="govuk-body">{secondPtxt}</p>
      <p className="govuk-body">{thirdPtxt}</p>
      <Button onClick={handleBtnCLick}>{btnTxt}</Button>
      <h3 className="govuk-heading-s govuk-!-margin-top-2">
        {thirdHeadingTxt}
      </h3>
      <p className="govuk-body">
        <Link
          href="https://www.smartsurvey.co.uk/s/EV-grants/"
          target="_blank"
          rel="noopener noreferrer"
          className="govuk-link"
        >
          {linkTxt}
        </Link>
        {fourthPtxt}
      </p>
    </>
  );
};

export default ResubmitConfirm;
