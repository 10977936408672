import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { PhaseBanner, BackLink } from 'govuk-react-jsx';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import queryString from 'query-string';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Template from './components/Template';
import CookiesBanner from './components/CookiesBanner';
import RoutesTree from './RoutesTree';
import {
  setCurrentLanguage,
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  getDisplayGoBackLink,
  getUserLockedStatus,
  setTokenCount,
  getTokenCount,
} from './features/app/appSlice';
import { AuthProvider } from './AuthContext';
import { auth, getAccount } from './firebase';
import { saveLanguageCookie } from './utils/helpers';
import hideMenu from './hooks/hideMenu';

// TODO: ensure there are no debugger statements in the code. no-debugger was changed to eslint warning only

// TODO: ! set DEBUG = false for PROD // toogle DEBUG flag enables/disables console.log function
let DEBUG = true;
if (process.env.NODE_ENV === 'development') {
  DEBUG = true;
}

if (!DEBUG) {
  // eslint-disable-next-line no-console
  console.log = () => {};
}

function App() {
  const gtmParams = { id: process.env.REACT_APP_GA_GTM };
  const { search, pathname } = useLocation();
  const values = queryString.parse(search);
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const userLockedStatus = useSelector(getUserLockedStatus);
  const tokenCount = useSelector(getTokenCount);
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(false);
  const [dashboardLink, setDashboardLink] = useState('');
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  let myInterval;
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const displayBackLink = useSelector(getDisplayGoBackLink);

  const MAX_TOKEN_COUNT_REFRESH = 20;

  const MILLISECS_FOR_SET_INTERVAL = 3540000;

  hideMenu();
  useEffect(() => {
    if (document.cookie.includes('lang=en')) {
      dispatch(setCurrentLanguage('en'));
    } else if (document.cookie.includes('lang=cy')) {
      dispatch(setCurrentLanguage('cy'));
    } else {
      document.cookie = '_ga_0700LVV1G5=; Max-Age=-99999999;';
    }

    onAuthStateChanged(auth, (user) => {
      if (
        user !== null &&
        !isLoggedOut &&
        tokenCount <= MAX_TOKEN_COUNT_REFRESH
      ) {
        myInterval = setInterval(() => {
          user?.getIdToken(true).then(() => {
            // eslint-disable-next-line no-console
            setTokenCount(tokenCount + 1);
          });
        }, MILLISECS_FOR_SET_INTERVAL);
      } else {
        clearInterval(myInterval);
      }

      setCurrentUser(user);
      if (user !== null) {
        getAccount()
          .then((response) => {
            const { data } = response;
            if (data?.account_type === 'Property Management Company') {
              setDashboardLink('/dashboardHub/dashboard-pmc');
            } else if (data?.account_type === 'Landlord') {
              setDashboardLink('/dashboardHub/dashboard');
            } else if (data?.account_type === 'Installer') {
              setDashboardLink('/installer/claim-grant/installer-dashboard');
            } else if (data?.account_type === 'Small to medium enterprise') {
              setDashboardLink('/sme-app/dashboard2');
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.debug('Get Account error:', err.message);
            throw new Error(err);
          });
      }
    });
    return () => clearInterval(myInterval);
  }, [isLoggedOut]);

  const fullWidthScreens = [
    '/installer/claim-grant/installer-dashboard',
    '/signin',
    '/dashboardHub/dashboard',
    '/dashboardHub/your-account',
  ];
  const isFullWidthScreen = fullWidthScreens.includes(pathname)
    ? 'govuk-grid-column-full'
    : 'govuk-grid-column-two-thirds';

  if (values.lang && values.lang !== currentLanguage) {
    dispatch(setCurrentLanguage(values.lang));
  }

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        // TODO: call a signout API
        setIsLoggedOut(true);
        history.push('/signin');
      })
      .catch(() => {
        // TODO: Show error message
      });
  };

  const headerProps =
    !currentUser || !currentUser.emailVerified || userLockedStatus
      ? {
          serviceName: copyProvider.getCopy(
            'App.s1',
            'OZEV Electric Vehicle Chargepoint Grants'
          ),
          serviceUrlHref: '/signin',
          navigation: [],
        }
      : {
          serviceName: copyProvider.getCopy(
            'App.s1',
            'OZEV Electric Vehicle Chargepoint Grants'
          ),
          serviceUrlHref: dashboardLink,
          navigation: [
            {
              active: dashboardLink && true,
              children: (
                <Link className="govuk-header__link" to={dashboardLink}>
                  {copyProvider.getCopy('App.s2', 'Dashboard')}
                </Link>
              ),
            },
            {
              active:
                window.location.pathname === '/dashboardHub/your-account' &&
                true,
              children: (
                <Link
                  className="govuk-header__link"
                  to="/dashboardHub/your-account"
                >
                  {copyProvider.getCopy('App.s3', 'Your account')}
                </Link>
              ),
            },
            {
              children: (
                <Link
                  className="govuk-header__link"
                  onClick={handleSignout}
                  to={{}}
                >
                  {copyProvider.getCopy('App.s4', 'Sign out')}
                </Link>
              ),
            },
          ],
        };

  const handleBtnClick = (event) => {
    event.preventDefault();
    history.goBack();
  };

  const beforeContentProps = (
    <>
      <PhaseBanner tag={{ children: 'beta' }}>
        {copyProvider.getCopy('App.s5', 'This is a new service - ')}
        <a
          href="https://www.smartsurvey.co.uk/s/EV-grants/"
          target="_blank"
          rel="noopener noreferrer"
          className="govuk-link"
        >
          {copyProvider.getCopy('App.s6', 'your feedback ')}
        </a>

        {copyProvider.getCopy('App.s7', 'to help us to improve this service.')}
      </PhaseBanner>
      {displayBackLink && (
        <BackLink onClick={handleBtnClick}>
          {copyProvider.getCopy('App.s8', 'Back')}
        </BackLink>
      )}
    </>
  );

  const footerProps = {
    meta: {
      children: [
        <Link
          key={1}
          className="govuk-footer__link app-footer-links"
          to="/cookies"
        >
          {copyProvider.getCopy('App.s9', 'Cookies')}
        </Link>,
        <Link
          key={2}
          className="govuk-footer__link app-footer-links"
          to="/evcg/privacy-policy"
          target="_blank"
        >
          {copyProvider.getCopy('App.s10', 'Privacy')}
        </Link>,
        <Link
          key={3}
          className="govuk-footer__link app-footer-links"
          to="/evcg/accessibility-statement"
          target="_blank"
        >
          {copyProvider.getCopy('App.s11', 'Accessibility statement')}
        </Link>,
        <Link
          key={4}
          className="govuk-footer__link app-footer-links"
          to="/evcg/terms-and-conditions"
          target="_blank"
        >
          {copyProvider.getCopy('App.s12', 'Terms and conditions')}
        </Link>,
        <Link
          key={5}
          className="govuk-footer__link app-footer-links"
          lang={currentLanguage === 'en' ? 'cy' : 'en'}
          onClick={() => {
            const selectedLanguage = currentLanguage === 'en' ? 'cy' : 'en';
            saveLanguageCookie(selectedLanguage);
            dispatch(setCurrentLanguage(selectedLanguage));
          }}
          to={{}}
        >
          {copyProvider.getCopy('App.s13', 'Cymraeg')}
        </Link>,
      ],
    },
  };

  return (
    <GTMProvider state={gtmParams}>
      <AuthProvider value={{ currentUser }}>
        <CookiesBanner />
        <Template
          header={headerProps}
          beforeContent={beforeContentProps}
          footer={footerProps}
        >
          <div className="govuk-grid-row">
            <div className={isFullWidthScreen}>
              <RoutesTree />
            </div>
          </div>
        </Template>
      </AuthProvider>
    </GTMProvider>
  );
}

export default App;
