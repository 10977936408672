import React, { useState } from 'react';
import { Button, Input, ErrorSummary } from 'govuk-react-jsx';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';
import { checkValidPassword } from '../regex/regex';
import { changePassword } from '../firebase';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [hasRepeatError, setHasRepeatError] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const { encodedEmail, encodedOtp } = useParams();

  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const history = useHistory();

  // Error messages
  const blankPasswordErrorMsg = copyProvider.getCopy(
    'ResetPassword.s6',
    'Enter a password'
  );
  const invalidPasswordErrorMsg = copyProvider.getCopy(
    'ResetPassword.s7',
    'Create a password that meets the above mentioned criteria'
  );
  const blankRepeatPasswordErrorMsg = copyProvider.getCopy(
    'ResetPassword.s6',
    'Enter a password'
  );
  const invalidRepeatPasswordErrorMsg = copyProvider.getCopy(
    'ResetPassword.s7',
    'Create a password that meets the above mentioned criteria'
  );
  const passwordsDontMatchErrorMsg = copyProvider.getCopy(
    'ResetPassword.s8',
    "Passwords don't match"
  );

  // Handle input functions
  const handleCreatePasswordInput = (event) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatPasswordInput = (event) => {
    setRepeatPassword(event.target.value);
  };

  // Handle button click function
  const handleButtonClick = () => {
    const showBlankPasswordError = {
      children: blankPasswordErrorMsg,
    };

    const showInvalidPasswordError = {
      children: invalidPasswordErrorMsg,
    };

    const showBlankRepeatPasswordError = {
      children: blankRepeatPasswordErrorMsg,
    };

    const showInvalidRepeatPasswordError = {
      children: invalidRepeatPasswordErrorMsg,
    };

    const showPasswordsDontMatchError = {
      children: passwordsDontMatchErrorMsg,
    };

    // Password validation
    if (newPassword === '') {
      setErrorMsg(showBlankPasswordError);
      setHasPasswordError(true);
      setHasRepeatError(false);
      setErrorList([
        { ...showBlankPasswordError, href: '#input-with-password' },
      ]);
    } else if (!checkValidPassword.test(newPassword)) {
      setErrorMsg(showInvalidPasswordError);
      setHasPasswordError(true);
      setHasRepeatError(false);
      setErrorList([
        { ...showInvalidPasswordError, href: '#input-with-password' },
      ]);
    } else if (repeatPassword === '') {
      setErrorMsg(showBlankRepeatPasswordError);
      setHasPasswordError(false);
      setHasRepeatError(true);
      setErrorList([
        {
          ...showBlankRepeatPasswordError,
          href: '#input-with-repeat-password',
        },
      ]);
    } else if (!checkValidPassword.test(repeatPassword)) {
      setErrorMsg(showInvalidRepeatPasswordError);
      setHasPasswordError(false);
      setHasRepeatError(true);
      setErrorList([
        {
          ...showInvalidRepeatPasswordError,
          href: '#input-with-repeat-password',
        },
      ]);
    } else if (newPassword !== repeatPassword) {
      setErrorMsg(showPasswordsDontMatchError);
      setHasPasswordError(false);
      setHasRepeatError(true);
      setErrorList([
        {
          ...showPasswordsDontMatchError,
          href: '#input-with-repeat-password',
        },
      ]);
    } else {
      changePassword({
        email: encodedEmail,
        email_otp: encodedOtp,
        new_password: Buffer.from(newPassword).toString('base64'),
      })
        .then(() => {
          // Redirect to EVCG-453
          history.push('/password-updated-confirmation');
        })
        .catch(() => {});
    }
  };
  const pageModel = (
    <div>
      {errorList.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summery',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy('ResetPassword.s1', 'Create new password')}
      </h1>
      <Input
        errorMessage={hasPasswordError ? errorMsg : null}
        className="govuk-input--width-20"
        onChange={handleCreatePasswordInput}
        hint={{
          children: [
            <div className="govuk-!-margin-bottom-1">
              {copyProvider.getCopy('ResetPassword.s3', 'Your password must:')}
            </div>,
            <ul className="govuk-list govuk-list--bullet govuk-hint">
              <li>
                {copyProvider.getCopy(
                  'ResetPassword.s3a',
                  'be at least 8 characters or longer'
                )}
              </li>
              <li>
                {copyProvider.getCopy(
                  'ResetPassword.s3b',
                  'include at least 1 uppercase letter'
                )}
              </li>
              <li>
                {copyProvider.getCopy(
                  'ResetPassword.s3c',
                  'include at least 1 lowercase letter'
                )}
              </li>
              <li>
                {copyProvider.getCopy(
                  'ResetPassword.s3d',
                  'include at least 1 number'
                )}
              </li>
              <li>
                {copyProvider.getCopy('ResetPassword.s3e', 'include a symbol')}
              </li>
            </ul>,
          ],
        }}
        id="input-with-password"
        label={{
          children: copyProvider.getCopy(
            'ResetPassword.s2',
            'Create a password'
          ),
        }}
        name="create-password"
        type="password"
      />
      <Input
        errorMessage={hasRepeatError ? errorMsg : null}
        className="govuk-input--width-20"
        onChange={handleRepeatPasswordInput}
        id="input-with-repeat-password"
        label={{
          children: copyProvider.getCopy(
            'ResetPassword.s4',
            'Repeat your password'
          ),
        }}
        name="repeat-password"
        type="password"
      />
      <Button onClick={handleButtonClick}>
        {copyProvider.getCopy('ResetPassword.s5', 'Continue')}
      </Button>
    </div>
  );
  return pageModel;
};

export default ResetPassword;
