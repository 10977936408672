const formatMobileNo = (mobileNo) => {
  let formattedMobileNo = mobileNo.replace(/\s/g, '');
  if (mobileNo.charCodeAt(0) === 48) {
    formattedMobileNo = `${formattedMobileNo
      .substring(0, 7)
      .replace(/[0-9]/g, 'X')}${formattedMobileNo.substring(7)}`;
  } else {
    formattedMobileNo = `${formattedMobileNo
      .substring(0, 6)
      .replace(/[0-9]/g, 'X')}${formattedMobileNo.substring(6)}`;
  }

  return formattedMobileNo;
};

export default formatMobileNo;
