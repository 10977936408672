import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../../features/app/appSlice';

import displayMenu from '../../../hooks/displayMenu';
import { getInstallerDetails } from '../../../firebase';
import {
  setIsResidential,
  getIsResidential,
} from '../../../features/customer/customerJourneySlice';

const InstallerDashboardFirstTime = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const isResidential = useSelector(getIsResidential);
  const INSTALLER_ACCOUNT_TYPE = 'installer';
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const getInstaller = () => {
    getInstallerDetails()
      .then((response) => {
        if (response.data.success) {
          if (
            response.data.account_type.toLowerCase() === INSTALLER_ACCOUNT_TYPE
          ) {
            dispatch(setIsResidential(response.data.isResidential));
          }
        }
      })
      .catch(() => {
        // Todo later error handling
      });
  };

  useEffect(() => {
    getInstaller();
  }, []);

  const onButtonClick = () => {
    window.location.assign(
      '/evhs/installer/application-setup/customer-details'
    );
  };

  displayMenu();

  return (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s1',
          'Manage your claims'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s2',
          'You have not started any claims yet.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s3',
          'Once you start a claim, you will be able to see it and track its progress here.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s4',
          "You can also update your account details and add new users in 'your account' at the top of this page."
        )}
      </p>
      {isResidential && (
        <>
          <br />
          <br />
          <h1 className="govuk-body">
            {copyProvider.getCopy(
              'Installer.Dashboard.InstallerDashboardFirstTime.s8',
              'To begin a new claim for the '
            )}
            <strong>
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboardFirstTime.s9',
                ' renters and flat owners grant'
              )}
            </strong>
            <Link
              to={{}}
              className="govuk-!-margin-top-5"
              onClick={onButtonClick}
            >
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboardFirstTime.s10',
                ' enter your customers details'
              )}
            </Link>
          </h1>
        </>
      )}
    </>
  );
};

export default InstallerDashboardFirstTime;
